import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

const MetaData = ({ title, description, tags, imageURL, url }) => {

 useEffect(() => {
    // Update document title
    document.title = title;

    // Update Open Graph meta tags
    const ogTitle = document.querySelector('meta[property="og:title"]');
    if (ogTitle) ogTitle.setAttribute("content", title);

    const ogDescription = document.querySelector('meta[property="og:description"]');
    if (ogDescription) ogDescription.setAttribute("content", description);

    const ogImage = document.querySelector('meta[property="og:image"]');
    if (ogImage) ogImage.setAttribute("content", imageURL);

    const ogUrl = document.querySelector('meta[property="og:url"]');
    if (ogUrl) ogUrl.setAttribute("content", url);

    // Update Twitter meta tags
    const twitterTitle = document.querySelector('meta[name="twitter:title"]');
    if (twitterTitle) twitterTitle.setAttribute("content", title);

    const twitterDescription = document.querySelector('meta[name="twitter:description"]');
    if (twitterDescription) twitterDescription.setAttribute("content", description);

    const twitterImage = document.querySelector('meta[name="twitter:image"]');
    if (twitterImage) twitterImage.setAttribute("content", imageURL);
  }, [title, description, imageURL, url]);
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={tags} />

      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imageURL} key="og:image"/>
      <meta property="og:url" content={url} />
      <meta property="og:type" content="article" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={imageURL}   property="og:image"
        key="og:image" />
    </Helmet>


    // <Helmet>
    //   <title>{title}</title>
    //   <meta name="description" content={description} />
    //   <meta name="keywords" content={tags} />
      
    //   <meta property="og:title" content={title} />
    //   <meta property="og:description" content={description} />
    //   <meta property="og:image" content={imageURL} />
    //   <meta property="og:url" content={url} />
    //   <meta property="og:type" content="article" />

    //   <meta name="twitter:card" content="summary_large_image" />
    //   <meta name="twitter:title" content={title} />
    //   <meta name="twitter:description" content={description} />
    //   <meta name="twitter:image" content={imageURL} />
    // </Helmet>
  );
};

export default MetaData;
