import React, { useState, useEffect } from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate, useLocation } from "react-router-dom";
import backgroundImage from "../../../src/images/Rectangle 74.png";
import { useSelector, useDispatch } from "react-redux";
import Download from "../../../src/images/google_logo (2).png";
import Facebook from "../../../src/images/Facebook_Logo_2023 (1).png";
import "./auth.css";
import axios from "../../appUtils/axiosConfig";
import { registerStart, registerSuccess, registerFailure } from "./authSlice";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { registerUser } from "./authSlice";
import MetaData from "../../component/layout/MetaData";
import {
  SHOW_ERROR_NOTIFICATION,
  SHOW_INFO_NOTIFICATION,
  SHOW_SUCCESS_NOTIFICATION,
} from "../../appUtils/appToast";

// function RegistrationForm({ userinfo }) {
function RegistrationForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, isLoggedIn, isLoading, error, otpSent } = useSelector(
    (state) => state.auth
  );

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const key = searchParams.get("key");
  const slug = searchParams.get("slug");
  const [formdata, setformdata] = useState({
    name: "",
    email: "",
    // mobileNumber: "",
    password: "",
    agreeToTerms: false,
  });


  const [mobileNumber, setMobileNumber] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [inValidPhone, setInValidPhone] = useState(false);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    const newvalue = type === "checkbox" ? checked : value;
    setformdata({
      ...formdata,
      [name]: newvalue,
    });

    if (name === "email") {
      if (value.includes("@") && value.includes(".")) {
        const validationMsg = document.getElementById("emailValidation");
        validationMsg.innerHTML = "Email is valid";
        validationMsg.style.color = "green";
      } else {
        const validationMsg = document.getElementById("emailValidation");
        validationMsg.innerHTML = "Email is not valid";
        validationMsg.style.color = "red";
      }
    }

  };

  const handleChangePhoneNumber = (value, data) => {
    const number = value.slice(data.dialCode.length);
    setMobileNumber(number);
    setCountryCode(`+${data.dialCode}`);
    setInValidPhone(false);
  };

  const [isvalid, setisvalid] = useState(false);
  // Register a user
  const handleRegister = (e) => {
    e.preventDefault();

    const { name, password, email } = formdata;

    if (!name || !password || !email || !mobileNumber) {
      SHOW_ERROR_NOTIFICATION("All fields are mandatory");
      setisvalid(true);
      return;
    }

    if (password.length < 8) {
      SHOW_ERROR_NOTIFICATION("Password Should be Atleast 8 digis");
      return;
    }
    if (formdata.name == "") {
      setisvalid(true);
    }

    e.preventDefault();
    formdata.phone = mobileNumber;
    formdata.email = email;
    formdata.countryCode = countryCode;
    // dispatch(registerStart());
    localStorage.setItem("mobile", mobileNumber);
    localStorage.setItem("countryCode",countryCode);
    localStorage.setItem("email",email);
    dispatch(registerUser(formdata)).then((result) => {
      if (!result?.payload?.success) {
        SHOW_ERROR_NOTIFICATION(result?.payload?.message);
      }
      if (result?.payload?.success) {
        SHOW_SUCCESS_NOTIFICATION(result?.payload?.message);
      }
      if (result?.error?.message) {
        SHOW_ERROR_NOTIFICATION(result?.payload);
      }
    });
  };

  useEffect(() => {
    if (otpSent) {
      navigate("/verifyotp"); //Now
    }
  }, [dispatch, error, otpSent, navigate]);

  const googleAuth = () => {
    window.open(
      `${process.env.REACT_APP_API_URL}/auth/google/callback`,
      // `http://localhost:8080/auth/google/callback`,
      "_self"
    );
  };

  const getPasswordCheckboxColor = () => {
    if (formdata.password.length === 0) {
      return "grey";
    } else if (formdata.password.length < 8 || formdata.password.length > 20) {
      return "red";
    } else {
      return "green";
    }
  };

  const [inputValue, setInputValue] = React.useState("");

  function handleKeyDown(event) {
    const key = event.key || event.keyCode || event.which;
    const inputField = event.target;
    const isEmpty = inputField.value.trim() === "";

    if (isEmpty && key === " ") {
      // Prevent space key when the input field is empty
      event.preventDefault();
    } else if (!isEmpty && key === " ") {
      // Allow space key when the input field is not empty
      event.stopPropagation();
    } else if (
      (key >= "0" && key <= "9") ||
      key === "Enter" ||
      (key >= 48 && key <= 57)
    ) {
      // Allow numbers (0-9) and enter key, prevent default behavior otherwise
      event.preventDefault();
    } else {
      // Disallow special characters
      const specialCharacters = /[!@#$%^&*(),.?":{}|<>]/;
      if (specialCharacters.test(key)) {
        event.preventDefault();
      }
    }
  }

  const loginhandler = () => {
    if (key) {
      navigate(`/login?key=${key}&slug=${slug}`);
    } else {
      navigate("/login");
    }
  };

  const facebookAuth = () => {
    window.open(
      `${process.env.REACT_APP_API_URL}/auth/facebook/callback`,
      "_self"
    );
  };

  return (
    <div>
      <MetaData title="Register User" />
      <div
        className="heroRegister"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          height: "1081px",
        }}
      >
        <div className="login12">
          <div className="text-center p-3">
            <h1>
              <span style={{ fontSize: "60px" }} className="loginfont">
                Register now!
              </span>
            </h1>
          </div>
          <div className="main_div">
            {/* <div className="clmix">
              <div className="title">
                <p className="adden fs-3" >Sign Up into your Account</p>
              </div>
            </div> */}
            <form onSubmit={handleRegister} className="mt-1">
              <label htmlFor="fullName" className="form-label textgray">
                Full Name
              </label>
              <div className="input-group mb-3">
                <span
                  className="input-group-text text-white rounded-0 bgPcolor"
                  id="basic-addon1"
                >
                  <i className="fa-regular fa-user"></i>
                </span>

                <input
                  type="text"
                  className="form-control textgray"
                  // className={`form-control rounded-0 ${isvalid ? "is-invalid" : ""}`}
                  name="name"
                  // readOnly
                  placeholder="Full Name"
                  aria-label="Full Name"
                  aria-describedby="basic-addon1"
                  value={formdata.name}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                />
              </div>

              <label htmlFor="mobileNumber" className="form-label textgray">
                Mobile Number
              </label>
              <div className="input-group mb-3">
                <span
                  className="input-group-text bgPcolor text-white rounded-0"
                  id="basic-addon1"
                >
                  <i className="fa-solid fa-mobile-screen"></i>
                </span>
                {/* <input
                  type="number"
                  className="form-control rounded-0"
                  name="mobileNumber"
                  placeholder="Mobile Number"
                  aria-label="Mobile Number"
                  aria-describedby="basic-addon1"
                  value={formdata.mobileNumber}
                  onChange={handleInputChange}
                /> */}
                <PhoneInput
                  country={"in"}
                  className="form-control ps-0 textgray"
                  enableSearch
                  // inputClass='login-page__phone-input'
                  inputClass="form-control rounded-0 border border-0 w-100 focus"
                  id="phone-input"
                  countryCodeEditable={false}
                  placeholder="Enter Mobile"
                  onChange={handleChangePhoneNumber}
                />
              </div>

              <label htmlFor="emailAddress" className="form-label textgray">
                Email Address
              </label>
              <div className="input-group mb-3">
                <span
                  className="input-group-text bgPcolor text-white rounded-0"
                  id="basic-addon1"
                >
                  <i className="fa-regular fa-envelope"></i>
                </span>
                <input
                  type="email"
                  className="form-control  textgray"
                  // readOnly
                  name="email"
                  placeholder="Email address"
                  aria-label="Email Address"
                  aria-describedby="basic-addon1"
                  value={formdata.email}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div>
                <span id="emailValidation"></span>
              </div>

              <label htmlFor="password" className="form-label textgray">
                Password
              </label>
              <div className="input-group mb-3">
                <span
                  className="input-group-text bgPcolor text-white rounded-0 password"
                  id="basic-addon1"
                >
                  <i
                    className="fa-solid fa-lock"
                    // style={{ fontSize: "20px" }}
                  ></i>
                </span>

                <input
                  type="password"
                  className="form-control textgray"
                  name="password"
                  placeholder="Password"
                  aria-label="Password"
                  aria-describedby="basic-addon1"
                  value={formdata.password}
                  onChange={handleInputChange}
                  style={{ border: `1px solid ${getPasswordCheckboxColor()}` }}
                />
              </div>

              <div className="pb-4">
                <div className="forget_div">
                  <p
                    style={{
                      fontSize: "13px !important",
                      color: getPasswordCheckboxColor(),
                    }}
                  >
                    <i
                      className="fa-solid fa-circle-check"
                      style={{ paddingRight: "5px" }}
                    ></i>
                    Must be 8 characters long.
                  </p>
                </div>
                <div className="check_box">
                  <input
                    type="checkbox"
                    name="agreeToTerms"
                    // value={user.ischecked}
                    checked={formdata.agreeToTerms}
                    onChange={handleInputChange}
                  />
                  <span className="mx-2">
                    I agree to{" "}
                    <a className="text-primary" href="/termsandconditions">
                      the terms of service
                    </a>
                  </span>
                </div>
              </div>
              <div className="input_box button ">
              <input
    type="submit"
    className="bgPcolor"
    value={isLoading ? "" : "Register"}
    disabled={formdata.agreeToTerms || isLoading ? false : true}
  />
  {isLoading && (
    <div
      className="spinner-border text-light position-absolute"
      role="status"
      style={{
        top: "18%",
        left: "45%",
        transform: "translate(-50%, -50%)",
        animation: "rotateSpinner 1s linear infinite"
      }}
    >
      <span className="visually-hidden">Loading...</span>
    </div>
  )}
              </div>
              <h5
                className="p-3 text-center fw-bolder"
                style={{ color: "#9C90D8" }}
              >
                OR
              </h5>
              <div className="input-group mb-3">
                <button
                  type="button"
                  onClick={googleAuth}
                  className="form-control googlebtn btn btn-success rounded-3"
                >
                  Sign Up with Google
                  <img loading="lazy" src={Download} alt="Dr. Archika Didi" className="appimg" />
                </button>
              </div>
              {/* <div className="input-group mb-3">
                <button
                  type="button"
                  onClick={facebookAuth}
                  className="form-control fbbtn btn btn-primary rounded-3"
                >
                  Sign Up with Facebook
                  <img loading="lazy" src={Facebook} alt="Dr. Archika Didi" className="appimg" />
                </button>
              </div> */}
              <div className="links text-center pt-2">
                Have an account?
                <a
                  onClick={loginhandler}
                  style={{
                    color: "#9C90D8",
                    paddingLeft: "5px",
                    cursor: "pointer",
                  }}
                >
                  Login here
                </a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegistrationForm;
