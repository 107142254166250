import React, { useEffect, useRef, useState } from "react";

import bg1 from "../../src/images/bg1.png";
import bg2 from "../../src/images/bg2.png";
import bg3 from "../../src/images/bg3.png";
import whatwedo from "../../src/images/whatwedo.png";
import archikadidi from "../../src/images/Archika Didi.png";
import education from "../../src/images/education.png";
import health from "../../src/images/healthcare.png";
import nutrition from "../../src/images/healthyfood.png";
import protection from "../../src/images/shield.png";
import inclusion from "../../src/images/teamwork.png";
import resillence from "../../src/images/resilience.png";
import one from "../../src/images/01.png";
import two from "../../src/images/02.png";
import three from "../../src/images/03.png";
import four from "../../src/images/04.png";
import five from "../../src/images/05.png";
import six from "../../src/images/06.png";
import seven from "../../src/images/07.png";
import eight from "../../src/images/08.png";
import logo from "../../src/images/logo.png";
import mission1 from "../../src/images/mission_1 1.png";
import mission2 from "../../src/images/mission_2 1.png";
import mission3 from "../../src/images/mission_3 1.png";
import rightarrow from "../../src/images/right-arrow 1.png";
import BigHearts from "../../src/images/bighearts.png";
import card1 from "../../src/images/card1.png";
import card2 from "../../src/images/card2.png";
import card3 from "../../src/images/card3.png";
import ImageCarousel from "./ImageCarousel";
import partner1 from "../../src/images/Partner-1.png";
import partner2 from "../../src/images/Partner-2.png";
import partner3 from "../../src/images/Partner-3.png";
import partner4 from "../../src/images/Partner-4.png";
import partner5 from "../../src/images/Partner-5.png";
import partner6 from "../../src/images/Partner-6.png";
import bgimg1 from "../../src/images/bgimg1.jpg";
import bgimg2 from "../../src/images/bgimg2.png";
import bgimg5 from "../../src/images/bgimg5.png";
import container3 from "../../src/images/container3.png";
import container4 from "../../src/images/container4.png";
import Carousel from "./SlideCarousel";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { donationImpact } from "../Allslices/donationImpactSlice";
import Moment from "react-moment";
import LoaderPage from "./SmallLoader";

import {
  getdonation,
  getDonationCategory,
  getDonationPartner,
  getAllDonationCampaigns,
  getsingleCampaign,
  joinCampaign,
  getdonationVideoReducer,
} from "../Allslices/donationSlice";
import Slider from "react-slick";
import SmallLoader from "./SmallLoader";
import MetaData from "../component/layout/MetaData";
import ReactModal from "react-modal";
const Donation = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getdonation());
    dispatch(getDonationCategory());
    dispatch(donationImpact());
    dispatch(getDonationPartner());
    dispatch(getAllDonationCampaigns());
    dispatch(getsingleCampaign());
    dispatch(getdonationVideoReducer());
  }, [dispatch]);

  const sliderRef = useRef();

  const minSlidesToShow = Math.min(3);
  let gridColumnClass = "col-md-4 col-sm-12 col-lg-3";
  
  if (minSlidesToShow === 3) {
    gridColumnClass = "col-md-4 col-sm-12 col-lg-3";
  } else if (minSlidesToShow === 2) {
    gridColumnClass = "col-md-3 col-sm-12 col-lg-3";
  } else if (minSlidesToShow === 1) {
    gridColumnClass = "col";
  }
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplayspeed: 6000,
    speed: 700,
    slidesToShow: minSlidesToShow,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
    ],
  };



  const maxLength = 300;

  const { donationVideos } = useSelector((state) => state.donationVideo);

  const [selectedSection, setSelectedSection] = useState("our_mission");
  const navigate = useNavigate();
  const handleNavigateToSingleCampaign = (slug) => {
    navigate(`/donation/campaign/${slug}`);
  };

  const { donation, donationCategory, partners, campaigns } = useSelector(
    (state) => state.donation
  );
  const initialFormData = {
    name: "",
    last_name: "",
    email: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [email, setEmail] = useState("");

  const handleInputChange = (donation) => {
    const { name, value, type, checked, files } = donation.target;
    const updatedValue =
      type === "checkbox" ? checked : type === "file" ? files[0] : value;

    if (name === "email") {
      setEmail(updatedValue);
    }
    setFormData({
      ...formData,
      [name]: updatedValue,
    });
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const formDataToSend = { ...formData };
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(joinCampaign({ campaignJoinedUsers: formDataToSend }));
    setTimeout(() => {
      setFormData(initialFormData);
    }, 5000);
  };

  //for trending visdeos
  const [isOpenVideo, setIsOpenVideo] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState("");

  useEffect(() => {
    if (isOpenVideo) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [isOpenVideo]);

  const openModal = (video_Url) => {
    setSelectedVideo(video_Url);
    setIsOpenVideo(true);
  };

  const closeModal = () => {
    setSelectedVideo("");
    setIsOpenVideo(false);
  };


  return (
    <>
      <MetaData title={donation?.donation_banner?.meta_title ?? "Dr. Archika Didi"} description={donation?.donation_banner?.meta_description ?? "Dr. Archika Didi"} tags={donation?.donation_banner?.meta_tag ?? "Dr. Archika Didi"} />
      {
        <>
          {donation ? (
            <>
              <div className="vidomediaquery desktop-only" style={{ backgroundSize: "cover", overflow: "hidden", backgroundImage: `url(${donation?.donation_banner?.banner_image})` }} >
                <div className="container wisdompad desktop-only">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-6 d-flex flex-column align-items-start justify-content-center">

                      {donation?.donation_banner?.alignItem === "left" && (<>
                        <div>
                          <h1 className=" ">
                            <span className="wisdomhead haedingfont">
                              {donation?.donation_banner?.heading}
                            </span>
                          </h1>
                          <p
                            className=" wisdomPara homebdes"
                            style={{
                              fontFamily: "Lato",
                              textAlign: "justify"
                            }}
                          >
                            {donation?.donation_banner?.subheading}
                          </p>
                        </div></>)}

                    </div>
                    <div className="col-lg-6  col-md-6 col-sm-6 col-6 d-flex flex-column align-items-start justify-content-center">

                      {donation?.donation_banner?.alignItem === "right" && (<>
                        <div>
                          <h1 className="fw-bold ">
                            <span className="wisdomhead haedingfont">
                              {donation?.donation_banner?.heading}
                            </span>
                          </h1>
                          <p
                            className=" wisdomPara homebdes"
                            style={{
                              fontFamily: "Lato",
                              textAlign: "justify"
                            }}
                          >
                            {donation?.donation_banner?.sub_heading}
                          </p>
                        </div></>)}

                    </div>
                  </div>
                </div>


              </div>

              {/* banner starts here */}



              <div className="mobile-only">
          <div className="container-fluid mobile-only" style={{position:"relative",display:"flex"}}> 
            <img loading="lazy" src={ `${donation?.donation_banner?.bannerres_image}` } alt="Dr. Archika Didi" />
            <div className="container" style={{position:"absolute",height:"100%"}} 
           >
              <div className="row" style={{height:"100%"}}>
                <div className="col-6 d-flex justify-content-start align-items-center">

                  {donation?.donation_banner?.alignItem === "left" && (<>
                    <p
                      className="homebannertitle"
                      style={{ fontFamily: "myFont",fontSize:"24px" }}
                      dangerouslySetInnerHTML={{
                        __html: donation?.donation_banner?.headingres,
                      }}
                    ></p>
                  </>)}

                </div>
                <div className="col-6 d-flex justify-content-start align-items-center">
                  {donation?.donation_banner?.alignItem   === "right" && (<>
                    <p
                      className="homebannertitle"
                      style={{ fontFamily: "myFont",fontSize:"24px" }}
                      dangerouslySetInnerHTML={{
                        __html: donation?.donation_banner?.headingres,
                      }}
                    ></p>
                  </>)}
                </div>


              </div>


             
             

            </div>

          
          </div>

          <div className="container">
               <p
                className="py-4 "
                style={{
                  fontFamily: "Lato",
                  textAlign: "justify"
                }}
                dangerouslySetInnerHTML={{
                  __html: donation?.donation_banner?.sub_headingres,
                }}
               >
               
               </p>
              
             </div>

             
          {/* banner ends here */}

          </div>

              {/* <div className="container-fluid mobile-only">
                <div className="container mycontainer3" style={{
                  backgroundSize: "contain",
                  backgroundImage: `url(${donation?.donation_banner?.bannerres_image})`,
                  backgroundRepeat: 'no-repeat',
                  height: '53vh'
                }}>
                  <div className="row h-50">
                    <div className="col-6 d-flex justify-content-center align-items-center h-75">

                      {donation?.donation_banner?.alignItem === "left" && (<>
                        <h1 className="fw-bold ">
                          <span className="fs-5">
                            {donation?.donation_banner?.headingres}
                          </span>
                        </h1>
                      </>)}

                    </div>
                    <div className="col-6 d-flex justify-content-center align-items-center h-75">
                      {donation?.donation_banner?.alignItem === "right" && (<>
                        <h1 className="fw-bold ">
                          <span className="fs-5">
                            {donation?.donation_banner?.headingres}
                          </span>
                        </h1>
                      </>)}
                    </div>
                  </div>

                  <p
                    className="m-0 py-4 "
                    style={{
                      fontFamily: "Lato",
                      textAlign: "justify"
                    }}
                  >
                    {donation?.donation_banner?.sub_headingres}
                  </p>

                </div>
              </div> */}
              {/* banner ends here */}
            </>




          ) : (
            <SmallLoader />
          )}

          {donation ? (
            <>
              <div className="container py-5" style={{ overflow: "hidden" }}>
                <div className="row justify-content-center">
                  {" "}
                  {/* Center the row */}
                  <div className="col-lg-12 donheading text-center">
                    <h1 className="fw-bold mydonationdd" style={{
                      color: "black",
                      fontSize: "20px !important",

                      fontWeight: 600,
                    }}>
                      <span>
                        {donation?.section_1?.heading}
                      </span>
                    </h1>
                    <p
                      className=" lato lh-lg  mx-auto " style={{
                        textAlign: "justify",
                        fontSize: "16px",
                      }}
                    >
                      {donation?.section_1?.content}
                    </p>
                  </div>
                 

                </div>
                {/* <button
                  type="button"
                  className="btn btn-lg mb-2 d-sm-5 mt-3 rounded-5 shadowbuttons"
                  style={{
                    backgroundColor: "#ff9933",
                    color: "white",
                    padding: "10px 20px",
                  }}
                >
                  DONATE NOW
                </button> */}
              </div>
            </>
          ) : (
            <SmallLoader />
          )}


          <div className="container donheading">
            <h1 className="py-4 text-center" style={{ fontFamily: "myfont" }}><span>{donation?.section_3?.heading}</span></h1>

            <div className="row">
              <div className="col-12">
                {Array.isArray(campaigns) &&
                  campaigns.filter((status) => status?.status == true).map((item, innerIndex) => (
                    <div className="row m-3 rounded p-0" key={innerIndex} style={{ backgroundColor: '#FDF1FA' }}>
                      <>

                        <div className="col-lg-3 col-md-6 col-sm-12 p-0">
                          <img
                            src={item?.thumbnail_image}
                            style={{ width: "100%" }}
                            alt="Dr. Archika Didi"
                          />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 p-3">
                          <h4
                            className="mt-1 fs-4 text-capitalize"
                            style={{ fontFamily: "playfair", color: "black" }}
                          >

                            {item?.title}
                          </h4>
                          <p
                            className="text-start card-text lato pb-3"

                            dangerouslySetInnerHTML={{
                              __html:
                                item?.shortdescription,
                            }}
                            style={{ fontFamily: "Lato", color: "#5B5B5B", textAlign: "justify" }}
                          ></p>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 p-3">
                          <button
                            type="submit"
                            className="btn btn-lg fs-6 w-100 shadow rounded shadowbuttons"
                            style={{ backgroundColor: "#ff9933", color: "white", cursor: "pointer" }}
                          ><a href={item?.main_banner_description} target="_blank" style={{ color: 'inherit' }}>DONATE NOW</a>
                          </button>
                        </div>

                      </>
                    </div>
                  ))
                }

              </div>
            </div>
          </div>

          <section>
            {donationVideos ? (
              <div
                className="container beloved pt-5 mt-2"
                style={{ paddingTop: "20px" }}
              >
                <div className="container">
                  <div className="row mb-5 d-flex justify-content-center mx-2">
                    <Slider ref={sliderRef} {...settings}>
                      {Array.isArray(donationVideos) &&
                        donationVideos
                          .filter((item) => item?.status == true)
                          .map((item, innerIndex) => (
                            <div className="item" key={innerIndex}>
                          <div className="position-relative px-1 trandingvideo">
                            <div
                              className="overlay"
                              onClick={() => openModal(item.video_url)}
                              style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                cursor: "pointer",
                                zIndex: 1,
                              }}
                            ></div>
                            <iframe
                              width="100%"
                              // height="150px"
                              style={{ borderRadius: "10px" }}
                              src={item.video_url}
                              title="YouTube video player"
                              onClick={() => openModal(item.video_url)}
                              frameBorder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                              allowFullScreen
                            ></iframe>
                          </div>
                        </div>
                          ))}
                    </Slider>

                  </div>
                </div>
              </div>
            ) : (
              <LoaderPage />
            )}
          </section>
          <ReactModal
        isOpen={isOpenVideo}
        onRequestClose={closeModal}
        style={{
          overlay: { backgroundColor: "rgba(0, 0, 0, 0.75)" },
          content: {
            top: "40%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginTop: "5rem",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            padding: "0",
            overflow: "hidden",
            border: "transparent",
            backgroundColor: "transparent",
            zIndex: 200,
            height: "280px",
            width: "380px"
          },
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            position: "absolute",
            right: "20px",
            zIndex: 100,
          }}
        >
          <button
            onClick={closeModal}
            style={{ background: "none", border: "none", fontSize: "20px" }}
          >
            ✖
          </button>
        </div>
        <div
          className=""
          style={{ width: "100%", height: "calc(100% - 20px)" }}
        >
          <iframe
            width="100%"
            height="100%"
            style={{ borderRadius: "10px" }}
            src={selectedVideo}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>
      </ReactModal>



          <div className="container-fluid">
            <div className="container donationmedia">
              <div className="row g-3 container py-5">



               

                <div className="col-12 px-2 text-center d-flex align-items-center justify-content-center flex-column lh-lg">

                  <h1
                    className="mt-2 pb-1 my-2"
                    style={{ fontFamily: "myfont", fontSize: "2rem" }}
                  >
                    {donation?.section_2?.heading}
                  </h1>
                  <p
                    className=" text-dark fw-bold lato  ddontaionh "
                    style={{
                      fontFamily: "Playfair Display",
                      fontSize: "16px",
                      fontWeight: 500,
                    }}
                    dangerouslySetInnerHTML={{ __html: donation?.section_2?.content }}
                  >
                  </p>

                </div>

              </div>
            </div>
          </div>

          {/* success stories starts */}

          <Carousel />

          {/* success stories ends */}


          <div className="container  mb-5">
            <h2 className="fs-4 text-center m-5 lato fw-bold">
              Join us in volunteering to make a powerful impact on society!
            </h2>

            <form className="row g-4 " onSubmit={handleSubmit}>
              <div className="col-lg-3 col-md-6 col-sm-12">
                <label htmlFor="" className="form-label lato fw-semibold">
                  Full Name
                </label>
                <input
                  value={formData.name}
                  className="p-2 w-100 border border-0 shadow rounded-pill"
                  style={{ backgroundColor: "#f2f2f2", borderRadius: "8px" }}
                  placeholder="Full Name"
                  type="text"
                  name="name"
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12">
                <label htmlFor="" className="form-label lato fw-semibold">
                  Mobile
                </label>
                <input
                  value={formData.last_name}
                  name="last_name"
                  placeholder="Mobile"
                  className="p-2 w-100 border border-0 shadow rounded-pill"
                  style={{ backgroundColor: "#f2f2f2", borderRadius: "8px" }}
                  type="number"
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12">
                <label htmlFor="" className="form-label lato fw-semibold">
                  Email Address
                </label>
                <input
                  value={formData.email}
                  name="email"
                  placeholder="example@gmail.com"
                  className="p-2 w-100 border border-0 shadow rounded-pill"
                  style={{ backgroundColor: "#f2f2f2", borderRadius: "8px" }}
                  type="email"
                  onChange={handleInputChange}
                />
                {email && !validateEmail(email) && (
                  <div style={{ color: "red" }}>
                    Please enter a valid email address
                  </div>
                )}
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 d-flex justify-content-center align-items-end">
                <button
                  type="submit"
                  className="btn btn-lg fs-6 w-100 shadow rounded-pill shadowbuttons"
                  style={{ backgroundColor: "#ff9933", color: "white" }}
                >
                  JOIN US
                </button>
              </div>
            </form>
          </div>




        </>
      }
    </>
  );
};

export default Donation;
