import React, { useEffect, useState } from "react";
import nodata from "../../images/No-Data.png";
import { getNewUrlBlog } from "../../Allslices/blogSlice";
import { useDispatch, useSelector } from "react-redux";
import { Router, useLocation, useNavigate } from "react-router-dom";
import LoaderPage from "../../maincontent/LoaderPage";

const PageNotFound = () => {
  const { singleurlblog } = useSelector((state) => state.blog);
  const [urlFound, setUrlFound] = useState(false);
  const [loader, setLoader] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const location = useLocation();
  const currentpathUrl = location.pathname;
  const slug = currentpathUrl?.split("/")[1];

  const excludedSlugs = [
    "blog",
    "video",
    "wellness",
    "wisdom",
    "podcast",
    "courses",
    "events",
    "meditation",
    "music",
    "gallery",
  ];
  useEffect(() => {
    if (slug && !excludedSlugs.includes(slug)) {
      setLoader(true);
      dispatch(getNewUrlBlog(slug)).then((result) => {
        if(result.payload.success){
          navigate(result.payload.newurl);
        }
        setLoader(false);
      });
    } else {
      setLoader(false);
    }
  }, [dispatch, slug]);

  // useEffect(() => {
  //   console.log("testingslug", slug);
  //   if (singleurlblog?.success === true) {
  //     setUrlFound(true);
  //     navigate(singleurlblog.newurl, { replace: true });
  //     console.log("object");
  //   }
  // }, []);

  return (
    <>
      {loader ? (
        <LoaderPage />
      ) : (
        !urlFound && (
          <section>
            <div className="container">
              <div className="text-center my-5 py-4 d-flex align-items-center flex-column">
                <img
                  loading="lazy"
                  style={{ width: "400px" }}
                  src={nodata}
                  alt="Dr. Archika Didi"
                />
                <h1 style={{ fontFamily: "myFont", fontSize: "3.8rem" }}>
                  404! Page Not Found... 🤷‍♂️🤦‍♂️
                </h1>
              </div>
            </div>
          </section>
        )
      )}
    </>
  );
};

export default PageNotFound;
