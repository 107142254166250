import React, { useEffect } from "react";
import ".././component/layout/style.css";
import bg from "../../src/images/Rectangle 57 (3).png";
import {useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Moment from "react-moment";
import {
  playTrack,
  setCurrentTrack,
  setTrackList,
} from "../Allslices/playerSlice";
import { getSingleWellness } from "../Allslices/wellnessSlice";
import WellnessSongMusicPlayer from "./WellnessSongMusicPlayer";
import MetaData from "../component/layout/MetaData";
import { getmyOrder } from "../Allslices/orderSlice";
import { SHOW_INFO_NOTIFICATION } from "../appUtils/appToast";

const WellnessMusicSubPage = ({ audioRef, songDetails, setSongDetails }) => {
  const { currentTrack, isPlaying } = useSelector((state) => state.player);
  const { wellness } = useSelector((state) => state.allwellness);
  const { orderAll } = useSelector((state) => state?.order);

  const dispatch = useDispatch();
  const { wellnessslug, songslug } = useParams();

  let url = window.location.href;
  url = url.split("/");
  const urlleng = url.length;
  let songList = [];

  useEffect(() => {
    if (!isPlaying && currentTrack == null) {
      if (wellnessslug) {
        dispatch(getSingleWellness(wellnessslug)).then((res) => {
          songList = res.payload.wellness.combinedList.filter(
            (item) => item?.type === "audio"
          );
          dispatch(setTrackList({ list: songList }));
          dispatch(
            setCurrentTrack(songList.find((song) => song?.slug == songslug))
          );
        });
      }
    }
  }, [dispatch, wellnessslug, songslug]);

  const seekPoint = (e) => {
    audioRef.current.currentTime = (e / 100) * audioRef.current.duration;
    setSongDetails((prev) => ({
      ...prev,
      time: Math.round(
        (audioRef.current.currentTime / audioRef.current.duration) * 100
      ),
    }));
  };
  useEffect(() => {
    dispatch(getmyOrder());
    dispatch(getSingleWellness(wellnessslug));
  }, []);

const navigate=useNavigate()
let userId = localStorage.getItem("userId");

useEffect(() => {
  if (wellness?.isPaid) {
    if (orderAll && orderAll.length > 0) {
      let purchased = false;
      
      for (const order of orderAll) {
          if (order&& order?.orderItems?.product && wellness?._id === order?.orderItems?.product) {
            purchased = true;
          }
      }
      if (!purchased) {
        navigate(`/wellness/${wellnessslug}`);
      }
    }
    if (userId == null) {
      navigate("/login");
      SHOW_INFO_NOTIFICATION("Please login to access Content");

    }
  }
}, [orderAll, navigate, wellness,wellnessslug,songslug]);


  return (
    <>
      <MetaData title={currentTrack?.title} />
      <div className="conatainer-fluid" style={{ overflow: "hidden" }}>
        <img
          src={bg}
          alt="Dr. Archika Didi"
          height={"100%"}
          width={"100%"}
          className="image-fluid"
        />
      </div>
      <div className="container py-4 mb-4" style={{ overflow: "hidden" }}>
        <div className="row">
          <div className="col-lg-6 col-xs-12 d-flex justify-content-center text-center p-2 position-relative">
            <img
              src={currentTrack?.thumbnail_image}
              alt="Dr. Archika Didi"
              style={{
                maxWidth: "90%",
                backgroundSize: "cover",
              }}
            />
            <div
              className="musicplayerwellness bg-light py-3 py-sm-3 px-sm-3 py-md-3 px-md-3 px-lg-4 py-lg-4 px-3 rounded-3"
              style={{ width: "99%" }}
            >
              <WellnessSongMusicPlayer
                albumId={wellnessslug}
                audioRef={audioRef}
                songDetails={songDetails}
                onSeek={seekPoint}
                time={songDetails?.time}
                setSongDetails={setSongDetails}
              />
            </div>
          </div>
          <div className="col-lg-6 col-xs-12 text-lg-start p-3 mt-md-5 mt-lg-0 text-sm-center">
            <h2
              className="fw-semibold h2wellnesssong"
              style={{ fontFamily: "Lato" }}
            >
              About Track
            </h2>
            <p style={{ fontFamily: "Lato" }}>{currentTrack?.description}</p>
            <div className="fw-bold">
              {/* <span>
                Label :{" "}
                <b>
                  {currentTrack?.label?.map((item) => item.title).join(", ")}
                </b>
              </span>
              <br />
              <span>
                Artists :Dr Archika Didi
                <b>
                  {currentTrack?.artist?.map((item) => item.title).join(", ")}
                </b>
              </span>
              <br />
              <span>Genres : Pop-goth</span>
              <br /> */}
              <span>
                Release Date :{" "}
                <Moment format="D MMM YYYY" withTitle>
                  {currentTrack?.created_at}
                </Moment>
              </span>
              <br />
              <br />
              <br />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WellnessMusicSubPage;
