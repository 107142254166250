import React, { useEffect, useState } from "react";
import bg from "../../src/images/Rectangle 57 (3).png";
import ".././component/layout/style.css";
import SubpageMusicPlayer from "./SubpageMusicPlayer";
import { useDispatch, useSelector } from "react-redux";
import { getsinglealbum } from "../Allslices/albumSlice";
import Moment from "react-moment";
import { getmyOrder } from "../Allslices/orderSlice";
import OrderModal from "../Payments/OrderModal";
import { useNavigate } from "react-router-dom";
import { setCurrentTrack, setTrackList } from "../Allslices/playerSlice";
import MetaData from "../component/layout/MetaData";
import useGeolocation from "../component/shared/UserGeolocation";

const MusicsubPag = ({ audioRef, songDetails, setSongDetails }) => {
  const { currentTrack, isPlaying, trackList, currentIndex } = useSelector(
    (state) => state.player
  );
  const { artiste } = useSelector((state) => state.album);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  var url = window.location.href;
  url = url.split("/");
  const urlleng = url.length;
  const albumId = url[urlleng - 2];
  const songId = url[urlleng - 1];
  const { orderAll } = useSelector((state) => state?.order);
  const [buynow, setbuynow] = useState(false);
  const [isPurchased, setIsPurchased] = useState(false);
  const [albuumPurchased, setAlbumPurchased] = useState(false);
  const orderType = "song";

  useEffect(() => {
    if (!isPlaying && currentTrack == null) {
      if (albumId) {
        dispatch(getsinglealbum(albumId)).then((res) => {
          dispatch(setTrackList({ list: res.payload?.album?.songList }));
          dispatch(
            setCurrentTrack(
              res.payload?.album?.songList.find((song) => song?.slug == songId)
            )
          );
        });
      }
    }
    dispatch(getmyOrder());
  }, [dispatch, albumId]);

  useEffect(() => {
    orderAll.map((item, index) => {
      if (
        item?.orderItems?.product &&
        currentTrack?._id === item.orderItems.product
      ) {
        setIsPurchased(true);
      }
      if (
        item?.orderItems?.product &&
        artiste?._id === item.orderItems.product
      ) {
        setAlbumPurchased(true);
      }
    });
  }, [orderAll, currentTrack]);

  const handlebuynow = () => {
    setbuynow(true);
  };

  const seekPoint = (e) => {
    audioRef.current.currentTime = (e / 100) * audioRef.current.duration;

    setSongDetails((prev) => ({
      ...prev,
      time: Math.round(
        (audioRef.current.currentTime / audioRef.current.duration) * 100
      ),
    }));
  };

  // const [checkBuyStatus, setCheckBuyStatus] = useState();
  // useEffect(() => {
  //   const purchaseStatus = orderAll.filter(
  //     (item) => item?.orderItems?.product === artiste?._id
  //   );
  //   setCheckBuyStatus(purchaseStatus[0]?.paymentInfo?.status);
  // }, [orderAll]);

  const sellingPriceINR = currentTrack?.selling_price_rupee;
  const mrpPriceINR = currentTrack?.mrp_price_rupee;
  const sellingPriceUSD = currentTrack?.selling_price_usd;
  const mrpPriceUSD = currentTrack?.mrp_price_usd;

  const userGeolocationCode = useGeolocation();

  const isIndianUser = userGeolocationCode === "IN";
  let mrpPrice = isIndianUser ? mrpPriceINR : mrpPriceUSD;

  const renderPrice = () => {
    const sellingPrice = isIndianUser ? sellingPriceINR : sellingPriceUSD;
    const currencySymbol = isIndianUser ? "₹" : "$";

    if (sellingPrice === mrpPrice) {
      return `BUY NOW (${currencySymbol}${sellingPrice})`;
    } else if (sellingPrice < mrpPrice) {
      return (
        <>
          BUY NOW{" "}
          <span style={{ textDecoration: "line-through", color: "white" }}>
            {currencySymbol}
            {mrpPrice}
          </span>{" "}
          ({currencySymbol}
          {sellingPrice}){" "}
        </>
      );
    } else {
      return `BUY NOW (${currencySymbol}${sellingPrice})`;
    }
  };
  return (
    <>
      <MetaData title={currentTrack?.description} />

      <div
        className="conatainer-fluid"
        style={{ height: "6rem", overflow: "hidden" }}
      >
        <img
          src={bg}
          alt="Dr. Archika Didi"
          height={"100%"}
          width={"100%"}
          className="image-fluid"
        />
      </div>

      <div className="container pt-4">
        <div className="row">
          <div className="col-lg-6 col-xs-12 text-center p-2 position-relative">
            <img
              src={currentTrack?.thumbnail_image}
              alt="Dr. Archika Didi"
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                objectFit: "contain",
              }}
            />
            <div className="musicplayer bg-light py-3 py-sm-3 px-sm-3 py-md-3 px-md-3 px-lg-4 py-lg-4 px-3 rounded-3">
              <SubpageMusicPlayer
                onSeek={seekPoint}
                albumId={albumId}
                audioRef={audioRef}
                time={songDetails?.time}
                setSongDetails={setSongDetails}
                isPurchased={isPurchased}
                albuumPurchased={albuumPurchased}
              />
            </div>
          </div>
          <div
            className="col-lg-6 col-xs-12 text-start p-2"
            style={{
              fontFamily: "Lato",
              position: "relative",
              marginTop: "70px",
            }}
          >
            <h2
              className="fw-semibold"
              style={{ fontFamily: "myFont", textAlign: "center" }}
            >
              About Track
            </h2>
            <h5
              className=" mt-4"
              style={{
                fontFamily: "myFont",
              }}
            >
              {currentTrack?.description}
            </h5>
            <div className="fw-bold ">
              <br />
              {currentTrack?.artist?.length > 0 && (
                <span>
                  Artists :{" "}
                  <b>
                    {currentTrack.artist.map((item) => item.title).join(", ")}
                  </b>
                </span>
              )}
              <br />

              {currentTrack && (
                <span>
                  Released :{" "}
                  <Moment format="D MMM YYYY" withTitle>
                    {currentTrack?.created_at}
                  </Moment>
                </span>
              )}

              <br />
              {currentTrack?.generic?.length > 0 ? (
                <span>
                  Genres :{" "}
                  {currentTrack?.generic?.map((item) => item.title).join(", ")}
                </span>
              ) : (
                ""
              )}
              <br />
              <div className="pt-5">
                {currentTrack?.isPaid && isPurchased == false ? (
                  <button
                    className="btn shadowbuttons btn-lg text-light rounded-pill cmn-t-shake "
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdrop"
                    onClick={handlebuynow}
                    style={{ backgroundColor: "#FF9933" }}
                  >
                    {currentTrack?.mrp_price_rupee == 0 &&
                    currentTrack?.mrp_price_usd == 0 &&
                    currentTrack?.isPaid
                      ? "GET NOW (FREE)"
                      : currentTrack?.mrp_price_rupee > 0 &&
                        currentTrack?.mrp_price_usd &&
                        currentTrack?.isPaid
                      ? renderPrice()
                      : ""}
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* carousel*/}

      <section className="related mt-5 pt-5">
        <div className="container-fluid">
          <div className="con pt-5 pb-4"></div>
        </div>
      </section>

      <OrderModal
        orderType={orderType}
        product={buynow ? currentTrack : ""}
        albumId={albumId}
        navigate={true}
        isExpired={false}
      />

      {/*  */}
    </>
  );
};

export default MusicsubPag;
