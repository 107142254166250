import React, { useState, useEffect, useRef } from "react";
import Rectangle57 from "../../src/images/rectangle57.png";
import { useLocation, useNavigate } from "react-router-dom";
import MetaData from "../component/layout/MetaData";
import lock from "../images/lock 1.png";
import CheckMark from "../images/checkmark.png";
import { ImHeadphones } from "react-icons/im";
import { MdLibraryBooks } from "react-icons/md";
import { IoCloseSharp } from "react-icons/io5";
import { getmyOrder } from "../Allslices/orderSlice";
import {
  getcoursedetails,
  getCourseLesson,
  postActivityAnswer,
} from "../Allslices/courseSlice";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  SHOW_INFO_NOTIFICATION,
  SHOW_SUCCESS_NOTIFICATION,
} from "../appUtils/appToast";

export default function Course2() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const currentpathUrl = location.pathname;
  const slug = currentpathUrl.split("/")[2];
  const {
    courseDetails,
    courseLessonList,
    isLoading: coursedataLoading,
    isPostActivityAnswerLoading,
    activityAnswer,
  } = useSelector((state) => state.course);
  const OrderId = location.state?.orderid;
  const CourseId = location.state?.productId;
  const { orderAll } = useSelector((state) => state?.order);
  const [activeTab, setActiveTab] = useState("Section0");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [audioUrl, setAudioUrl] = useState("");
  const [showActivity, setShowActivity] = useState(false);
  const [showAudioModal, setshowAudioModal] = useState(false);
  const [answerActivityId, setAnswerActivityId] = useState("");
  const [audioContentIndex, setAudioContetnIndex] = useState("");
  const [formData, setFormData] = useState({
    answerText: "",
    orderid: OrderId,
    courseid: CourseId,
  });
  const [loader, setLoader] = useState(false);
  const [loadmore, setLoadMore] = useState(false);
  // const [activeContent, setActiveContent] = useState('Content0');
  const [activeContents, setActiveContents] = useState({});

  useEffect(() => {
    dispatch(getmyOrder());
  }, [dispatch]);

  useEffect(() => {
    if (orderAll && orderAll.length > 0) {
      let isCoursePurchased = false;

      for (const order of orderAll) {
        const orderItem = order.orderItems;
        if (orderItem.slug === slug) {
          isCoursePurchased = true;
          const createdAt = moment(order.createdAt);
          const expiryDate = createdAt.add(
            orderItem.monthValidity || 0,
            "months"
          );
          const currentTime = moment();

          if (currentTime.isAfter(expiryDate)) {
            isCoursePurchased = false;
            break;
          }
        }
      }

      if (!isCoursePurchased) {
        navigate(`/courses/${slug}`);
      }
    }
  }, [orderAll, slug, navigate]);

  useEffect(() => {
    let userId = localStorage.getItem("userId");
    if (userId == null) {
      navigate("/login");
      SHOW_INFO_NOTIFICATION("Please login to access this Content");
    }
  }, []);

  const scrollToCourse = () => {
    // const courseSection = document.getElementById("courseSection");
    // courseSection.scrollIntoView({ behavior: "smooth" });
    const topValue = window.innerWidth <= 768 ? 1000 : 400;

    window.scrollTo({
      top: topValue,
      behavior: "smooth",
    });
    setActiveTab("Course");
  };

  const activityRef = useRef(null);

  const toggleActivity = (activityId) => {
    setAnswerActivityId(activityId);
    setShowActivity(!showActivity);
    setshowAudioModal(false);
  };

  const [activeAccordianIndex, setActiveAccordianIndex] = useState(null);

  const handleAccordionClick = (index) => {
    setActiveAccordianIndex(activeAccordianIndex === index ? null : index);
  };

  useEffect(() => {
    if (showActivity && activityRef.current) {
      activityRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      window.scrollBy(0, 100);
    }
  }, [showActivity]);

  const audioModalRef = useRef(null);

  const toggleAudio = (url, index) => {
    setAudioUrl(url);
    setAudioContetnIndex(index);
    setshowAudioModal(!showAudioModal);
    setShowActivity(false);
  };

  useEffect(() => {
    if (showAudioModal && audioModalRef.current) {
      audioModalRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [showAudioModal]);

  const sectionRef = useRef();

  useEffect(() => {
    if (activeTab && sectionRef.current && window.innerWidth < 768) {
      sectionRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [activeTab]);

  const [visibleCourses, setVisibleCourses] = useState(4);

  const handleLoadMore = () => {
    setLoadMore(true);
    setTimeout(() => {
      setVisibleCourses((prevVisibleCourses) => prevVisibleCourses + 4);
      setLoadMore(false);
    }, 1000);
  };

  const openModal = (url) => {
    setIsModalOpen(true);
    setAudioUrl(url);
  };

  const handleContentClick = (dayIndex, contentIndex, content) => {
    setActiveContents((prev) => ({
      ...prev,
      [dayIndex]: {
        activeContent: contentIndex,
        activeTitle: content.title,
        activeDescription: content.description,
        activeAudioUrl: content?.audiofile,
        activeVideoUrl: content.videoUrl,
      },
    }));
  };

  let activeContent = 0;
  useEffect(() => {
    if (courseLessonList?.length > 0) {
      const defaultActiveContents = {};

      courseLessonList.forEach((day, dayIndex) => {
        if (day?.content?.length > 1) {
          const firstContent = day.content[0];
          if (firstContent) {
            defaultActiveContents[dayIndex] = {
              activeContent: 0,
              activeTitle: firstContent.title,
              activeDescription: firstContent.description,
              activeAudioUrl: firstContent?.audiofile,
              activeVideoUrl: firstContent.videoUrl,
            };
          }
        }
      });
      setActiveContents(defaultActiveContents);
    }
  }, [courseLessonList]);

  const singleCourse = courseDetails?.course;
  const courseid = singleCourse?._id;

  const courseFaqList = singleCourse?.courseFaq;

  useEffect(() => {
    setLoader(true);
    dispatch(getcoursedetails(slug));
    setTimeout(() => {
      setLoader(false);
    }, 1500);
  }, [slug]);

  useEffect(() => {
    dispatch(
      getCourseLesson({ courseid: courseid, orderid: formData.orderid })
    );
  }, [courseid, formData.orderid, isPostActivityAnswerLoading]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const [currenttab, setcurrenttab] = useState("button1");

  const [videoUrl, setVideoUrl] = useState("");

  const handleInputChange = (e) => {
    const { name, value, type, files } = e.target;
    setFormData({
      ...formData,
      [name]: type === "file" ? files[0] : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { answerText } = formData;
    if (answerText === "") {
      SHOW_INFO_NOTIFICATION("Activity Answer Can't be Empty");
      return;
    }
    dispatch(postActivityAnswer({ answerActivityId, formData }));
    SHOW_SUCCESS_NOTIFICATION("Activity Answer Posted Successfully.");
    setShowActivity(false);
  };

  const handleUrlChange = (newUrl) => {
    const topValue = window.innerWidth <= 768 ? 900 : 280;

    window.scrollTo({
      top: topValue,
      behavior: "smooth",
    });
    setVideoUrl(newUrl);
  };

  useEffect(() => {
    setVideoUrl(singleCourse?.defaultVideoUrl);
  }, [singleCourse?.defaultVideoUrl]);

  useEffect(() => {
    if (singleCourse?.status === false) {
      navigate("/courses");
    }
  }, [singleCourse, dispatch, navigate]);

  return (
    <>
      <MetaData title="Course 2" />
      <section style={{ overflow: "hidden" }}>
        {/* <div
          className="aboutbtn2"
          style={{
            backgroundImage: `url(${singleCourse?.bannerImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "300px",
          }}
        ></div> */}

        {singleCourse ? (
          <section className="overflow-hidden">
            <div className="desktop-only">
              <div className="position-relative d-flex">
                <img
                  loading="lazy"
                  src={`${singleCourse?.bannerImage}`}
                  alt="Dr. Archika Didi"
                  className="w-100"
                />
              </div>
            </div>
            <div className="mobile-only">
              <div
                className="container-fluid mobile-only"
                style={{ position: "relative", display: "flex" }}
              >
                <img
                  loading="lazy"
                  src={`${singleCourse?.bannerImage}`}
                  alt="Dr. Archika Didi"
                />
                <div
                  className="container"
                  style={{ position: "absolute", height: "100%" }}
                ></div>
              </div>
            </div>
          </section>
        ) : (
          <section
            className="d-flex justify-content-center align-items-center w-100"
            style={{ height: "300px" }}
          >
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </section>
        )}

        <div
          className="poascastsub2"
          style={{
            backgroundImage: `url(${Rectangle57})`,
            backgroundSize: "cover",
            backgroundPosition: "center center",
          }}
        >
          <div className="container meaning ">
            <div className="row pt-5">
              <div
                className="col-lg-3 col-sm-10 col-md-12 col-xl-3 col-xxl-3 col-10 course2SideContainer p-0"
                style={{ borderRadius: "16px" }}
              >
                {currenttab == "button1" ? (
                  <button
                    className="btn shadowbuttons btn-lg text-light rounded-0  w-100 "
                    onClick={() => scrollToCourse()}
                  >
                    Start Here
                  </button>
                ) : (
                  ""
                )}
                {currenttab == "button2" ? (
                  <button className="btn shadowbuttons btn-lg text-light rounded-0  w-100 ">
                    Daily Action
                  </button>
                ) : (
                  ""
                )}
                {currenttab == "button3" ? (
                  <button className="btn shadowbuttons btn-lg text-light rounded-0  w-100 ">
                    Ready to move on?
                  </button>
                ) : (
                  ""
                )}
                <div className="position-relative">
                  {loader ? (
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{
                        height: "14rem",
                        width: "18rem",
                      }}
                    >
                      <div
                        className="spinner-border text-primary"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <img
                        src={singleCourse?.courseImage}
                        alt="Dr. Archika Didi"
                        style={{
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          borderRadius: "0px 0px 10px 10px !important",
                          aspectRatio: "16/9",
                        }}
                      />
                    </div>
                  )}
                </div>
                <div className="penel text-muted  m-4">
                  <p
                    style={{ fontFamily: "Lato", color: "#5B5B5B" }}
                    dangerouslySetInnerHTML={{
                      __html: singleCourse?.welcomeMessage?.description,
                    }}
                  ></p>
                </div>

                <div className="menus">
                  <ul>
                    {Array.isArray(singleCourse?.courseSection) &&
                      [...singleCourse.courseSection].map((section, index) => (
                        <li
                          key={section._id}
                          onClick={() => handleTabClick(`Section${index}`)}
                          className={`d-flex gap-2 align-items-center text-md-center coursemenus my-2 py-2 ${
                            activeTab === `Section${index}`
                              ? "d-flex!important gap-2 align-items-center active coursemenus my-2 py-2"
                              : ""
                          }`}
                        >
                          <span
                            className="checkmark"
                            style={{
                              marginRight:
                                activeTab !== `Section${index}`
                                  ? "2.5rem"
                                  : "0",
                            }}
                          >
                            {activeTab === `Section${index}` && (
                              <img
                                loading="lazy"
                                src={CheckMark}
                                alt="Dr. Archika Didi"
                              />
                            )}
                          </span>
                          <span
                            style={{ fontFamily: "myFont", fontSize: "22px" }}
                          >
                            {section.title}
                          </span>
                        </li>
                      ))}
                    <li
                      id="courseSection"
                      onClick={() => handleTabClick("Course")}
                      className={`d-flex align-items-center gap-2 coursemenus my-2 py-2 ${
                        activeTab === "Course" ? "active" : ""
                      }`}
                    >
                      <span
                        className="checkmark"
                        style={{
                          marginRight: activeTab !== "Course" ? "2.5rem" : "0",
                        }}
                      >
                        {activeTab === "Course" && (
                          <img
                            loading="lazy"
                            src={CheckMark}
                            alt="Dr. Archika Didi"
                          />
                        )}
                      </span>
                      <span
                        className=""
                        style={{ fontFamily: "myFont", fontSize: "22px" }}
                      >
                        Course in {singleCourse?.sections}{" "}
                        {singleCourse?.daysType}
                      </span>
                    </li>
                    {courseFaqList?.length > 0 && (
                      <li
                        onClick={() => handleTabClick("faq")}
                        className={`d-flex align-items-center gap-2 coursemenus my-2 py-2 ${
                          activeTab === "faq" ? "active" : ""
                        }`}
                      >
                        <span
                          className="checkmark"
                          style={{
                            marginRight: activeTab !== "faq" ? "2.5rem" : "0",
                          }}
                        >
                          {activeTab === "faq" && (
                            <img
                              loading="lazy"
                              src={CheckMark}
                              alt="Dr. Archika Didi"
                            />
                          )}
                        </span>
                        <span
                          className=""
                          style={{ fontFamily: "myFont", fontSize: "22px" }}
                        >
                          FAQ's{" "}
                        </span>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
              <div className="col-lg-9 py-4 ">
                {Array.isArray(singleCourse?.courseSection) &&
                  [...singleCourse.courseSection]
                    .sort((a, b) => a.sequence - b.sequence)
                    .map(
                      (section, index) =>
                        activeTab === `Section${index}` && (
                          <div
                            ref={sectionRef}
                            key={section._id}
                            className="container-fluid"
                          >
                            <div className="d-flex justify-content-center flex-column align-items-center gap-2">
                              <h1
                                className="fs-3 fw-bold m-auto py-2"
                                style={{ fontFamily: "myFont" }}
                              >
                                {section.title?.toUpperCase()}
                              </h1>
                              <div
                                style={{
                                  overflow: "hidden",
                                }}
                              >
                                {section.fileType === "video" && (
                                  <iframe
                                    style={{
                                      borderRadius: "10px",
                                      height: " 48vh",
                                      width: "55vw",
                                    }}
                                    src={section.file}
                                    className="iframeCourse"
                                    title="Video player"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowFullScreen
                                  ></iframe>
                                )}
                                {section.fileType === "audio" && (
                                  <audio
                                    controls
                                    className="my-4"
                                    controlsList={
                                      section.downloadable ? "" : "nodownload"
                                    }
                                  >
                                    <source
                                      src={section?.file}
                                      type="audio/mp3"
                                    />
                                    Your browser does not support the audio
                                    element.
                                  </audio>
                                )}
                                {section.fileType === "book" && (
                                  <iframe
                                    style={{
                                      borderRadius: "10px",
                                      height: " 48vh",
                                      width: "55vw",
                                    }}
                                    className="iframeCourse"
                                    src={section.file}
                                    title="Book viewer"
                                    frameBorder="0"
                                    allow="fullscreen"
                                  ></iframe>
                                )}
                                {section.fileType === "text content" && (
                                  <div
                                    style={{
                                      width: "55vw",
                                      height: "58vh",
                                      overflowY: "auto",
                                      padding: "1rem",
                                      border: "1px solid #ccc",
                                      borderRadius: "10px",
                                    }}
                                  >
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: section.file,
                                      }}
                                    />
                                  </div>
                                )}
                              </div>
                              <p
                                className="pt-3 mx-3 mx-lg-5 px-1 px-lg-3 fs-6"
                                style={{
                                  textAlign: "justify",
                                  fontFamily: "Lato",
                                  fontWeight: 500,
                                }}
                                dangerouslySetInnerHTML={{
                                  __html: section.description,
                                }}
                              ></p>
                            </div>
                          </div>
                        )
                    )}

                {activeTab == "Course" && (
                  <div className="container-fluid">
                    <div className="d-flex justify-content-center align-items-center">
                      <iframe
                        style={{
                          height: "48vh",
                          width: "55vw",
                        }}
                        className="iframeCourse"
                        src={videoUrl}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                      ></iframe>
                    </div>
                    <div className="row py-5 g-3">
                      {Array.isArray(courseLessonList) &&
                        courseLessonList
                          .slice(0, visibleCourses)
                          .map((item, index) => (
                            <div key={index}>
                              {item?.unlocked ? (
                                <div
                                  className="col-12 p-4 rounded-5"
                                  style={{
                                    backgroundColor: "#fdf1fa",
                                    cursor: "pointer",
                                  }}
                                >
                                  <h5
                                    className="fw-semibold"
                                    style={{ fontFamily: "myFont" }}
                                  >
                                    {singleCourse?.daysType === "Days"
                                      ? "Day"
                                      : singleCourse?.daysType === "Weeks"
                                      ? "Week"
                                      : singleCourse?.daysType === "Months"
                                      ? "Month"
                                      : ""}
                                    {""} {index + 1}
                                  </h5>
                                  <div className="row">
                                    {item?.content?.length === 1 ? (
                                      <>
                                        <div className="col-lg-8 col-md-12 col-md-12 col-sm-4 d-flex flex-column rounded align-items-start mt-3">
                                          <h5
                                            className="fw-semibold fs-5"
                                            style={{ fontFamily: "myFont" }}
                                          >
                                            {item?.content[0].title}
                                          </h5>
                                          <p className="lato fs-6">
                                            {item?.content[0].description}
                                          </p>
                                        </div>

                                        <div className="col-lg-4 col-sm-12">
                                          <div
                                            className="position-relative rounded-3"
                                            style={{
                                              paddingBottom: "56.25%",
                                              height: 0,
                                              overflow: "hidden",
                                              maxWidth: "100%",
                                              position: "relative",
                                            }}
                                          >
                                            <div
                                              className="overlay"
                                              style={{
                                                position: "absolute",
                                                top: 0,
                                                left: 0,
                                                width: "100%",
                                                height: "100%",
                                                cursor: "pointer",
                                                zIndex: 1,
                                              }}
                                              onClick={() =>
                                                handleUrlChange(
                                                  item?.content[0]?.videoUrl
                                                )
                                              }
                                            ></div>
                                            <iframe
                                              style={{
                                                width: "18vw",
                                                height: "24vh",
                                                borderRadius: "10px",
                                                position: "relative",
                                                zIndex: 0,
                                              }}
                                              className="iframeCourse"
                                              src={item?.content[0]?.videoUrl}
                                              title="YouTube video player"
                                              frameBorder="0"
                                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                              allowFullScreen={true}
                                            ></iframe>
                                          </div>
                                          <div className="pt-2 d-flex">
                                            <span
                                              className="p-2 d-inline-flex  align-items-center gap-2 lato"
                                              style={{ cursor: "pointer" }}
                                              onClick={() =>
                                                toggleAudio(
                                                  item?.content[0]?.audiofile,
                                                  index
                                                )
                                              }
                                            >
                                              <ImHeadphones fontSize={28} />
                                              Audio
                                            </span>
                                            <span
                                              className="p-2 d-inline-flex gap-2 lato"
                                              style={{ cursor: "pointer" }}
                                              onClick={() =>
                                                toggleActivity(
                                                  item?.activity?._id
                                                )
                                              }
                                            >
                                              <MdLibraryBooks fontSize={32} />
                                              Activity
                                            </span>
                                            {item?.activity?.answers[0]
                                              ?.answerText && (
                                              <span
                                                className="p-1 d-inline-flex gap-2 lato"
                                                style={{
                                                  cursor: "pointer",
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                                }}
                                              >
                                                <img
                                                  src={CheckMark}
                                                  style={{ height: "30px" }}
                                                  alt="Dr. Archika Didi"
                                                />
                                              </span>
                                            )}
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <div className="col-lg-3 col-sm-3 d-flex flex-column rounded-3 pt-2">
                                          {item?.content?.map(
                                            (content, contentIndex) => (
                                              <ul
                                                className={`p-1 list-group`}
                                                style={{ cursor: "pointer" }}
                                              >
                                                <li
                                                  key={contentIndex}
                                                  className="fw-semibold list-group-item"
                                                  style={{
                                                    fontFamily: "myFont",
                                                    backgroundColor:
                                                      activeContents[index]
                                                        ?.activeContent ===
                                                      contentIndex
                                                        ? "#b767a2"
                                                        : "#eeeff4",
                                                    color:
                                                      activeContents[index]
                                                        ?.activeContent ===
                                                      contentIndex
                                                        ? "white"
                                                        : "black",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    handleContentClick(
                                                      index,
                                                      contentIndex,
                                                      content
                                                    )
                                                  }
                                                >
                                                  {content.title}
                                                </li>
                                              </ul>
                                            )
                                          )}
                                        </div>
                                        <div className="col-lg-5 col-sm-4 d-flex flex-column align-items-start rounded mt-3">
                                          <h6 className="fw-semibold lato">
                                            {activeContents[index]?.activeTitle}
                                          </h6>
                                          <p className="lato fs-6">
                                            {
                                              activeContents[index]
                                                ?.activeDescription
                                            }
                                          </p>
                                        </div>
                                        <div className="col-lg-4 col-sm-12">
                                          <div
                                            className="position-relative rounded-3"
                                            style={{
                                              paddingBottom: "56.25%",
                                              height: 0,
                                              overflow: "hidden",
                                              maxWidth: "100%",
                                              position: "relative",
                                            }}
                                            onClick={() => {
                                              if (
                                                activeContents[index]
                                                  ?.activeVideoUrl
                                              ) {
                                                handleUrlChange(
                                                  activeContents[index]
                                                    ?.activeVideoUrl
                                                );
                                              }
                                            }}
                                          >
                                            <div
                                              className="overlay"
                                              style={{
                                                position: "absolute",
                                                top: 0,
                                                left: 0,
                                                width: "100%",
                                                height: "100%",
                                                cursor: "pointer",
                                                zIndex: 1,
                                              }}
                                              onClick={() =>
                                                handleUrlChange(
                                                  activeContents[index]
                                                    ?.activeVideoUrl
                                                )
                                              }
                                            ></div>
                                            <iframe
                                              style={{
                                                width: "18vw",
                                                height: "24vh",
                                                borderRadius: "10px",
                                                position: "relative",
                                                zIndex: 0,
                                              }}
                                              className="iframeCourse"
                                              src={
                                                activeContents[index]
                                                  ?.activeVideoUrl
                                              }
                                              title="YouTube video player"
                                              frameBorder="0"
                                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                              allowFullScreen={true}
                                            ></iframe>
                                          </div>
                                          <div className="pt-2 d-flex">
                                            <span
                                              className="p-2 d-inline-flex  align-items-center gap-2 lato"
                                              style={{ cursor: "pointer" }}
                                              onClick={() =>
                                                toggleAudio(
                                                  activeContents[index]
                                                    ?.activeAudioUrl,
                                                  index
                                                )
                                              }
                                            >
                                              <ImHeadphones fontSize={27} />
                                              Audio
                                            </span>
                                            <span
                                              className="p-2 d-inline-flex gap-2 lato"
                                              style={{ cursor: "pointer" }}
                                              onClick={() =>
                                                toggleActivity(
                                                  item?.activity?._id
                                                )
                                              }
                                            >
                                              <MdLibraryBooks fontSize={33} />
                                              Activity
                                            </span>
                                            {item?.activity?.answers[0]
                                              ?.answerText && (
                                              <span
                                                className="p-2 d-inline-flex gap-2 lato"
                                                style={{ cursor: "pointer" }}
                                              >
                                                <img
                                                  src={CheckMark}
                                                  style={{
                                                    height: "25px",
                                                  }}
                                                  alt="Dr. Archika Didi"
                                                />
                                              </span>
                                            )}
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                              ) : (
                                <div
                                  className="col-12 p-3 rounded-5"
                                  style={{
                                    backgroundColor: "#CCCCCC",
                                    pointerEvents: "none",
                                    position: "relative",
                                    cursor: "pointer",
                                  }}
                                >
                                  <div
                                    className="col-12 p-3 rounded-5 lock-image"
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      opacity: "0.6",
                                      position: "relative",
                                    }}
                                  >
                                    <div
                                      style={{
                                        position: "absolute",
                                        top: "43%",
                                        left: "52%",
                                        transform: "translate(-50%, -50%)",
                                        zIndex: "1",
                                      }}
                                    >
                                      <img
                                        src={lock}
                                        style={{
                                          width: "50px",
                                          height: "50px",
                                        }}
                                        alt="Dr. Archika Didi"
                                      />
                                    </div>

                                    <h5 className="fw-semibold lato">
                                      {singleCourse?.daysType === "Days"
                                        ? "Day"
                                        : singleCourse?.daysType === "Weeks"
                                        ? "Week"
                                        : singleCourse?.daysType === "Months"
                                        ? "Month"
                                        : ""}
                                      {""} {index + 1}
                                    </h5>
                                    <div className="row">
                                      {item?.content?.length === 1 ? (
                                        <>
                                          <div className="col-lg-8 col-sm-4 d-flex flex-column rounded align-items-start mt-3">
                                            <h5
                                              className="fw-semibold fs-5"
                                              style={{ fontFamily: "myFont" }}
                                            >
                                              {item?.content[0].title}
                                            </h5>
                                            <p className="lato fs-6">
                                              {item?.content[0].description}
                                            </p>
                                          </div>

                                          <div className="col-lg-4 col-sm-12">
                                            <div
                                              className="position-relative"
                                              style={{
                                                paddingBottom: "56.25%",
                                                height: 0,
                                                overflow: "hidden",
                                                maxWidth: "100%",
                                                position: "relative",
                                              }}
                                            >
                                              <div
                                                className="overlay"
                                                style={{
                                                  position: "absolute",
                                                  top: 0,
                                                  left: 0,
                                                  width: "100%",
                                                  height: "100%",
                                                  cursor: "pointer",
                                                  zIndex: 1,
                                                }}
                                                onClick={() =>
                                                  handleUrlChange(
                                                    item?.content[0]?.videoUrl
                                                  )
                                                }
                                              ></div>
                                              <iframe
                                                style={{
                                                  width: "18vw",
                                                  height: "24vh",
                                                  borderRadius: "10px",
                                                  position: "relative",
                                                  zIndex: 0,
                                                }}
                                                className="iframeCourse"
                                                src={item?.content[0]?.videoUrl}
                                                title="YouTube video player"
                                                frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                allowFullScreen={true}
                                              ></iframe>
                                            </div>
                                            <div className="pt-2 d-flex">
                                              <span
                                                className="p-2 d-inline-flex  align-items-center gap-2 lato"
                                                style={{ cursor: "pointer" }}
                                                onClick={() =>
                                                  toggleAudio(
                                                    activeContents[index]
                                                      ?.activeAudioUrl,
                                                    index
                                                  )
                                                }
                                              >
                                                <ImHeadphones fontSize={28} />
                                                Audio
                                              </span>
                                              <span
                                                className="p-2 d-inline-flex gap-2 lato"
                                                style={{ cursor: "pointer" }}
                                                onClick={() =>
                                                  toggleActivity(
                                                    item?.activity?._id
                                                  )
                                                }
                                              >
                                                <MdLibraryBooks fontSize={32} />
                                                Activity
                                              </span>
                                              {item?.activity?.answers[0]
                                                ?.answerText && (
                                                <span
                                                  className="p-2 d-inline-flex gap-2 lato"
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  <img
                                                    src={CheckMark}
                                                    height={30}
                                                    alt="Dr. Archika Didi"
                                                  />
                                                </span>
                                              )}
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <div className="col-lg-3 col-sm-3 d-flex flex-column rounded-3 pt-2">
                                            {item?.content?.map(
                                              (content, contentIndex) => (
                                                <ul
                                                  className={`p-1 list-group`}
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  <li
                                                    key={contentIndex}
                                                    className="fw-semibold list-group-item"
                                                    style={{
                                                      fontFamily: "myFont",
                                                      backgroundColor:
                                                        activeContents[index]
                                                          ?.activeContent ===
                                                        contentIndex
                                                          ? "#b767a2"
                                                          : "#eeeff4",
                                                      color:
                                                        activeContents[index]
                                                          ?.activeContent ===
                                                        contentIndex
                                                          ? "white"
                                                          : "black",
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() =>
                                                      handleContentClick(
                                                        index,
                                                        contentIndex,
                                                        content
                                                      )
                                                    }
                                                  >
                                                    {content.title}
                                                  </li>
                                                </ul>
                                              )
                                            )}
                                          </div>
                                          <div className="col-lg-5 col-sm-4 d-flex flex-column align-items-start rounded mt-3">
                                            <h6 className="fw-semibold lato">
                                              {
                                                activeContents[index]
                                                  ?.activeTitle
                                              }
                                            </h6>
                                            <p className="lato fs-6">
                                              {
                                                activeContents[index]
                                                  ?.activeDescription
                                              }
                                            </p>
                                          </div>
                                          <div className="col-lg-4 col-sm-12">
                                            <div
                                              className="position-relative rounded-3"
                                              style={{
                                                paddingBottom: "56.25%",
                                                height: 0,
                                                overflow: "hidden",
                                                maxWidth: "100%",
                                                position: "relative",
                                              }}
                                            >
                                              <div
                                                className="overlay"
                                                style={{
                                                  position: "absolute",
                                                  top: 0,
                                                  left: 0,
                                                  width: "100%",
                                                  height: "100%",
                                                  cursor: "pointer",
                                                  zIndex: 1,
                                                }}
                                                onClick={() =>
                                                  handleUrlChange(
                                                    activeContents[index]
                                                      ?.activeVideoUrl
                                                  )
                                                }
                                              ></div>
                                              <iframe
                                                style={{
                                                  width: "18vw",
                                                  height: "24vh",
                                                  borderRadius: "10px",
                                                  position: "relative",
                                                  zIndex: 0,
                                                }}
                                                className="iframeCourse"
                                                src={
                                                  activeContents[index]
                                                    ?.activeVideoUrl
                                                }
                                                title="YouTube video player"
                                                frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                allowFullScreen={true}
                                              ></iframe>
                                            </div>
                                            <div className="pt-2 d-flex">
                                              <span
                                                className="p-2 d-inline-flex  align-items-center gap-2 lato"
                                                style={{ cursor: "pointer" }}
                                                onClick={() =>
                                                  toggleAudio(
                                                    activeContents[index]
                                                      ?.activeAudioUrl,
                                                    index
                                                  )
                                                }
                                              >
                                                <ImHeadphones fontSize={27} />
                                                Audio
                                              </span>
                                              <span
                                                className="p-2 d-inline-flex gap-2 lato"
                                                style={{ cursor: "pointer" }}
                                                onClick={() =>
                                                  toggleActivity(
                                                    item?.activity?._id
                                                  )
                                                }
                                              >
                                                <MdLibraryBooks fontSize={33} />
                                                Activity
                                              </span>
                                              {item?.activity?.answers[0]
                                                ?.answerText && (
                                                <span
                                                  className="p-2 d-inline-flex gap-2 lato"
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  <img
                                                    src={CheckMark}
                                                    height={30}
                                                    alt="Dr. Archika Didi"
                                                  />
                                                </span>
                                              )}
                                            </div>
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}
                              {/* Modal for the Content Audio File */}

                              {audioContentIndex === index &&
                                showAudioModal && (
                                  <>
                                    <div
                                      ref={audioModalRef}
                                      className="col-12 p-3 rounded-5"
                                    >
                                      <div className="d-flex justify-content-between">
                                        <h6 className="fw-semibold lato">
                                          Audio
                                        </h6>
                                        <div
                                          className="p-2 rounded-circle d-flex"
                                          style={{
                                            backgroundColor: "#b767a2",
                                            color: "white",
                                          }}
                                          onClick={toggleAudio}
                                        >
                                          <IoCloseSharp
                                            fontSize={25}
                                            style={{ cursor: "pointer" }}
                                          />
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-12 d-flex flex-column rounded pt-1 my-3">
                                          <div
                                            className="modal-dialog w-100"
                                            role="document"
                                          >
                                            <div className="modal-content">
                                              <div
                                                className="modal-header"
                                                style={{
                                                  boxShadow:
                                                    " -2px 1px 26px -1px rgba(0,0,0,0.51)",
                                                  borderRadius: "50px",
                                                }}
                                              >
                                                <audio
                                                  controls
                                                  className="w-100"
                                                  controlsList="nodownload"
                                                >
                                                  <source
                                                    src={audioUrl}
                                                    type="audio/mpeg"
                                                  />
                                                </audio>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}

                              {/* Modal for the Activity Answer for Days */}
                              {answerActivityId === item.activity._id &&
                                showActivity && (
                                  <div
                                    ref={activityRef}
                                    className="col-12 p-3 rounded-5"
                                  >
                                    <div className="d-flex justify-content-between">
                                      <h6 className="fw-semibold lato">
                                        Activity
                                      </h6>
                                      <div
                                        className="p-2 rounded-circle d-flex"
                                        style={{
                                          backgroundColor: "#b767a2",
                                          color: "white",
                                        }}
                                        onClick={toggleActivity}
                                      >
                                        <IoCloseSharp
                                          fontSize={25}
                                          style={{ cursor: "pointer" }}
                                        />
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-12 d-flex flex-column rounded pt-1">
                                        <h5
                                          className="fw-semibold fs-6"
                                          style={{ fontFamily: "myFont" }}
                                        >
                                          {item?.activity?.title}
                                        </h5>
                                        <p
                                          className="text-left lato fs-6"
                                          dangerouslySetInnerHTML={{
                                            __html: item?.activity?.description,
                                          }}
                                        ></p>
                                      </div>
                                      <div className="col-12">
                                        <div
                                          data-mdb-input-init
                                          className="form-outline position-relative"
                                          style={{
                                            boxShadow:
                                              " -2px 1px 26px -1px rgba(0,0,0,0.51)",
                                            borderRadius: "15px",
                                          }}
                                        >
                                          <textarea
                                            className="form-control"
                                            id="textAreaExample1"
                                            placeholder="Post Your Answer Here..."
                                            rows="4"
                                            name="answerText"
                                            value={
                                              item?.activity?.answers[0]
                                                ?.answerText
                                            }
                                            onChange={handleInputChange}
                                            readOnly={
                                              !!item?.activity?.answers[0]
                                                ?.answerText
                                            }
                                          ></textarea>

                                          {!item?.activity?.answers[0]
                                            ?.answerText && (
                                            <button
                                              type="button"
                                              className="btn rounded px-4 fs-6 py-1 position-absolute text-white fw-2"
                                              style={{
                                                backgroundColor: "#b767a2",
                                                bottom: "1rem",
                                                right: "1rem",
                                              }}
                                              onClick={handleSubmit}
                                            >
                                              Post
                                            </button>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                            </div>
                          ))}
                    </div>

                    {courseLessonList?.length > visibleCourses && (
                      <div className="d-flex justify-content-center">
                        <button
                          className="btn shadowbuttons btn-lg text-light rounded-pill cmn-t-shake "
                          onClick={handleLoadMore}
                        >
                          {loadmore ? (
                            <div
                              className="spinner-border text-dark"
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          ) : (
                            <span className="lato">LOAD MORE</span>
                          )}
                        </button>
                      </div>
                    )}
                  </div>
                )}

                {activeTab == "faq" && (
                  <div className="container-fluid">
                    <div className="d-flex justify-content-center flex-column">
                      <h1
                        className="fs-2 fw-bold m-auto py-1"
                        style={{ fontFamily: "myFont" }}
                      >
                        FAQ's
                      </h1>
                    </div>

                    <div className="row py-3 g-3">
                      <div className="accordion" id="accordionExample">
                        {Array.isArray(courseFaqList) &&
                          courseFaqList.map((item, index) => (
                            <div
                              key={`accordionItem-${index}`}
                              className="accordion-item"
                            >
                              <h2
                                className="accordion-header"
                                id={`heading-${index}`}
                              >
                                <button
                                  className={`accordion-button ${
                                    activeAccordianIndex === index
                                      ? ""
                                      : "collapsed"
                                  }`}
                                  type="button"
                                  onClick={() => handleAccordionClick(index)}
                                  aria-expanded={activeAccordianIndex === index}
                                  aria-controls={`collapse-${index}`}
                                  style={{ background: "#fdf1fa" }}
                                >
                                  <strong style={{ fontFamily: "myFont" }}>
                                    {item?.question}
                                  </strong>
                                </button>
                              </h2>
                              <div
                                id={`collapse-${index}`}
                                className={`accordion-collapse collapse ${
                                  activeAccordianIndex === index ? "show" : ""
                                }`}
                                aria-labelledby={`heading-${index}`}
                                data-bs-parent="#accordionExample"
                              >
                                <div
                                  className="accordion-body"
                                  style={{ fontFamily: "myFont" }}
                                >
                                  {item?.answer}
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
