import { React, useEffect, useRef, useState } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import WisdomAudioPlayer from "./WisdomAudioPlayer";
import Moment from "react-moment";

import {
  FacebookIcon,
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  XIcon,
  TelegramIcon,
} from "react-share";
import { useDispatch, useSelector } from "react-redux";
import { getsinglewisdomlist, getwisdomConfig } from "../Allslices/wisdomSlice";

import MetaData from "../component/layout/MetaData";
import { setTrackList, setCurrentTrack } from "../Allslices/playerSlice";

export default function Wisdom_Article({
  audioRef,
  songDetails,
  setSongDetails,
}) {
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const dispatch = useDispatch();

  const { currentTrack, isPlaying, trackList, currentIndex } = useSelector(
    (state) => state.player
  );

  const { wisdomSlug } = useParams();
  const { wisdombanner, limit } = useSelector((state) => state.wisdom);
  useEffect(() => {
    if (!isPlaying) {
      if (wisdomSlug) {
        dispatch(getsinglewisdomlist(wisdomSlug)).then((res) => {
          const songList = [res.payload?.wisdom]; // Wrap in an array
          if (songList && songList.length > 0) {
            dispatch(setTrackList({ list: songList }));
            dispatch(setCurrentTrack(songList[0])); // Set first item as current track
          }
        });
      }
    }
  }, [dispatch, , isPlaying, wisdomSlug]);

  useEffect(() => {
    dispatch(getwisdomConfig());
  }, []);

  const handleNavigateToWisdomSubpage = (slug) => {
    dispatch(getsinglewisdomlist(slug));
    navigate(`/wisdom/${slug}`);
  };

  const { singlewisdomlist } = useSelector((state) => state.wisdom);

  const [fetchedWisdom, setFetchedWisdom] = useState([]);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    if (!singlewisdomlist?.wisdom?.contentType || !Array.isArray(fetchedWisdom))
      return;

    const filteredWisdom = fetchedWisdom.filter(
      (item) => item.contentType === singlewisdomlist.wisdom.contentType
    );
    setFetchedWisdom(filteredWisdom);
  }, [singlewisdomlist]);

  useEffect(() => {
    setFetchedWisdom(singlewisdomlist?.relatedlist);
  }, [singlewisdomlist?.relatedlist]);

  const url = window.location.href;

  const maxLength = 40;
  const maxLength2 = 115;

  let sliderRef1 = useRef();

  let sliderRef2 = useRef();
  const numCards = fetchedWisdom?.length || 0;
  const minSlidesToShow = Math.min(4, numCards);

  const settings1 = {
    // dots: true,
    infinite: true,
    autoscroll: true,
    autoplay: true,
    autoplayspeed: 6000,
    speed: 800,
    slidesToShow: minSlidesToShow,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
    ],
  };
  settings1.slidesToShow = minSlidesToShow;
  let gridColumnClass = "col-md-4 col-sm-12 col-lg-3";
  if (minSlidesToShow === 4) {
    gridColumnClass = "col-md-4 col-sm-12 col-lg-3";
  }
  if (minSlidesToShow === 3) {
    gridColumnClass = "col-md-4 col-sm-12 col-lg-3";
  } else if (minSlidesToShow === 2) {
    gridColumnClass = "col-md-3 col-sm-12 col-lg-3";
  } else if (minSlidesToShow === 1) {
    gridColumnClass = "col-md-3 col-sm-12 col-lg-3";
  }
  const seekPoint = (e) => {
    audioRef.current.currentTime = (e / 100) * audioRef.current.duration;

    setSongDetails((prev) => ({
      ...prev,
      time: Math.round(
        (audioRef.current.currentTime / audioRef.current.duration) * 100
      ),
    }));
  };
  let splitUrl = singlewisdomlist?.wisdom?.page_banner_image.split("/");

  let bannerImage;

  if (splitUrl) {
    bannerImage =
      splitUrl[3] == "publicundefined"
        ? wisdombanner?.default_image
        : singlewisdomlist?.wisdom?.page_banner_image;
  }

  const shareHashtag = `${singlewisdomlist?.wisdom?.meta_title}\n#${
    singlewisdomlist?.wisdom?.meta_tag
  }#${singlewisdomlist?.wisdom?.title}\n#${singlewisdomlist?.wisdom?.title}\n${
    Array.isArray(singlewisdomlist?.wisdom?.tags) &&
    singlewisdomlist?.wisdom?.tags.length > 0 &&
    singlewisdomlist?.wisdom?.tags?.map((item) => `#${item?.name}`)
  }#${singlewisdomlist?.wisdom?.slug}\n#DrArchikaDidi`;
  const shareHashtag_fb = `#${
    singlewisdomlist?.wisdom?.meta_tag || "DrArchikaDidi"
  }`;
  const shareHashtag_twitter = [
    `#${singlewisdomlist?.wisdom?.meta_title}\n#${
      singlewisdomlist?.wisdom?.meta_tag
    }#${singlewisdomlist?.wisdom?.title}\n#${
      singlewisdomlist?.wisdom?.title
    }\n${
      Array.isArray(singlewisdomlist?.wisdom?.tags) &&
      singlewisdomlist?.wisdom?.tags.length > 0 &&
      singlewisdomlist?.wisdom?.tags?.map((item) => `#${item?.name}`)
    }#${singlewisdomlist?.wisdom?.slug}\n#DrArchikaDidi`,
  ];

  const shareMessage = `${singlewisdomlist?.wisdom?.meta_title}\n\n${singlewisdomlist?.wisdom?.meta_description}`;

  return (
    <div>
      <MetaData
        title={singlewisdomlist?.wisdom?.meta_title ?? "Dr. Archika Didi"}
        description={singlewisdomlist?.wisdom?.meta_description ?? "Dr. Archika Didi"}
        tags={singlewisdomlist?.wisdom?.meta_tag ?? "Dr. Archika Didi"}
      />
      <>
        {/* banner section */}
        <div
          className=" blogimg mb-2  "
          style={{
            backgroundImage: `url(${bannerImage})`,

            backgroundSize: "cover",
            // filter: "blur(2px)"
          }}
        >
          <div className="container blogsub " style={{ overflow: "hidden" }}>
            <div className="row text-center p-5">
              <div className="col-lg-12 col-sm-12">
                <h2
                  className=" text-dark sbpagetitle "
                  style={{ fontFamily: "myFont" }}
                >
                  {singlewisdomlist?.wisdom?.title}
                </h2>
                <p className="wisdomsingledes">
                  {singlewisdomlist?.wisdom?.description}
                </p>
                <div
                  className="imagesee imagesee-wisdom   pt-sm-1"
                  style={{ overflow: "hidden" }}
                >
                  {singlewisdomlist?.wisdom?.contentType === "article" ? (
                    <img
                      src={singlewisdomlist?.wisdom?.thumbnail_image}
                      alt="Dr. Archika Didi"
                      className=""
                      style={{
                        borderRadius: "10px",
                        boxShadow: "1px 1px 17px 2px rgba(0,0,0,1)",
                      }}
                    />
                  ) : singlewisdomlist?.wisdom?.contentType === "audio" ? (
                    <div className="imagesee " style={{ overflow: "hidden" }}>
                      <img
                        src={singlewisdomlist?.wisdom?.thumbnail_image}
                        alt="Dr. Archika Didi"
                        className=""
                        style={{
                          borderRadius: "20px",
                          boxShadow: "1px 1px 17px 2px rgba(0,0,0,1)",
                        }}
                      />

                      <div className="wisdomSongPlayer bg-light py-3 py-sm-1 px-sm-1 py-md-2 px-lg-4 py-lg-3 px-3">
                        <WisdomAudioPlayer
                          onSeek={seekPoint}
                          albumId={wisdomSlug}
                          audioRef={audioRef}
                          songDetails={songDetails}
                          time={songDetails?.time}
                          setSongDetails={setSongDetails}
                        />
                      </div>
                    </div>
                  ) : (
                    <iframe
                      src={singlewisdomlist?.wisdom?.videourl}
                      title={singlewisdomlist?.wisdom?.title}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      className="singlevideoiframe"
                      allowFullScreen
                      style={{
                        borderRadius: "20px",
                        boxShadow: " -2px 1px 26px -1px rgba(0,0,0,1)",
                      }}
                    ></iframe>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* banner section end */}
        {/* text section all */}
        <section style={{ overflow: "hidden", marginTop: "1.65rem" }}>
          <div className="container">
            <div className="textrt  wisdomhshareione2 ">
              <div className="row wisdomicotext icotext text-center wisdomhshareione wisdom-space">
                <div className="col-lg-6 col-5 ">
                  <p
                    style={{ textTransform: "capitalize", paddingTop: "10px" }}
                  >
                    {singlewisdomlist?.wisdom?.contentType === "article" ? (
                      <>
                        Published :
                        <Moment
                          format="D MMM YYYY"
                          withTitle
                          style={{ margin: "0px 5px" }}
                        >
                          {singlewisdomlist?.wisdom?.createdAt}
                        </Moment>
                      </>
                    ) : (
                      ""
                    )}
                  </p>
                </div>
                <div className="col-lg-6 col-7">
                  {/* <a href="#" className="p-1"> */}
                  <a className="p-1">
                    <FacebookShareButton
                      url={url}
                      hashtag={shareHashtag_fb}
                      quote={shareMessage}
                    >
                      <FacebookIcon size={30} round={true} />
                    </FacebookShareButton>
                  </a>

                  <a className="p-1">
                    <WhatsappShareButton
                      appId=""
                      title={shareMessage}
                      url={url}
                      hashtag={shareHashtag}
                    >
                      <WhatsappIcon size={30} round={true} />
                    </WhatsappShareButton>
                  </a>
                  <a className="p-1">
                    <TwitterShareButton
                      appId=""
                      title={shareMessage}
                      hashtag={shareHashtag_twitter}
                      url={url}
                    >
                      <XIcon size={30} round={true} />
                    </TwitterShareButton>
                  </a>
                  <a className="p-1">
                    <TelegramShareButton title={shareMessage} url={url}>
                      <TelegramIcon size={30} round={true} />
                    </TelegramShareButton>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* content paragraph  */}
        <section style={{ overflow: "hidden" }} className="pb-3">
          <div className="container wisdomcont" style={{ marginTop: "1rem" }}>
            <div className="row mt-5">
              <div className="col-lg-12 col-md-12 col-sm-12  singlepagefont">
                <p
                  className="singlepagefont"
                  style={{ color: "#807F7F" }}
                  dangerouslySetInnerHTML={{
                    __html: singlewisdomlist?.wisdom?.fulldescription,
                  }}
                ></p>
              </div>
            </div>
          </div>
        </section>

        {/* text section all end */}
        {Array.isArray(fetchedWisdom) && fetchedWisdom.length === 0 ? (
          ""
        ) : (
          <>
            <div
              className="mahab-mixer d-none d-lg-block"
              style={{ overflow: "hidden" }}
            >
              <div className="text-center pt-2 mt-5">
                {singlewisdomlist?.wisdom?.contentType === "audio" && (
                  <h1 className="text-center pt-2 mt-5">
                    <span className="haedingfont"> Related Audio </span>
                  </h1>
                )}
                {singlewisdomlist?.wisdom?.contentType === "video" && (
                  <h1 className="text-center pt-2 mt-5">
                    <span className="haedingfont"> Related Video </span>
                  </h1>
                )}
                {singlewisdomlist?.wisdom?.contentType === "article" && (
                  <h1 className="text-center pt-2 mt-5">
                    <span className="haedingfont"> Related Article </span>
                  </h1>
                )}
              </div>

              <div
                className="container artvid  singlewisdomart pb-4 mb-5 "
                style={{ overflow: "hidden" }}
              >
                <div
                  className={`row g-3 py-2 artvid33 ${
                    fetchedWisdom?.length <= 2 ? "sliderscontainer" : ""
                  }`}
                >
                  <Slider
                    ref={(slider) => {
                      sliderRef1 = slider;
                    }}
                    {...settings1}
                  >
                    {Array.isArray(fetchedWisdom) &&
                      fetchedWisdom
                        .filter(
                          (item) =>
                            item?.contentType ===
                            singlewisdomlist?.wisdom?.contentType
                        )
                        .map((item, index) => (
                          <div
                            key={index}
                            className="col-md-6 col-sm-12 col-lg-3"
                            onClick={() =>
                              handleNavigateToWisdomSubpage(item?.slug)
                            }
                            style={{ borderRadius: "22px" }}
                          >
                            <div
                              className="card item-card card_height1 card-block mx-2 my-3 overflow-hidden"
                              style={{
                                borderRadius: "10px",
                                height: "450px",
                              }}
                            >
                              <img
                                src={item?.thumbnail_image}
                                alt="Dr. Archika Didi"
                                style={{ aspectRatio: "3/2" }}
                              />

                              <div
                                className="d-flex justify-content-end"
                                style={{ position: "relative", right: "26px" }}
                              >
                                <h6 className="pt-0">
                                  <h6 className="pt-3">
                                    {`${
                                      item.contentType === "video"
                                        ? `Video`
                                        : item.contentType === "audio"
                                        ? `Audio`
                                        : `Article`
                                    }`}
                                  </h6>
                                </h6>
                                <i
                                  className={`fa-solid ${
                                    item.contentType === "video"
                                      ? `fa-circle-play`
                                      : item.contentType === "audio"
                                      ? `fa-headphones`
                                      : `fa-book-open`
                                  }`}
                                  style={{
                                    position: "relative",
                                    top: "18px",
                                    left: "9px",
                                  }}
                                ></i>
                              </div>
                              <div className="container card_height1_11">
                                <h5
                                  className="mt-1 pt-1"
                                  style={{
                                    fontFamily: "Playfair Display SC",
                                    color: "#000000",
                                    height: "55px",
                                    fontWeight: "bold",
                                    overflow: "hidden",
                                  }}
                                >
                                  {item?.title.length > maxLength
                                    ? `${item?.title.substring(
                                        0,
                                        maxLength
                                      )}...`
                                    : item?.title}
                                </h5>
                                <p
                                  className="card-text lato my-2"
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      item.description.length > maxLength2
                                        ? `${item.description.substring(
                                            0,
                                            maxLength2
                                          )}...`
                                        : item.description,
                                  }}
                                  style={{
                                    marginLeft: "0.0rem",
                                    fontFamily: "Lato",
                                    color: "#5B5B5B",
                                  }}
                                ></p>

                                {/* <div className="pb-1 d-flex pt-1">
                          <h4 className="lato" style={{ fontSize: "16px" }}>
                            <Moment
                              className="fw-semibold"
                              format="D MMM YYYY"
                              withTitle
                            >
                              {item?.createdAt}
                            </Moment>
                          </h4>
                        </div> */}
                              </div>
                            </div>
                          </div>
                        ))}
                  </Slider>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    </div>
  );
}
