import React, { lazy } from 'react'
import Loadable from '../shared/Loadable'
// import Footer from './footer'
// import Header from './header'

const Header = Loadable(lazy(()=>import("./header")));
const Footer = Loadable(lazy(()=>import("./footer")));

const Layout = ({ children }) => {
    return (
        <div>

            <Header/>
            <main>
                {children}
            </main>
            <Footer />

        </div>
    )
}

export default Layout