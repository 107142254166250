import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import playbtn from "../images/playy-button (1).png";
import ".././component/layout/style.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllPodcast,
  getSinglePodcast,
  getPodcastBanner,
} from "../Allslices/podcastSlice";
import PodcastsongList from "./PodcastsongList";
import { pauseTrack, playTrack, setTrackList } from "../Allslices/playerSlice";
import MetaData from "../component/layout/MetaData";
const PodcastSinglePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { slug } = useParams();
  const handleNavigateToMusicPlayer = () => {
    navigate("/podcastsubpage");
  };
  const isComponentMounted = useRef(true);

  const { podcast, podcastbanner } = useSelector((state) => state.podcast);

  const [page, setPage] = useState(1);

  const [loader, setLoader] = useState(true);

  const [fetchedPodcast, setFetchedpodcast] = useState([]);
  const [totalpodcastCount, settotalpodcastCount] = useState(0);

  useEffect(() => {
    dispatch(getSinglePodcast(slug));
    dispatch(getPodcastBanner());
  }, []);

  const onSongPlay = (song) => {
    const index = podcast?.songList.findIndex((s) => s._id == song._id);

    dispatch(setTrackList({ list: podcast?.songList, index }));

    dispatch(playTrack(song));
  };

  const onSongPause = (song) => {
    const index = podcast?.songList.findIndex((s) => s._id == song._id);

    dispatch(setTrackList({ list: podcast?.songList, index }));
    dispatch(pauseTrack(song));
  };

  const handleFiltersSubmit = (e, action) => {
    e.preventDefault();
    if (action == "loadmore") {
      setLoader(true);
    }
    isComponentMounted.current = false;
    if (action == "loadmore") {
      setPage((prevpage) => prevpage + 1);
    }
  };
  let splitUrl = podcast?.banner_image.split("http://api.drarchikadidi.com/");

  let bannerImage;

  if (splitUrl) {
    bannerImage =
      splitUrl[1] == "publicundefined"
        ? podcastbanner?.podcastbanner?.default_image
        : podcast?.banner_image;
  }

  return (
    <>
      <MetaData
        title={podcast?.meta_title ?? "Dr. Archika Didi"}
        description={podcast?.meta_description ?? "Dr. Archika Didi"}
        tags={podcast?.meta_tag ?? "Dr. Archika Didi"}
      />

      {/*<!-- banner section -->*/}
      <section className="overflow-hidden">
        <div className="desktop-only">
          <div className="position-relative h-100">
            <img
              loading="lazy"
              src={`${bannerImage}`}
              alt="Dr. Archika Didi"
              className="w-100"
            />
          </div>
        </div>
        {/* <div
          className="poascast1 desktop-only"
          style={{
            backgroundImage: `url(${bannerImage})`,
          }}
        >
          <div className="container passtr1r">
            <div className="row">
              <div className="col-lg-7 col-xl-5 col-md-12 col-sm-10"></div>
              <div className="col-lg-7 col-xl-7 col-md-12`"></div>
            </div>
          </div>
        </div> */}
        <div className="mobile-only">
          <div
            className="container-fluid mobile-only"
            style={{ position: "relative", display: "flex" }}
          >
            <img loading="lazy" src={`${bannerImage}`} alt="Dr. Archika Didi" />
            <div
              className="container"
              style={{ position: "absolute", height: "100%" }}
            ></div>
          </div>
        </div>
      </section>
      {/* <!-- banner section end --> */}

      {/* </div> */}
      {/* <!-- all apisode section --> */}

      <section className="container">
        <h1 className="text-center pt-4">
          <span style={{ fontSize: "50px" }}>All Episodes</span>
        </h1>

        {podcast?.songList?.length > 0 &&
          podcast?.songList.map((song, index) => (
            <PodcastsongList
              key={song?._id}
              song={song}
              handlePlay={onSongPlay}
              handlePause={onSongPause}
              // artiste={artiste}
              // albumid={podcast?.slug}
            />
          ))}
      </section>
      <div className="p-4 text-center">
        {fetchedPodcast?.length == totalpodcastCount ? (
          ""
        ) : (
          <a>
            <button
              className="btn btn-orange btn-lg text-light rounded cmn-t-shake "
              style={{
                backgroundColor: "#FF9933!important",
                padding: "1rem 2.5rem",
              }}
              onClick={(e) => handleFiltersSubmit(e, "loadmore")}
            >
              LOAD MORE
            </button>
          </a>
        )}
      </div>
    </>
  );
};

export default PodcastSinglePage;
