import React from "react";

const SmallLoader = () => {
  return (
    <section className="py-5">
    <ul style={{ listStyle: "none",padding:" 0" }}>
      
        <li className=" mb-5 text-center">
          <div
            className="spinner-border text-primary"
            role="status"
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        </li>
      
    </ul>
  </section>
  );
};

export default SmallLoader;
