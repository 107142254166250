import React, { useEffect } from "react";
import {
  getGalleryBanner,
  getsinglegallerycategory,
} from "../Allslices/gallerySlice";
import { useDispatch, useSelector } from "react-redux";
import SmallLoader from "./SmallLoader";
import MetaData from "../component/layout/MetaData";
import { useParams } from "react-router-dom";
import GalleryModal from "./GalleryModal";
export default function GallerySubpage() {
  const dispatch = useDispatch();
  const { slug } = useParams();

  const { singlecategory, gallerybanner } = useSelector(
    (state) => state.gallery
  );

  let splitUrl;

  if (singlecategory?.page_banner_image) {
    splitUrl = singlecategory?.page_banner_image.split(
      "http://api.drarchikadidi.com/"
    );
  }
  let bannerImage;

  if (splitUrl) {
    bannerImage =
      splitUrl[1] == "publicundefined"
        ? gallerybanner?.default_image
        : singlecategory?.page_banner_image;
  }

  useEffect(() => {
    dispatch(getsinglegallerycategory(slug));
    dispatch(getGalleryBanner());
  }, []);

  let arrayData = singlecategory?.imageslist?.map((element, scheduleIndex) => ({
    height: 100,
    src: element?.thumbnail_image,
    width: 100,
  }));

  return (
    <>
      <MetaData
        title={singlecategory?.meta_title ?? "Dr. Archika Didi"}
        description={singlecategory?.meta_description ?? "Dr. Archika Didi"}
      />
      {singlecategory ? (
        <>
          <section className="overflow-hidden">
            <div className="desktop-only">
              <div className="position-relative d-flex">
                <img
                  loading="lazy"
                  src={`${bannerImage}`}
                  alt="Dr. Archika Didi"
                  className="w-100"
                />
                <div className="position-absolute h-100 w-100 top-0">
                  <div className="container h-100">
                    <div className="row h-100 align-items-center">
                      {singlecategory?.alignItem === "left" && (
                        <>
                          <div className="col-lg-6 col-md-6 col-xl-6 col-xxl-6 col-sm-6 col-12 d-flex align-items-center">
                            <div>
                              <h1 className="fw-bold">
                                <span
                                  style={{
                                    fontSize: "larger",
                                    color: "white!important",
                                  }}
                                >
                                  {singlecategory?.title}
                                </span>
                              </h1>
                              <p className="pt-2 text-dark ">
                                {singlecategory?.banner_description}
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-6 col-12"></div>
                        </>
                      )}
                      {singlecategory?.alignItem === "right" && (
                        <>
                          <div className="col-lg-6 col-md-6 col-sm-6 col-12"></div>
                          <div className="col-lg-6 col-md-6 col-xl-6 col-xxl-6 col-sm-6 col-12 d-flex align-items-center">
                            <div>
                              <h1 className="fw-bold">
                                <span
                                  style={{
                                    fontSize: "larger",
                                    color: "white!important",
                                  }}
                                >
                                  {singlecategory?.title}
                                </span>
                              </h1>
                              <p className="pt-2 text-dark ">
                                {singlecategory?.banner_description}
                              </p>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mobile-only">
              <div
                className="container-fluid mobile-only"
                style={{ position: "relative", display: "flex" }}
              >
                <img
                  loading="lazy"
                  src={`${bannerImage}`}
                  alt="Dr. Archika Didi"
                />
                <div
                  className="container"
                  style={{ position: "absolute", height: "100%" }}
                >
                  <div className="row" style={{ height: "100%" }}>
                    <div className="col-6 d-flex justify-content-start align-items-center">
                      {singlecategory?.alignItem === "left" && (
                        <>
                          <p
                            className="homebannertitle"
                            style={{ fontFamily: "myFont", fontSize: "24px" }}
                            dangerouslySetInnerHTML={{
                              __html: singlecategory?.title,
                            }}
                          ></p>
                        </>
                      )}
                    </div>
                    <div className="col-6 d-flex justify-content-start align-items-center">
                      {singlecategory?.alignItem === "right" && (
                        <>
                          <p
                            className="homebannertitle"
                            style={{ fontFamily: "myFont", fontSize: "24px" }}
                            dangerouslySetInnerHTML={{
                              __html: singlecategory?.title,
                            }}
                          ></p>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="container">
                <p
                  className="py-4 "
                  style={{
                    fontFamily: "Lato",
                    textAlign: "justify",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: singlecategory?.banner_description,
                  }}
                ></p>
              </div>

              {/* banner ends here */}
            </div>
          </section>
        </>
      ) : (
        <SmallLoader />
      )}

      <section>
        {singlecategory ? (
          <div className="container" style={{ margin: "8% auto" }}>
            {Array.isArray(singlecategory?.imageslist) &&
            singlecategory?.imageslist.length > 0 &&
            arrayData.length > 0 ? (
              <GalleryModal photos={arrayData}></GalleryModal>
            ) : (
              <h1 className="fwsemi-bold text-center">
                No Images Available To Show
              </h1>
            )}
          </div>
        ) : (
          <SmallLoader />
        )}
      </section>
    </>
  );
}
