import { useState, useEffect, useRef, lazy } from "react";

// import axios from 'axios';

import "./App.css";
import { ToastContainer } from "react-toastify";
import Layout from "./component/layout/layout";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
// import Home from "./maincontent/Home";
// import Login from './maincontent/Login';
import Login from "./component/auth/login";
import RegistrationForm from "./component/auth/register";

import OTPVerification from "./component/auth/otp";
import VerifyUserOtp from "./component/auth/VerifyUserOtp";
import ForgotPassword from "./component/auth/forgotPassword";
import NewPassword from "./component/auth/newpassword";
import Community from "./maincontent/community";
import ProtectedRoute from "./component/Route/ProtectedRoute";
import store from "./app/store";
import { loadUser } from "./component/auth/authSlice";
import { getContactUs } from "./Allslices/ContactUsSlice";
import { getHome } from "./Allslices/homeSlice";
import Gallery from "./maincontent/Gallery";
import GallerySubpage from "./maincontent/GallerySubpage";
import Blissful from "./maincontent/Blissful";
import ContactUs from "./maincontent/contactUs";
import Profile from "./maincontent/Profile";
import About from "./maincontent/AboutUs";
import Blog from "./maincontent/Blog";
import BlogSubpages from "./maincontent/blogSubpage";
import Course from "./maincontent/Course";
import PrivacyPolicy from "./maincontent/PrivacyPolicy";
import Course2 from "./maincontent/Course2";
import Music from "./maincontent/Music";
import Albums from "./maincontent/Albums";
import LoaderPage from "./maincontent/LoaderPage";
import OnlineClasses from "./maincontent/OnlineClasses";
import OnlineSingleClass from "./maincontent/OnlineSingleClass";
import MusicsubPag from "./maincontent/MusicsubPag";
import MusicFavourites from "./maincontent/MusicFavourites";
import WellnessBundle from "./maincontent/WellnessBundle";
import Wisdom_Audio from "./maincontent/Wisdom_Audio";
import WellnessSinglePage from "./maincontent/WellnessSinglePage";
import Wellness_Video from "./maincontent/Wellness_Video";
import Podcast from "./maincontent/Podcast";
import Podcast_Subpage from "./maincontent/Podcast_Subpage";
import PodcastFavourite from "./maincontent/PodcastFavourite";
import JoinTheCampaign from "./maincontent/JoinTheCampaign";
import AnnapurnaYojana from "./maincontent/AnnapurnaYojana";
import Donation from "./maincontent/Donation";
import Meditation from "./maincontent/Meditation";
import MeditationSinglePage from "./maincontent/MeditationSinglePage";
import Wisdom from "./maincontent/Wisdom";
import Wisdom_Video from "./maincontent/Wisdom_Video";
import Wisdom_Article from "./maincontent/Wisdom_Article";
import Video from "./maincontent/Video";
import BhagwatGita from "./maincontent/BhagwadGita";
import Chapter from "./maincontent/Chapter";
import SingleChapter from "./maincontent/SingleChapter";
import Cart1 from "./maincontent/Cart1";
import Cart2 from "./maincontent/Cart2";
import SubChapter from "./maincontent/SubChapter";
import TermsAndConditions from "./maincontent/Terms&Conditions";

import { setPlaying, setShowMusicBar } from "./Allslices/playerSlice";
import WellnessSingleVideo from "./maincontent/WellnessSingleVideo";
import WellnessMusicSubPage from "./maincontent/WellnessMusicSubPage";
import PodcastSinglePage from "./maincontent/PodcastSinglePage";
import PodcastMusicSubpage from "./maincontent/PodcastMusicSubpage";
import OrderSuccessfull from "./Payments/OrderSuccessfull";
import SingleVideo from "./maincontent/SingleVideo";
import Events from "./maincontent/Events";
import Events1 from "./maincontent/Events1";
import BhagwatVerses from "./maincontent/SingleVerses";
import PostById from "./maincontent/PostById";
import AnnualEvents from "./maincontent/AnnualEvents";
// import MonthEvents from "./maincontent/Monthsevents";
// import SpecialEvents from "./maincontent/SpecialEvents";
// import OtherEvents from "./maincontent/Otherevents";
import Shivratri from "./maincontent/Shivratri";
import EventSuccessPage from "./maincontent/EventSuccessPage";
import WellnessSingleArticle from "./maincontent/WellnessSingleArticle";
import AnnualSingleEvents from "./maincontent/Annualsingle";
import PageNotFound from "./component/layout/PageNotFound";
import FixMusicPlayer from "./maincontent/FixMusicPlayer";
import BlissfullBookpage from "./maincontent/blissfullBookpage";
import BookPage from "./maincontent/BookPage";
import Loadable from "./component/shared/Loadable";
import CookieManager from "./appUtils/CookieManager";
const Home = Loadable(lazy(() => import("./maincontent/Home")));
// import MusicSubPage from "./maincontent/MusicSubPage";

const ScrollTopMethod = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};

function App() {
  const { isAuthenticated, isLoading } = useSelector((state) => state.auth);
  const {
    currentTrack,
    repeatStatus,
    currentIndex,
    trackList,
    isPlaying,
    unpurchasedButPlaying,
    currentAlbum,
    isAlbumPurchased,
    isSongPurchased,
  } = useSelector((state) => state.player);

  useEffect(() => {
    store.dispatch(loadUser());
    dispatch(getContactUs());
    dispatch(getHome());
  }, []);
  const dispatch = useDispatch();
  const audioRef = useRef(null);
  const [songDetails, setSongDetails] = useState(null);
  const [audioPlaying, setAudioPlaying] = useState(false);

  useEffect(() => {
    if (audioPlaying) {
      dispatch(setPlaying(true));
    } else {
      dispatch(setPlaying(false));
    }
  }, [audioPlaying, dispatch]);

  useEffect(() => {
    const playAudio = async () => {
      if (isPlaying && audioRef.current) {
        try {
          await audioRef.current.play();
        } catch (error) {
          console.error("Error playing audio:", error);
          dispatch(setPlaying(false));
          // dispatch(setShowMusicBar(false));
        }
      } else if (audioRef.current) {
        audioRef.current.pause();
      }
    };

    playAudio();
  }, [isPlaying, dispatch]);

  useEffect(() => {
    const handleTrackChange = async () => {
      if (audioRef.current) {
        audioRef.current.currentTime = 0;
        setSongDetails((prev) => ({ ...prev, time: 0 }));

        if (isPlaying) {
          if (
            (currentAlbum?.isPaid && !isAlbumPurchased) ||
            (currentTrack?.isPaid && !isSongPurchased)
          ) {
            dispatch(setPlaying(false));

            await audioRef.current.pause();
          } else {
            try {
              await audioRef.current.play();
            } catch (error) {
              console.error("Error playing new track:", error);
              dispatch(setPlaying(false));
              dispatch(setShowMusicBar(false));
              return;
            }
          }
        } else {
          await audioRef.current.pause();
          // dispatch(setShowMusicBar(false));
        }
      }
    };

    handleTrackChange();
  }, [
    currentTrack?._id,
    isPlaying,
    currentAlbum,
    isAlbumPurchased,
    isSongPurchased,
    dispatch,
  ]);

  useEffect(() => {
    if (audioRef.current) {
      setSongDetails({
        volume: 1,
        time:
          Math.round(
            (audioRef.current.currentTime / audioRef.current.duration) * 100
          ) || 0,
        shuffle: false,
        repeat: false,
      });
    }
  }, [audioRef.current]);

  return (
    <>
      <audio
        ref={audioRef}
        src={currentTrack?.audiofile}
        onPause={() => setAudioPlaying(false)}
        onPlay={() => setAudioPlaying(true)}
        onTimeUpdate={() => {
          if (audioRef.current) {
            setSongDetails((prev) => ({
              ...prev,
              time: Math.round(
                (audioRef.current.currentTime / audioRef.current.duration) * 100
              ),
            }));
          }
        }}
      />

      <Router>
        <ScrollTopMethod />
        <CookieManager />
        <Layout>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/register" element={<RegistrationForm />} />

            <Route exact path="/otp" element={<OTPVerification />} />
            <Route exact path="/verifyotp" element={<VerifyUserOtp />} />
            <Route exact path="/forgotpassword" element={<ForgotPassword />} />
            <Route
              exact
              path="/password/reset/:token"
              element={<NewPassword />}
            />
            <Route exact path="/blog" element={<Blog />} />
            <Route exact path="/blog/:slug" element={<BlogSubpages />} />
            <Route exact path="/course" element={<Course />} />
            <Route exact path="/course/:slug" element={<Course2 />} />
            <Route exact path="/bhagwatgeeta" element={<BhagwatGita />} />
            <Route exact path="/courses" element={<OnlineClasses />} />
            <Route exact path="/wisdom/audio" element={<Wisdom_Audio />} />
            <Route exact path="/meditation" element={<Meditation />} />
            <Route
              exact
              path="/meditation/SinglePage/:slug"
              element={<MeditationSinglePage />}
            />
            <Route exact path="/aboutus" element={<About />} />
            <Route exact path="/bhagwatgeeta/chapter" element={<Chapter />} />
            <Route
              exact
              path="/bhagwatgeeta/chapter/:slug"
              element={<SingleChapter />}
            />
            <Route exact path="/podcast" element={<Podcast />} />
            <Route exact path="/music" element={<Music />} />
            <Route
              exact
              path="/termsandconditions"
              element={<TermsAndConditions />}
            />
            <Route exact path="/wisdom" element={<Wisdom />} />
            <Route
              exact
              path="/wisdom/:wisdomSlug"
              element={
                <Wisdom_Article
                  audioRef={audioRef}
                  songDetails={songDetails}
                  setSongDetails={setSongDetails}
                />
              }
            />
            <Route exact path="/wisdom/video" element={<Wisdom_Video />} />
            <Route exact path="/video" element={<Video />} />
            <Route
              exact
              path="/video/singleVideo/:slug"
              element={<SingleVideo />}
            />
            <Route exact path="/wellness" element={<WellnessBundle />} />
            <Route exact path="/events" element={<Events />} />
            <Route exact path="/events1" element={<Events1 />} />
            <Route exact path="/eventsuccess" element={<EventSuccessPage />} />
            <Route exact path="/events/:eventType" element={<AnnualEvents />} />

            <Route
              exact
              path="/events/eventdetails/:slug/:scheduleslug"
              element={<Shivratri />}
            />
            <Route exact path="/gallery" element={<Gallery />} />
            <Route
              exact
              path="/gallery/singlecategory/:slug"
              element={<GallerySubpage />}
            />
            <Route exact path="/donation" element={<Donation />} />
            <Route
              exact
              path="/jointhecampaign"
              element={<JoinTheCampaign />}
            />
            <Route exact path="/community" element={<Community />} />
            <Route exact path="/contactus" element={<ContactUs />} />

            <Route
              exact
              path="/eventblog/:slug"
              element={<AnnualSingleEvents />}
            />

            <Route exact path="/singlepost/:id" element={<PostById />} />
            <Route
              exact
              path="/wellness/:slug"
              element={<WellnessSinglePage />}
            />
            <Route
              exact
              path="/wellness/:wellnessslug/:songslug"
              element={
                <WellnessMusicSubPage
                  audioRef={audioRef}
                  songDetails={songDetails}
                  setSongDetails={setSongDetails}
                />
              }
            />
            <Route
              exact
              path="/wellness/video/:pslug/:slug"
              element={<WellnessSingleVideo />}
            />
            <Route exact path="/wellness_video" element={<Wellness_Video />} />
            <Route
              exact
              path="/wellness/article/:pslug/:slug"
              element={<WellnessSingleArticle />}
            />
            <Route
              exact
              path="/music/:slug"
              element={
                <Albums
                  audioRef={audioRef}
                  songDetails={songDetails}
                  setSongDetails={setSongDetails}
                />
              }
            />
            <Route
              exact
              path="/music/:slug/:slug"
              element={
                <MusicsubPag
                  audioRef={audioRef}
                  songDetails={songDetails}
                  setSongDetails={setSongDetails}
                />
              }
            />
            <Route
              exact
              path="/podcast/:slug"
              element={<PodcastSinglePage />}
            />
            <Route
              exact
              path="/podcast/:podcastslug/:songslug"
              element={
                <PodcastMusicSubpage
                  audioRef={audioRef}
                  songDetails={songDetails}
                  setSongDetails={setSongDetails}
                />
              }
            />
            {/* <Route exact path="/donation" element={<Donation />} /> */}

            <Route exact path="/courses/:slug" element={<Blissful />} />
            <Route exact path="/book/:slug" element={<BlissfullBookpage />} />
            <Route exact path="/books" element={<BookPage />} />
            <Route
              exact
              path="/donation/campaign/:slug"
              element={<AnnapurnaYojana />}
            />
            <Route exact path="/privacypolicy" element={<PrivacyPolicy />} />
            {/* private routes */}
            <Route
              element={<ProtectedRoute isAuthenticated={isAuthenticated} />}
            >
              {/* <Route exact path="/loderpage" element={<LoaderPage />} /> */}
              <Route exact path="/profile" element={<Profile />} />

              <Route
                exact
                path="/courses/dfs"
                element={<OnlineSingleClass />}
              />

              <Route
                exact
                path="/music_favourites"
                element={<MusicFavourites />}
              />

              <Route
                exact
                path="/podcastsubpage"
                element={<Podcast_Subpage />}
              />
              <Route
                exact
                path="/podcastfavourite"
                element={<PodcastFavourite />}
              />

              <Route exact path="/cart1/:slug" element={<Cart1 />} />
              <Route exact path="/cart2" element={<Cart2 />} />
              <Route exact path="/subchapter" element={<SubChapter />} />

              <Route
                exact
                path="/ordersuccess"
                element={<OrderSuccessfull />}
              />

              <Route
                exact
                path="/bhagwatgeeta/:slug"
                element={<BhagwatVerses />}
              />
              <Route path="*" element={<PageNotFound />} />
            </Route>
          </Routes>
        </Layout>
        <FixMusicPlayer
          audioRef={audioRef}
          songDetails={songDetails}
          setSongDetails={setSongDetails}
        />
      </Router>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* Same as */}
      <ToastContainer />
    </>
  );
}

export default App;
