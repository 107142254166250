
import 'bootstrap/dist/css/bootstrap.min.css';

const loaderWrapperStyles = {
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 1301,
  width: '100%',
};

const Loader = () => (
  <div style={loaderWrapperStyles}>
    <div className="progress" style={{ height: '4px' }}>
      <div
        className="progress-bar progress-bar-striped progress-bar-animated bg-primary"
        role="progressbar"
        style={{ width: '100%' }}
      ></div>
    </div>
  </div>
);

export default Loader;
