import React from "react";
import ".././component/layout/style.css";
import youngwomen from "../images/young-woman-home-pondering-work-tasks-generated-by-ai 1.png";
// import playbtn from "../images/playy-button (1).png";
import Carousel from "./Carousel";
import {
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookShareButton,
  FacebookMessengerShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  XIcon,
  TelegramIcon,
} from "react-share";
import MetaData from "../component/layout/MetaData";
const Podcast_Subpage = () => {
  const url = window.location.href;

  return (
    <>
      <MetaData title="Podcast-Subpage" />
      {/* <!-- banner section --> */}
      <div className="poascastsub1">
        <div className="container passtrsub1">
          <div className="sunpast text-center px-5">
            <h1 className="" style={{ textTransform: "uppercase" }}>
              Finding Moments of joy in
              <br /> a challenging year
            </h1>
            <p className="pt-2 text-dark drdsub fs-5">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              nulla eiusmod tempor incidi dunt ut labore et dolore magna aliqua.
              Ut etim enim ad minim veniam, quis nostrud exercitation ullamco
              laboris nisi ut aliquip ex ea commodo consequat.
            </p>
          </div>
        </div>
      </div>
      {/* <!-- banner section end --> */}

      <section className="mpmusic">
        <div className="container">
          <div className="eletypp">
            <div className="imgese text-center container">
              <img
                src={youngwomen}
                alt="Dr. Archika Didi"
                style={{
                  objectFit: "cover",
                  minWidth: "100%",
                  height: "100%",
                  position: "relative",
                  top: "-50px",
                  width: "261px",
                }}
              />
            </div>
            <div className="garps p-sm-5 ">
              <div className="footer-social-icon p-2 pb-5 d-lg-flex d-sm-inline-block">
                <div className="text-secondary">Audio | Feb 8, 2022</div>
                <div className="ms-auto d-lg-flex d-sm-inline-block">
                  <div className="">
                    <a href="#">
                      <i className="fa-regular fa-bookmark text-dark fs-4"></i>
                    </a>
                    <a href="#">
                      <i className="fa-solid fa-share-nodes"></i>
                    </a>
                  </div>
                  <a href="#" className="p-1">
                    <FacebookShareButton
                      url={"https://archikadidi.com"}
                      hashtag="#ArchikaDidiBlog"
                      quote={"CampersTribe - World is yours to explore"}
                    >
                      <FacebookIcon size={45} round={true} />
                    </FacebookShareButton>
                  </a>

                  <a href="#" className="p-1">
                    <WhatsappShareButton
                      appId=""
                      title="Hi I am happy to share Blogs by Archika Didi"
                      url={url}
                      hashtag="#ArchikaDidiBlog"
                    >
                      <WhatsappIcon size={45} round={true} />
                    </WhatsappShareButton>
                  </a>
                  <a href="#" className="p-1">
                    <TwitterShareButton
                      appId=""
                      title="Hi I am happy to share Blogs by Archika Didi"
                      hashtag="#ArchikaDidiBlog"
                      url={url}
                    >
                      <XIcon size={45} round={true} />
                    </TwitterShareButton>
                  </a>
                  <a href="#" className="p-1">
                    <TelegramShareButton
                      title="Hi I am happy to share Blogs by Archika Didi"
                      url={url}
                    >
                      <TelegramIcon size={45} round={true} />
                    </TelegramShareButton>
                  </a>
                </div>
              </div>
              <p className="">
                Originally, brahmana, kshatriya, vaishya, and shudra were not
                seen as sects of people who are born in certain families as it
                is understood today, but as descriptions of qualifications. A
                shudra was someone who did menial jobs; all he knew was his
                body. A vaishya was someone who was using his body, and to some
                extent, his mind. He was largely free of emotion, except for his
                own family. His body and his calculations ruled his nature. A
                kshatriya was passionate to the point of involving his life
                energies. He was more in his heart; otherwise he could not rule
                or lay down his life for what he believed in..
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- related content section --> */}

      <section className="related">
        <div className="container mb-4">
          <h1 className="text-center pt-2">
            <span className="fs-1">Related Content</span>
          </h1>
          <div className="con pt-5">
            <Carousel />
          </div>
        </div>
      </section>

      {/*  */}
    </>
  );
};

export default Podcast_Subpage;
