import React, { useEffect, useRef, useState } from "react";
import ".././component/layout/style.css";
import playbtn from "../images/playy-button (1).png";
// import bg from "../images/btop-bg.webp";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  filtersUpdate,
  getAllPodcast,
  getPodcastBanner,
  getLanguages,
  getPodcastCategory,
} from "../Allslices/podcastSlice";
import usePreserveScroll from "./histrorysave"; // Adjust the path accordingly
import { getalltags } from "../Allslices/tagSlice";
import Select from "react-select";
import nodata from "../images/No-Data.png";
import filtericon from "../images/filtericons.png";
import searchicon from "../images/searchicons.png";
import SmallLoader from "./SmallLoader";
import MetaData from "../component/layout/MetaData";
const Podcast = () => {
  const location = useLocation();
  const listStartRef = useRef(null);
  const [yCoordinate, setYCoordinate] = useState(null);
  const query = location.search.split("?")[1];
  const [fetchedPodcast, setFetchedPodcast] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const { tags } = useSelector((state) => state.tag);

  let initialfilters = {
    categoryId: null,
    language: null,
    tags: null,
    newest: "",
    text: query ?? "",
  };

  useEffect(() => {
    const element = listStartRef.current;
    if (element) {
      const position = element.getBoundingClientRect();
      const y = position.top;
      setYCoordinate(y);
    }
  }, []);

  const [filters, setFilters] = useState(initialfilters);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    podcastlist,
    podcastbanner,
    podcastCategoryList,
    currentPage,
    limit,
    isLoading,
    totalalbums,
    Languages,
    Filters: { categoryId, language, sort, contentType, text },
  } = useSelector((state) => state.podcast);
  const handleNavigateToPodcastallepisodes = (slug) => {
    navigate(`/podcast/${slug}`);
  };
  usePreserveScroll();

  useEffect(() => {
    dispatch(getPodcastBanner());
    dispatch(getalltags());
    dispatch(getLanguages());
    dispatch(getPodcastCategory());
    // dispatch(
    //   getAllPodcast({
    //     page: currentPage,
    //     limit: limit,
    //     tags: filters.tags,
    //   })
    // );
  }, []);

  const transformedData =
    Array.isArray(tags) &&
    tags.map((item) => ({
      label: item.name,
      value: item._id,
    }));

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  const handlefilterchange = (e) => {
    const { value, name } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const handleFilterChange = (e) => {
    const { value, name } = e.target;

    const payload = {
      [name]: value,
    };

    dispatch(filtersUpdate(payload));
  };

  const handleFiltersSubmit = (e, action) => {
    let tags = null;
    if (selectedOption?.length > 0) {
      tags = selectedOption.map((item) => item.value).join(",");
    }
    if (action == "loadmore") {
      dispatch(
        getAllPodcast({
          page: currentPage,
          limit: limit + 8,
          tags: tags,
        })
      );
    } else {
      dispatch(
        getAllPodcast({
          page: currentPage,
          limit: limit,
          tags: tags,
        })
      );
    }
  };

  useEffect(() => {
    dispatch(filtersUpdate(query ? { text: query } : { text: text }));
    handleFiltersSubmit();
  }, [query]);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleFiltersSubmit();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  return (
    <>
      <MetaData
        title={podcastbanner?.podcastbanner?.meta_title ?? "Dr. Archika Didi"}
        description={
          podcastbanner?.podcastbanner?.meta_description ?? "Dr. Archika Didi"
        }
        tags={podcastbanner?.podcastbanner?.meta_tag ?? "Dr. Archika Didi"}
      />{" "}
      {/* <!-- banner section --> */}
      {podcastbanner ? (
        <>
          <section className="overflow-hidden">
            <div className="desktop-only">
              <div className="position-relative d-flex">
                <img
                  loading="lazy"
                  src={`${podcastbanner?.podcastbanner?.background_image}`}
                  alt="Dr. Archika Didi"
                  className="w-100"
                />
                <div className="position-absolute w-100 h-100 top-0">
                  <div className="container h-100">
                    <div className="row h-100 align-items-center">
                      {podcastbanner?.podcastbanner?.alignItem === "left" && (
                        <>
                          <div className="col-lg-6 col-md-6 col-sm-6 col-6 d-flex flex-column align-items-start justify-content-center">
                            <div>
                              <h1 className="haedingfont">
                                <span>
                                  {podcastbanner?.podcastbanner?.heading}
                                </span>
                              </h1>
                              <p className="text-dark lh-lg homebdes text-justify">
                                {podcastbanner?.podcastbanner?.subheading}
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-6 col-12"></div>
                        </>
                      )}

                      {podcastbanner?.podcastbanner?.alignItem === "right" && (
                        <>
                          <div className="col-lg-6 col-md-6 col-sm-6 col-12"></div>

                          <div className="col-lg-6 col-md-6 col-sm-6 col-6 d-flex flex-column align-items-start justify-content-center">
                            <div>
                              <h1 className="haedingfont">
                                <span>
                                  {podcastbanner?.podcastbanner?.heading}
                                </span>
                              </h1>
                              <p className="text-dark lh-lg homebdes text-justify">
                                {podcastbanner?.podcastbanner?.subheading}
                              </p>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* banner starts here */}

            <div className="mobile-only">
              <div
                className="container-fluid mobile-only"
                style={{ position: "relative", display: "flex" }}
              >
                <img
                  src={`${podcastbanner?.podcastbanner?.res_background_image}`}
                  alt="Dr. Archika Didi"
                />
                <div
                  className="container"
                  style={{ position: "absolute", height: "100%" }}
                >
                  <div className="row" style={{ height: "100%" }}>
                    <div className="col-6 d-flex justify-content-start align-items-center">
                      {podcastbanner?.podcastbanner?.alignItem === "left" && (
                        <>
                          <p
                            className="homebannertitle"
                            style={{ fontFamily: "myFont", fontSize: "24px" }}
                            dangerouslySetInnerHTML={{
                              __html: podcastbanner?.podcastbanner?.headingres,
                            }}
                          ></p>
                        </>
                      )}
                    </div>
                    <div className="col-6 d-flex justify-content-start align-items-center">
                      {podcastbanner?.podcastbanner?.alignItem === "right" && (
                        <>
                          <p
                            className="homebannertitle"
                            style={{ fontFamily: "myFont", fontSize: "24px" }}
                            dangerouslySetInnerHTML={{
                              __html: podcastbanner?.podcastbanner?.headingres,
                            }}
                          ></p>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="container">
                <p
                  className="py-4 "
                  style={{
                    fontFamily: "Lato",
                    textAlign: "justify",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: podcastbanner?.podcastbanner?.subheadingres,
                  }}
                ></p>
              </div>

              {/* banner ends here */}
            </div>

            {/* banner ends here */}
          </section>
        </>
      ) : (
        <SmallLoader />
      )}
      {/* <!-- section mp3-music end --> */}
      <div
        className="container filterpad py-2 shadow"
        style={{ bottom: "40px" }}
      >
        <div className="row pb-4 p-3 m-0">
          <div
            className="col-lg-3 col-sm-12 col-md-6 d-flex justify-content-center align-items-center pola gap-2"
            id="mobileScreen"
          >
            <label
              htmlFor="filter"
              className="fw-bold myfiltericon"
              style={{ color: "#A0A0A0" }}
            >
              <img
                loading="lazy"
                style={{ width: "25px" }}
                src={filtericon}
                alt="Dr. Archika Didi"
              />
            </label>
            <select
              name="categoryId"
              value={categoryId}
              className="select searchborder rounded-pill p-2 px-2 my-2 mx-1 w-100"
              onChange={(e) => handleFilterChange(e)}
              onKeyDown={handleKeyPress}
            >
              <option value="">All Categories</option>
              {Array.isArray(podcastCategoryList) &&
                podcastCategoryList
                  ?.filter((e) => e.status === true)
                  .map((item, index) => {
                    return (
                      <option value={item?._id} key={index}>
                        {item?.title}
                      </option>
                    );
                  })}
            </select>
          </div>
          <div className="col-lg-3 col-sm-12 col-md-6 d-flex justify-content-center align-items-center pola">
            <select
              name="language"
              value={language}
              className="select searchborder rounded-pill p-2  px-2  my-2 mx-1 w-100"
              onChange={(e) => handleFilterChange(e)}
              onKeyDown={handleKeyPress}
            >
              <option value="">Language</option>
              {Array.isArray(Languages) &&
                Languages?.map((item, index) => {
                  return (
                    <option key={index} value={item?.language}>
                      {item?.language}
                    </option>
                  );
                })}
            </select>
          </div>
          <div
            className="col-lg-3 col-sm-12 col-md-6 d-flex justify-content-center align-items-center multitagsselction "
            // id="mobileScreen"
          >
            <Select
              // defaultValue={[tags[2], tags[3]]}
              isMulti
              name="tag"
              options={transformedData}
              onChange={handleChange}
              value={selectedOption}
              placeholder={"Select Tags"}
              id="tagss"
              onKeyDown={handleKeyPress}
              className="select  rounded-pill py-2 w-100  selecttagmusic"
              classNames={{
                control: (state) =>
                  state.isFocused
                    ? "searchborder rounded-pill pb-1"
                    : "searchborder rounded-pill pb-1",
              }}
              classNamePrefix="select"
            />
          </div>

          <div
            className="col-lg-3 col-sm-12 col-md-6 d-flex justify-content-center align-items-center"
            id="mobileScreen"
          >
            <label
              htmlFor="filter"
              className="fw-bold mysearchicon"
              style={{ color: "#A0A0A0" }}
            >
              <img
                src={searchicon}
                alt="Dr. Archika Didi"
                style={{ width: "25px" }}
                onClick={handleFiltersSubmit}
              />
            </label>
            <form className=" w-100 pt-4" role="search">
              <input
                className="form-control  searchborder rounded-pill p-2 w-100 searchborder"
                type="search"
                name="text"
                placeholder="Search by keyword..."
                aria-label="Search"
                onChange={(e) => handleFilterChange(e)}
                onKeyDown={handleKeyPress}
                value={text}
                style={{ borderRadius: "19px", color: "#A0A0A0" }}
              />
              <i className="fa-solid fa-magnifying-glass  mysearchiconforphone"></i>
            </form>
          </div>
          <div className="pt-3 d-flex justify-content-center">
            <button
              className="btn shadowbuttons btn-lg text-light cmn-t-shake mb-2"
              style={{
                padding: "0.6rem 2.5rem",
              }}
              onClick={handleFiltersSubmit}
            >
              SEARCH
            </button>
          </div>
        </div>
      </div>
      {/* <!-- album section --> */}
      <div className="p-3 album px-4">
        <div className="">
          {isLoading ? (
            <SmallLoader />
          ) : podcastlist?.length > 0 ? (
            <>
              <div className="container pt-4" ref={listStartRef} id="listStart">
                <div className="row g-3">
                  {Array.isArray(podcastlist) &&
                    podcastlist?.map((item, index) => (
                      <div
                        key={index}
                        className="col-md-6 col-sm-12 col-lg-3 mic rounded p-2 "
                        onClick={() =>
                          handleNavigateToPodcastallepisodes(item?.slug)
                        }
                      >
                        <div
                          className="card item-card card-block text-dark overflow-hidden"
                          style={{ borderRadius: "10px" }}
                        >
                          <img
                            src={item?.thumbnail_image}
                            alt="Dr. Archika Didi"
                            style={{ aspectRatio: "3/2" }}
                          />

                          <div
                            className="d-flex justify-content-between mt-2"
                            style={{ position: "relative" }}
                          >
                            <div className="moucis">
                              <a
                                href="#"
                                className="position-absolute"
                                style={{
                                  left: "2%",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={playbtn}
                                  style={{ width: "38px" }}
                                  alt="Dr. Archika Didi"
                                />
                                <i
                                  className="fa-solid fa-headphones px-2 fs-4"
                                  style={{ color: "#9767a2" }}
                                ></i>
                              </a>
                            </div>
                            <p
                              className="text-secondary"
                              style={{
                                fontFamily: "Lato",
                                paddingRight: "20px",
                              }}
                            >
                              {item.songList.length > 1
                                ? `Episodes ${item.songList.length}`
                                : `Episode ${item.songList.length}`}
                            </p>
                          </div>
                          <div className="mt-2" style={{}}>
                            <p className="green fw-semibold">
                              {item?.category[0]?.title}
                            </p>
                          </div>
                          <div className="play mb-4">
                            <h5
                              className="text-dark px-1 fw-semibold px-2"
                              style={{ fontFamily: "myFont" }}
                            >
                              {item?.title}
                            </h5>
                          </div>
                          {/* <div className="d-flex justify-content-between text-dark fw-semibold p-2 ">
                          <div className="px-1">{item?.artist[0]?.title}</div>
                          <div className="d-flex gap-2 "></div>
                        </div> */}
                        </div>
                      </div>
                    ))}
                </div>
              </div>

              <div className="col-12 mt-2">
                <div className="pt-3 d-flex justify-content-center py-5">
                  {podcastlist && podcastlist?.length == totalalbums ? (
                    ""
                  ) : (
                    <a>
                      <button
                        className="btn shadowbuttons btn-lg text-light cmn-t-shake"
                        onClick={(e) => handleFiltersSubmit(e, "loadmore")}
                      >
                        {isLoading ? (
                          <div
                            className="spinner-border text-dark"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        ) : (
                          <span>LOAD MORE</span>
                        )}
                      </button>
                    </a>
                  )}
                </div>
              </div>
            </>
          ) : (
            <section>
              <div className="text-center d-flex justify-content-center">
                <img
                  loading="lazy"
                  style={{ width: "400px" }}
                  src={nodata}
                  alt="Dr. Archika Didi"
                />
              </div>
            </section>
          )}
        </div>
      </div>
    </>
  );
};

export default Podcast;
