import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../appUtils/axiosConfig";
import {
  SHOW_ERROR_NOTIFICATION,
  SHOW_SUCCESS_NOTIFICATION,
} from "./../../appUtils/appToast";

const initialState = {
  user: null,
  isLoggedIn: false,
  isLoading: false,
  isLoginLoading: false,
  error: null,
  isAuthenticated: null,
  otpSent: null,
  intrest: null,
  userQuestionandThoughtlist: null,
  userAnswer: null,
  totalQuestionslist: 0,
  totalAnswerslist: 0,
  currentPage: 0,
  totalPages: 0,
  wellnessBookmarks: [],
  isProfileUpdated: false,
  isPasswordUpdated: false,
  updatedProfileData: [],
};

function saveUserData(responseData) {
  localStorage.setItem("userId", responseData?.user?._id);
  localStorage.setItem("userImage", responseData?.user?.image);
  localStorage.setItem("token", responseData?.token);
}

export const registerUser = createAsyncThunk(
  "user/register",
  async (userData, { dispatch, rejectWithValue }) => {
    try {
      dispatch(registerStart());

      const response = await axios.post(`user/register`, userData);

      dispatch(registerSuccess(response.data)); // Dispatch the success action with the response data
      return response.data;
    } catch (error) {
      dispatch(registerFailure(error.response.data.message));
      return rejectWithValue(error.response.data.message); // Return the error if needed
    }
  }
);

export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async (userData, { dispatch, rejectWithValue }) => {
    try {
      dispatch(loginStart());
      const response = await axios.post(`user/login`, userData);
      saveUserData(response.data);
      dispatch(loginSuccess(response.data?.user));
      if (response?.data?.success == true) {
        SHOW_SUCCESS_NOTIFICATION("Login Successfully");
      }
      // return response.data
    } catch (error) {
      dispatch(loginFailure(error.response.data.message));
      SHOW_ERROR_NOTIFICATION(error.response.data.message);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const checkSocialLogin = createAsyncThunk(
  "auth/checkSocialLogin",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      dispatch(checkSocialLoginstart());
      const url = `${process.env.REACT_APP_API_URL}/auth/login/success`;
      const response = await axios.get(url, {
        withCredentials: true,
        headers: { "Cache-Control": "no-cache" },
      });
      if (response.data?.success) {
        saveUserData(response.data);
        dispatch(checkSocialLoginsuccess(response.data?.user));
      }
    } catch (error) {
      dispatch(checkSocialLoginFailure(error.response.data.message));
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const loadUser = createAsyncThunk(
  "auth/loadUser",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      dispatch(loaduserStart());
      const response = await axios.get(`user/me`, {
        headers: { "Cache-Control": "no-cache" },
      });
      dispatch(loadUserSuccess(response?.data));
      return response?.data.success;
    } catch (error) {
      dispatch(loadUserfailure(error.response.data.message));
      return false;
      //return rejectWithValue(error.response.data.message)
    }
  }
);

export const getuserintrest = createAsyncThunk(
  "interest/allInterest",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      dispatch(userintrestStart());
      const response = await axios.get(`/interest/allInterest`);
      dispatch(userintrestSuccess(response?.data?.allInterest));
    } catch (error) {
      dispatch(userintrestFailure(error.response.data.message));
    }
  }
);
// const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export const logout = createAsyncThunk(
  "auth/logout",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      // dispatch(logoutuserStart())
      const response = await axios.post(`user/logout`, {
        withCredentials: true,
      });
      const url = `${process.env.REACT_APP_API_URL}/auth/sociallogout`;
      const response2 = await axios.get(url, {
        withCredentials: true,
        headers: { "Cache-Control": "no-cache" },
      });
      //removeUserData
      document.cookie =
        "session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie =
        "session.sig=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      localStorage.removeItem("userId");
      localStorage.removeItem("userImage");
      localStorage.removeItem("token");

      dispatch(logoutuserSuccess(response?.data?.message));
      return response?.data?.message;
    } catch (error) {
      dispatch(logoutuserFailure(error.response.data.message));
      //return rejectWithValue(error.response.data.message);
      return false;
    }
  }
);

export const likealbum = createAsyncThunk(
  "auth/likealbum",
  async (albumid, { dispatch, rejectWithValue }) => {
    try {
      dispatch(likealbumstart());
      const response = await axios.patch(`album/like/${albumid}`);
      dispatch(likealbumSuccess(response?.data));
    } catch (error) {
      dispatch(likealbumFailure(error.response.data.message));
    }
  }
);

export const getuserQuestionsandThoughts = createAsyncThunk(
  "auth/getuserQuestionsandThoughts",
  async ({ page, type, userId }, { dispatch, rejectWithValue }) => {
    let limit = 10;
    try {
      dispatch(getuserQuestionsandThoughtsStart());
      const response = await axios.get(
        `community/post/all?page=${
          page + 1
        }&limit=${limit}&type=${type}&user=${userId}`
      );
      dispatch(getuserQuestionsandThoughtsSuccess(response?.data?.postList));
      return response?.data?.postList;
    } catch (error) {
      dispatch(getuserQuestionsandThoughtsfailure(error.response.data.message));
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getuserAnswers = createAsyncThunk(
  "auth/getuserAnswers",
  async ({ userId, page }, { dispatch, rejectWithValue }) => {
    let limit = 10;
    try {
      dispatch(getuserAnswersStart());
      const response = await axios.get(
        `user/${userId}/answers?page=${page + 1}&limit=${limit}`
      );
      dispatch(getuserAnswersSuccess(response?.data?.data));
      return response?.data?.data;
    } catch (error) {
      dispatch(getuserAnswersfailure(error.response.data.message));
      return rejectWithValue(error.response.data.message);
    }
  }
);
//   forgot password
export const forgotPassword = createAsyncThunk(
  "auth/forgotpassword",
  async ({ email, mobile, countryCode }, { dispatch, rejectWithValue }) => {
    try {
      dispatch(forgotStart());
      const response = await axios.post(`user/password/forgot`, {
        email,
        mobile,
        countryCode,
      });
      dispatch(forgotSuccess(response?.data));
      return response.data;
    } catch (error) {
      dispatch(forgotFailure(error.response.data));
      // dispatch(forgotFailure(error.response.message));
      return rejectWithValue(error.response.message);
    }
  }
);

// verifyOTP
export const verifyOTP = createAsyncThunk(
  "auth/verifyotp",
  async (
    { email, mobile, countryCode, otp },
    { dispatch, rejectWithValue }
  ) => {
    try {
      dispatch(verifyOTPStart());
      const response = await axios.post(`user/password/verifyotp`, {
        email,
        mobile,
        countryCode,
        otp,
      });

      dispatch(verifyOTPSuccess(response.data));
      return response?.data;
    } catch (error) {
      dispatch(verifyOTPFailure(error.response.data));
      return rejectWithValue(error.response.data);
    }
  }
);

export const verifyRegisteredUserOTP = createAsyncThunk(
  "auth/verifyotp",
  async ({ mobile, otp }, { dispatch, rejectWithValue }) => {
    try {
      dispatch(verifyRegisteredUserStart());
      const response = await axios.post(`user/verifyRegisteredUser`, {
        mobile,
        otpFromUser: otp,
      });

      dispatch(verifyRegisteredUserSuccess(response.data));
      saveUserData(response.data);
      return response?.data;
    } catch (error) {
      dispatch(verifyRegisteredUserFailure(error.response.data));

      return rejectWithValue(error.response.data);
    }
  }
);

// reset password
export const resetPassword = createAsyncThunk(
  "auth/resetpassword",
  async (
    { token, password, confirmPassword },
    { dispatch, rejectWithValue }
  ) => {
    try {
      dispatch(resetStart());
      const response = await axios.put(`user/password/reset/${token}`, {
        password,
        confirmPassword,
      });

      dispatch(resetSuccess(response.success));

      return response.data;
    } catch (error) {
      dispatch(resetFailure(error.response.data));
      return rejectWithValue(error.response.message);
    }
  }
);

export const changePassword = createAsyncThunk(
  "auth/resetpassword",
  async (
    { oldPassword, newPassword, confirmPassword },
    { dispatch, rejectWithValue }
  ) => {
    try {
      dispatch(updatepasswordStart());
      const { data } = await axios.put(`user/password/update`, {
        oldPassword,
        newPassword,
        confirmPassword,
      });

      dispatch(updatepasswordSuccess(data.success));
      return data;
    } catch (error) {
      // Pass the error message from the backend response
      const errorMessage =
        error.response?.data?.message || "Password update failed";
      dispatch(updatepasswordFailure(errorMessage));
      return rejectWithValue({ message: errorMessage });
    }
  }
);

export const bookmarkWellness = createAsyncThunk(
  "auth/bookmarkWellness",
  async (id, { dispatch, rejectWithValue }) => {
    try {
      dispatch(bookmarkWellnessStart());
      const response = await axios.patch(`wellness/bookmark/${id}`);
      dispatch(bookmarkWellnessSuccess(response.data));
    } catch (error) {
      dispatch(bookmarkWellnessFailure(error.response.data.message));
      return rejectWithValue(error.response.data.message);
    }
  }
);
export const updateUserProfile = createAsyncThunk(
  "auth/updateUserProfile",
  async (userData, { dispatch, rejectWithValue }) => {
    try {
      dispatch(updateUserProfileStart());
      // Make PUT request to update user PROFILE
      const response = await axios.put(`user/update`, userData);

      // Dispatch the success action with the updated Profile data
      dispatch(updateUserProfileSuccess(response.data));
      return response.data;
    } catch (error) {
      // Dispatch the failure action with the error message
      dispatch(updateUserProfileFailure(error.response.data.message));
      return rejectWithValue(error.response.data.message);
    }
  }
);
export const resendOtp = createAsyncThunk(
  "user/resend_otp",
  async (userData, { dispatch, rejectWithValue }) => {
    try {
      dispatch(resendStart());

      const response = await axios.post(`user/resend_otp`, userData);

      dispatch(resendSuccess(response.data)); // Dispatch the success action with the response data
      return response.data;
    } catch (error) {
      dispatch(resendFailure(error.response.data.message));
      return rejectWithValue(error.response.data.message); // Return the error if needed
    }
  }
);
// export const updateWellnessBookmarks = createAsyncThunk(
//     'auth/updateWellnessBookmarks',
//     async (updatedBookmarks, { dispatch, rejectWithValue }) => {
//         try {
//             const response = await axios.patch(`/wellness/bookmark/${updatedBookmarks.id}`);
//             dispatch(updateWellnessBookmarksSuccess(updatedBookmarks));

//             return response.data;
//         } catch (error) {
//             dispatch(updateWellnessBookmarksFailure(error.response.data.message));

//             return rejectWithValue(error.response.data.message);
//         }
//     }
// );

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    registerStart(state) {
      state.isLoading = true;
      state.error = null;
      state.isAuthenticated = false;
    },
    registerSuccess(state, action) {
      // state.user = action.payload;
      state.isLoggedIn = true;
      state.isLoading = false;
      if (action.payload.success) {
        state.otpSent = true;
      }
    },
    registerFailure(state, action) {
      state.error = action.payload;
      state.isLoading = false;
      state.isAuthenticated = false;
    },
    loginStart(state) {
      state.isLoginLoading = true;
      state.error = null;
      state.isAuthenticated = false;
    },
    loginSuccess(state, action) {
      state.user = action.payload;
      state.isLoggedIn = true;
      state.isLoginLoading = false;
      state.isAuthenticated = true;
    },
    loginFailure(state, action) {
      state.error = action.payload;
      state.isLoginLoading = false;
      state.isAuthenticated = false;
    },
    loaduserStart(state) {
      state.isLoading = true;
      state.error = null;
      // state.isAuthenticated = false;
    },
    loadUserSuccess(state, action) {
      state.user = action.payload.user;
      state.isLoggedIn = true;
      state.isLoading = false;
      state.isAuthenticated = true;
    },
    loadUserfailure(state, action) {
      state.isLoading = false;
      state.isAuthenticated = false;
      state.user = null;
      state.error = action.payload;
    },
    logoutuserSuccess(state, action) {
      state.isLoading = false;
      state.isLoggedIn = false;
      state.isAuthenticated = false;
      state.user = null;
    },
    checkSocialLoginstart(state, action) {
      state.isLoading = true;
      state.error = null;
      state.isAuthenticated = false;
    },
    checkSocialLoginsuccess(state, action) {
      state.user = action.payload;
      state.isLoggedIn = true;
      state.isLoading = false;
      state.isAuthenticated = true;
    },
    checkSocialLoginFailure(state, action) {
      state.error = action.payload;
      state.isLoading = false;
      state.isAuthenticated = false;
    },
    likealbumstart(state, action) {
      state.isLoading = true;
    },
    likealbumSuccess(state, action) {
      state.isLoading = false;
      state.user = action.payload;
    },
    likealbumFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    userintrestStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    userintrestSuccess(state, action) {
      state.intrest = action.payload;
      state.isLoading = false;
    },
    userintrestFailure(state, action) {
      state.intrest = null;
      state.isLoading = false;
      state.error = action.payload;
    },
    //forgotpassword
    forgotStart(state, action) {
      state.isLoading = true;
    },
    forgotSuccess(state, action) {
      state.isLoading = false;
      // state.message =action.payload.message;
      state.message = action.payload;
    },
    forgotFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    verifyOTPStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    verifyOTPSuccess(state, action) {
      state.isLoading = false;
      state.message = action.payload.message;
      state.error = null;
    },
    verifyOTPFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload.message || action.payload;
    },
    verifyRegisteredUserStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    verifyRegisteredUserSuccess(state, action) {
      state.isLoading = false;
      state.message = action.payload.message;
      state.error = null;
      state.user = action.payload?.user;
      state.isAuthenticated = true;
    },
    verifyRegisteredUserFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload.message || action.payload;
    },
    resetStart(state, action) {
      state.isLoading = true;
    },
    resetSuccess(state, action) {
      state.isLoading = false;
      state.data = action.payload;
    },
    resetFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    updatepasswordStart(state, action) {
      state.isPasswordUpdated = true;
    },
    updatepasswordSuccess(state, action) {
      state.isPasswordUpdated = false;
      state.isUpdated = action.payload;
    },
    updatepasswordFailure(state, action) {
      state.isPasswordUpdated = false;
      state.error = action.payload;
      state.message = action.payload;
    },
    getuserQuestionsandThoughtsStart(state, action) {
      state.isLoading = true;
      state.totalQuestionslist = [];
    },
    getuserQuestionsandThoughtsSuccess(state, action) {
      state.isLoading = false;
      state.userQuestionandThoughtlist = action.payload;
      state.totalQuestionslist = action.payload.totalQuestionslist;
    },
    getuserQuestionsandThoughtsfailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getuserAnswersStart(state, action) {
      state.isLoading = true;
    },
    getuserAnswersSuccess(state, action) {
      state.isLoading = false;
      state.totalAnswerslist = action.payload.totalAnswerslist;
      state.totalPages = action.payload.totalPages;
      state.currentPage = action.payload.currentPage;

      if (state.userAnswer === null) {
        state.userAnswer = action?.payload;
      } else {
        state.userAnswer = [...state.userAnswer, ...action?.payload];
      }
    },
    getAnswerResetPage(state, action) {
      // state.userAnswer.page = 1;
      state.userAnswer = null;
    },
    getuserAnswersfailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // Other synchronous actions for login, loadUser, and logout can be similarly added
    bookmarkWellnessStart(state, action) {
      state.isLoading = true;
    },
    bookmarkWellnessSuccess(state, action) {
      state.isLoading = false;
      state.wellnessBookmarks = action.payload;
    },
    bookmarkWellnessFailure(state, action) {
      state.isLoading = false;
      state.wellnessBookmarks = action.payload;
    },
    // updateWellnessBookmarksStart(state) {
    //     state.isLoading = true;
    //     state.error = null;
    // },
    // updateWellnessBookmarksSuccess(state, action) {
    //     state.isLoading = false;
    //     state.wellnessBookmarks = action.payload; // Assuming the payload contains updated bookmarks
    // },
    // updateWellnessBookmarksFailure(state, action) {
    //     state.isLoading = false;
    //     state.error = action.payload;
    // },

    updateUserProfileStart(state, action) {
      state.isLoading = true;
      state.isProfileUpdated = false;
      state.error = null;
    },
    updateUserProfileSuccess(state, action) {
      state.isLoading = false;
      state.isProfileUpdated = true;
      state.updatedProfileData = action.payload;
      state.error = null;
    },
    updateUserProfileFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    resendStart(state) {
      state.isLoading = true;
      state.error = null;
      state.isAuthenticated = false;
    },
    resendSuccess(state, action) {
      // state.user = action.payload;
      state.isLoggedIn = true;
      state.isLoading = false;
      if (action.payload.success) {
        state.otpSent = true;
      }
    },
    resendFailure(state, action) {
      state.error = action.payload;
      state.isLoading = false;
      state.isAuthenticated = false;
    },
  },
});

export const {
  verifyRegisteredUserStart,
  verifyRegisteredUserSuccess,
  verifyRegisteredUserFailure,
  registerStart,
  registerSuccess,
  registerFailure,
  loginStart,
  loginSuccess,
  loginFailure,
  loaduserStart,
  loadUserSuccess,
  loadUserfailure,
  logoutuserSuccess,
  logoutuserFailure,
  checkSocialLoginstart,
  checkSocialLoginsuccess,
  checkSocialLoginFailure,
  likealbumstart,
  likealbumSuccess,
  likealbumFailure,
  userintrestStart,
  userintrestSuccess,
  userintrestFailure,
  forgotStart,
  forgotSuccess,
  forgotFailure,
  verifyOTPStart,
  verifyOTPSuccess,
  verifyOTPFailure,
  resetStart,
  resetSuccess,
  resetFailure,
  updatepasswordStart,
  updatepasswordSuccess,
  updatepasswordFailure,
  getuserQuestionsandThoughtsStart,
  getuserQuestionsandThoughtsSuccess,
  getuserQuestionsandThoughtsfailure,
  getuserAnswersStart,
  getuserAnswersSuccess,
  getuserAnswersfailure,
  getAnswerResetPage,
  bookmarkWellnessStart,
  bookmarkWellnessSuccess,
  bookmarkWellnessFailure,
  updateUserProfileStart,
  updateUserProfileSuccess,
  updateUserProfileFailure,
  resendStart,
  resendSuccess,
  resendFailure,
  // updateWellnessBookmarksStart,
  // updateWellnessBookmarksSuccess,
  // updateWellnessBookmarksFailure
} = authSlice.actions;

export default authSlice.reducer;
