import { useEffect } from "react";

const CookieManager = () => {
  console.log("running");

  const setCookie = (name, value, days) => {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    const expires = "expires=" + date.toUTCString();
    const creationTimestamp = new Date().getTime();
    document.cookie = `${name}=${value}; ${expires}; creationTimestamp=${creationTimestamp}; path=/`;
  };

  // Function to get the cookie value
  const getCookie = (name) => {
    const cookies = document.cookie.split("; ");
    for (let cookie of cookies) {
      const [cookieName, cookieValue] = cookie.split("=");
      if (cookieName === name) {
        return cookieValue;
      }
    }
    return null;
  };

  // Function to get the cookie creation timestamp
  const getCookieTimestamp = (name) => {
    const cookies = document.cookie.split("; ");
    for (let cookie of cookies) {
      const [cookieName, cookieValue] = cookie.split("=");
      if (cookieName === "creationTimestamp" && cookieValue) {
        return parseInt(cookieValue, 10); // Return the stored timestamp as an integer
      }
    }
    return null;
  };

  // Function to check if the user is logged in and clear the cookie after a specific timeout if not logged in
  const checkAndClearCookieIfNotLoggedIn = () => {
    const userSession = getCookie("userSession");
    const creationTimestamp = getCookieTimestamp("creationTimestamp");

    // If the userSession is not found and it's been more than 2 days, clear the cookies
    if (!userSession && creationTimestamp) {
      const now = new Date().getTime();
      const differenceInDays =
        (now - creationTimestamp) / (1000 * 60 * 60 * 24);

      if (differenceInDays > 2) {
        // Clear cookies if not logged in and 2 days have passed since creationTimestamp
        document.cookie =
          "userSession=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        document.cookie =
          "creationTimestamp=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        console.log(
          "User is not logged in. Cookie has been cleared after 2 days."
        );
      }
    }
  };

  useEffect(() => {
    checkAndClearCookieIfNotLoggedIn();
  }, []);

  return null;
};

export default CookieManager;
