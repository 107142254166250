import React, { useState, useRef, useEffect, useCallback } from "react";
import ".././component/layout/style.css";
import { getsinglealbum } from "../Allslices/albumSlice";
import { nextTrack, prevTrack, setCurrentTrack, setPlaying, setShowMusicBar, setSongPurchased, setUnpurchasedButPlaying } from "../Allslices/playerSlice";
import { useDispatch, useSelector } from "react-redux";
import { convertToMins } from "../appUtils";

import {
  Box,
  Flex,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Text,
} from "@chakra-ui/react";

import { BsSoundwave } from "react-icons/bs";


const SubpageMusicPlayer = ({ onSeek, albumId, audioRef, time, setSongDetails, isPurchased, albuumPurchased }) => {

  const dispatch = useDispatch()
  const { currentTrack, isPlaying, trackList } = useSelector((state) => state.player);

  const { artiste } = useSelector((state) => state.album);
  const { orderAll } = useSelector((state) => state?.order);


  let isSongPurchased = false;
  orderAll.map((item, index) => {

    if (item?.orderItems?.product == currentTrack?._id) {
      isSongPurchased = true;
    }
  })

  useEffect(() => {
    if (albumId) {
      dispatch(getsinglealbum(albumId))
    }
  }, [])

  useEffect(() => {
    if (!artiste?.isPaid && currentTrack?.isPaid && isSongPurchased) {
      dispatch(setSongPurchased(true));
    } else {
      dispatch(setSongPurchased(false));
    }
  }, [isSongPurchased]);

  const handlePlayPause = () => {
    
    if (artiste?.isPaid && !isPurchased && !albuumPurchased) {
      return;
    }
    if (!audioRef || !audioRef.current || typeof audioRef.current.play !== 'function') {
      dispatch(setShowMusicBar(false));
      return;
    }

    if (isPlaying) {
     
      audioRef?.current.pause();
      dispatch(setPlaying(false));
      dispatch(setShowMusicBar(false));
      
    } else {

      audioRef?.current.play();
      dispatch(setPlaying(true));
      dispatch(setShowMusicBar(true));
    }
  };

  const handleNextSong = () => {
    if (trackList.length == 1) {
      restartSong();
    } else {
      dispatch(nextTrack());
    }
  };

  const handlePreviousSong = () => {
    if (trackList.length == 1) {
      restartSong();
    } else {
      dispatch(prevTrack());
    }
  };

  const restartSong = () => {
    setSongDetails((prev) => {
      return { ...prev, time: 0 };
    });
    audioRef.current.currentTime = 0;
    audioRef.current.play();
  };

  // useEffect(() => {
    

  //   if ((isPlaying && !artiste?.isPaid && !isPurchased && !albuumPurchased && currentTrack?.isPaid)) {
  //     // dispatch(setPlaying(false));
  //     // dispatch(setShowMusicBar(false));
  //     // dispatch(setCurrentTrack(null));
  //     // audioRef?.current.pause();
  //     dispatch(setUnpurchasedButPlaying(true));
  //     window.location.reload();
  //     // dispatch(setPlaying(false));
  //     // dispatch(setShowMusicBar(false));
  //   }else{
  //     dispatch(setUnpurchasedButPlaying(false));
  //   }

  // }, [audioRef,dispatch])


  return (
    <>
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ maxWidth: "100%" }}
      >
        <div>
          <span className="text-start fw-semibold fs-6">
            {currentTrack?.title}
          </span>
        </div>
        <div
          className="d-flex justify-content-between"
          style={{ width: "7rem" }}
        >
          <span onClick={handlePreviousSong} style={{ cursor: "pointer" }}>
            <i className="fa-solid fa-backward-step fs-4"></i>
          </span>
          <span style={{ cursor: "pointer" }} onClick={(!currentTrack?.isPaid && currentTrack) || (currentTrack?.isPaid && isPurchased) || (artiste?.isPaid && !currentTrack?.isPaid && isSongPurchased) ? handlePlayPause : null} >
            {isPlaying ? (
              <i className={`fas fa-pause fs-4 `}></i>
            ) : (
              <i className={`fas fa-play fs-4  `}></i>
              // <i className={`fas fa-play fs-4 disabled`}></i>
            )}
          </span>
          <span onClick={handleNextSong} style={{ cursor: "pointer" }}>
            <i className="fa-solid fa-forward-step fs-4"></i>
          </span>
        </div>
      </div>



      <Flex justifyContent="space-between" gap={3}>
        {/* <Text fontSize="xs" color="zinc.500">
          {trackRef ? convertToMins(trackRef.currentTime) : "0:00"}
        </Text> */}
        <Slider
          outline={0}
          _focus={{ outline: 0 }}
          aria-label="seek-slider"
          defaultValue={0}
          width="100%"
          onChange={onSeek}
          value={!isNaN(time) ? time : 0}>
          <SliderTrack bg='gray.400'>
            <SliderFilledTrack bg="#b767a2" />
          </SliderTrack>
          <SliderThumb boxSize={6} outline={0}>
            <Box color="#b767a2" as={BsSoundwave} />
          </SliderThumb>
        </Slider>
        {/* <Text fontSize="xs" color="zinc.500">
          {track?.duration.split(".").join(":")}
        </Text> */}
      </Flex>




      <div
        className="d-flex justify-content-between"
        style={{ maxWidth: "100%" }}
      >
        {/* <div>
          <span className=" fs-5 text-secondary">{currentTrack?.description}</span>
        </div> */}
        <div className="d-flex gap-1 text-secondary">
          <span>{audioRef.current ? convertToMins(audioRef.current.currentTime) : "0:00"}</span>/<span>{audioRef.current?.duration ? convertToMins(audioRef.current.duration) : "0:00"}</span>
        </div>
      </div>

    </>
  );
};

export default SubpageMusicPlayer;
