import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import Moment from "react-moment";
import { convertToMins } from "../appUtils";
import MetaData from "../component/layout/MetaData";
import rect74 from "../images/Rectangle74.png";
import ad from "../images/ad.png";
import { getSingleWellnessArticle } from "../Allslices/wellnessSlice";
import { getSingleWellness } from "../Allslices/wellnessSlice";
import { getmyOrder } from "../Allslices/orderSlice";
import { SHOW_INFO_NOTIFICATION } from "../appUtils/appToast";

const WellnessSingleArticle = () => {
  const { slug } = useParams();
  const dispatch = useDispatch();
const navigate = useNavigate()
  const { singlewellnessarticle ,wellness} = useSelector((state) => state.allwellness);
  const location = useLocation();
  const currentpathUrl = location.pathname;
  const parentslug = currentpathUrl?.split("/")[3];
  const { orderAll } = useSelector((state) => state?.order);
  useEffect(() => {
    dispatch(getSingleWellnessArticle(slug));
    dispatch(getSingleWellness(parentslug));
    dispatch(getmyOrder());

  }, []);

  const audioRef = useRef(null);
  const [isPlaying, setPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    if (singlewellnessarticle && audioRef.current) {
      audioRef.current.src = singlewellnessarticle.audiofile;
    }
  }, [singlewellnessarticle]);

  const handlePlay = () => {
    audioRef.current.play();
    setPlaying(true);
  };

  const handlePause = () => {
    audioRef.current.pause();
    setPlaying(false);
  };

  const handleTogglePlayPause = () => {
    if (isPlaying) {
      handlePause();
    } else {
      handlePlay();
    }
  };

  const handleTimeUpdate = () => {
    setCurrentTime(audioRef.current.currentTime);
  };

  const handleSeek = (time) => {
    audioRef.current.currentTime = time;
    setCurrentTime(time);
  };

  const handleLoadedMetadata = () => {
    setDuration(audioRef.current.duration);
  };

  const handleBackward = () => {
    handleSeek(currentTime - 10); // Adjust backward duration as needed
  };

  const handleForward = () => {
    handleSeek(currentTime + 10); // Adjust forward duration as needed
  };
  let userId = localStorage.getItem("userId");

  useEffect(() => {
    if (wellness?.isPaid) {
      if (orderAll && orderAll.length > 0) {
        let purchased = false;
        
        for (const order of orderAll) {
          if (order&& order?.orderItems?.product && wellness?._id === order?.orderItems?.product) {
            purchased = true;
          }
      }
        
        // setIsPurchased(purchased);
  
        if (!purchased) {
          navigate(`/wellness/${parentslug}`);
        }
      }
      if (userId == null) {
        navigate("/login");
      SHOW_INFO_NOTIFICATION("Please login to access this Content");

      }
    }
  }, [orderAll, slug, navigate, wellness, parentslug]);
  
  
  return (
    <>
      <MetaData title="Wisdom Article" />

      {/* banner section */}

      {/* Checking Purpose */}
      <div
        className=" blogimg mb-2 meditation"
        style={{
          backgroundImage: `url(${singlewellnessarticle?.thumbnail_image})`,
          backgroundSize: "cover",
        }}
      >
        <div className="container blogsub">
          <div className="row text-center p-5">
            <div className="col-lg-12 col-sm-12">
              <h1 className=" text-dark" style={{ fontFamily: "myFont" }}>
                {singlewellnessarticle?.title}
              </h1>
              {/* <p
                className="pt-1 text-dark fw-semibold mt-4"
                style={{ fontFamily: "lato" }}
                dangerouslySetInnerHTML={{ __html: wellness?.description }}
              ></p> */}
            </div>
            <div className="pt-3 mt-3 d-flex justify-content-center">
              <img
                src={singlewellnessarticle?.thumbnail_image}
                alt="Dr. Archika Didi"
                className=""
                style={{
                  borderRadius: "10px",
                  boxShadow: "rgb(0, 0, 0) 1px 1px 17px 2px",
                }}
              />
            </div>
          </div>
        </div>
      </div>
     

      {/* banner section end */}
      {/* text section all */}
      <section>
        <div className="container">
          <div className="textrt ">
            <div
              className="contyu text-center"
              style={{ fontSize: "20px", padding: "35px 0 35px 0" }}
              dangerouslySetInnerHTML={{
                __html: singlewellnessarticle?.content,
              }}
            ></div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WellnessSingleArticle;
