import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
  Suspense,
  lazy,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SmallLoader from "./SmallLoader";
import searchicon from "../images/searchicons.png";
import filtericon from "../images/filtericons.png";
import nodata from "../images/No-Data.png";
import useGeolocation from "../component/shared/UserGeolocation";
import CourseCards from "./CourseCards";
import { useSelector, useDispatch } from "react-redux";
import { IoCloseSharp } from "react-icons/io5";
import {
  filtersUpdate,
  getAllCourses,
  getcoursebanner,
  getCourseCategories,
  getLanguages,
} from "../Allslices/courseSlice";
import MetaData from "../component/layout/MetaData";
import usePreserveScroll from "./histrorysave";
import { getmyOrder } from "../Allslices/orderSlice";

const Slider = lazy(() => import("react-slick"));

export default function OnlineClasses() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const listStartRef = useRef(null);
  const [yCoordinate, setYCoordinate] = useState(null);
  usePreserveScroll();

  const {
    courselist,
    course,
    courseCategories,
    Languages,
    isLoading,
    isCourseLoading,
    Filters: { categoryId, language, sort, text },
  } = useSelector((state) => state.course);
  const categorywisecourse = courselist?.categorywise;
  const upcomingCourses = courselist?.course_upcom;
  const newCourses = courselist?.course_new;
  const bestsellingCourses = courselist?.course_bestsell;
  const [loader, setLoader] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [message, setMessage] = useState("");
  const [fetchedCourses, setFetchedCourses] = useState([]);
  const { orderAll } = useSelector((state) => state?.order);
  const myorderlist = orderAll?.filter((item) => item?.orderType === "course");

  useEffect(() => {
    const element = listStartRef.current;
    if (element) {
      const position = element.getBoundingClientRect();
      const y = position.top;
      setYCoordinate(y);
    }
  }, []);
  const [totalcoursesCount, settotalCoursesCount] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [waitResponse, setWaitResponse] = useState(false);

  useEffect(() => {
    dispatch(getcoursebanner());
    dispatch(getCourseCategories());
    dispatch(getLanguages());
    dispatch(getmyOrder());
  }, []);

  const isComponentMounted = useRef(true);
  const [hoveredCourseIndex, setHoveredCourseIndex] = useState(null);
  const sliderRefs = useRef([]);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const query = location.search.split("?")[1];

  const [page, setPage] = useState(1);

  const handleFilterChange = (e) => {
    const { value, name } = e.target;
    const payload = {
      [name]: value,
    };
    dispatch(filtersUpdate(payload));
  };

  const maxLength = 80;
  const maxLength1 = 40;
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!hasMore) {
          setLoader(false);
          return;
        }
        setLoader(true);

        setWaitResponse(true);
        dispatch(filtersUpdate(query ? { text: query } : { text: text }));

        const action = await dispatch(
          getAllCourses({
            page: page,
          })
        );

        if (getAllCourses.fulfilled.match(action)) {
          if (action.payload.courses?.length === 0) {
            setHasMore(false);
            setLoader(false);
            return;
          }
          setFetchedCourses([...fetchedCourses, ...action.payload.courses]);
          settotalCoursesCount(action.payload?.totalCourses);
          setLoader(false);
          setWaitResponse(false);
        } else if (getAllCourses.rejected.match(action)) {
        }
      } catch (error) {
        setLoader(false);
        setWaitResponse(false);
      }
    };

    if (isComponentMounted.current) {
      fetchData();
    }
  }, []);

  const handlenavigateToCourseSinglePage = useCallback((item) => {
    if (item.coming_soon) {
      setMessage(
        item.message
          ? item.message
          : "This Course is coming soon. Please check back later!"
      );

      handleShow();
    } else {
      navigate(`/courses/${item?.slug}`);
    }
  });

  const handleFiltersSubmit = (e, action) => {
    if (action == "loadmore") {
      setLoader(true);
    }
    isComponentMounted.current = false;

    if (action == "loadmore") {
      setPage((prevpage) => prevpage + 1);
    }

    dispatch(
      getAllCourses({
        page: action == "loadmore" ? page + 1 : page,
      })
    ).then((response) => {
      if (action == "loadmore") {
        setFetchedCourses([...fetchedCourses, ...response?.payload?.courses]);
        setLoader(false);
      } else {
        setFetchedCourses(response?.payload?.courses);
        window.scrollTo({
          top: 650,
          behavior: "smooth",
        });
      }
    });
  };
  const NextArrow = useMemo(() => {
    return (props) => {
      const { className, style, onClick } = props;
      return (
        <div
          className={className}
          style={{
            ...style,
            display: "block",
            borderRadius: "50%",
            padding: "10px",
            position: "absolute",
            right: "-15px!important",
            height: "40px",
            width: "40px",
            zIndex: "1",
          }}
          onClick={onClick}
        ></div>
      );
    };
  });

  const PrevArrow = useMemo(() => {
    return (props) => {
      const { className, style, onClick } = props;
      return (
        <div
          className={className}
          style={{
            ...style,
            display: "block",
            borderRadius: "50%",
            padding: "10px",
            position: "absolute",
            left: "-35px",
            height: "40px",
            width: "40px",
            zIndex: "1",
          }}
          onClick={onClick}
        ></div>
      );
    };
  });

  let sliderRef1 = useRef();
  const numCards = newCourses?.length >= 3;
  const numCardss = newCourses?.length >= 2;

  const settings1 = useMemo(
    () => ({
      infinite: numCards,
      autoscroll: true,
      autoplay: true,
      autoplayspeed: 6000,
      speed: 700,
      slidesToShow: 3,
      slidesToScroll: 1,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: numCardss,
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
      ],
    }),
    []
  );

  const numCards1 = upcomingCourses?.length >= 3;

  const settings2 = useMemo(
    () => ({
      infinite: numCards1,
      autoscroll: true,
      autoplay: true,
      autoplayspeed: 6000,
      speed: 700,
      slidesToShow: 3,
      slidesToScroll: 1,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
      ],
    }),
    []
  );
  const numCards3 = bestsellingCourses?.length >= 3;

  const settings3 = useMemo(
    () => ({
      infinite: numCards3,
      autoscroll: true,
      autoplay: true,
      autoplayspeed: 6000,
      speed: 700,
      slidesToShow: 3,
      slidesToScroll: 1,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
      ],
    }),
    []
  );

  const userGeolocationCode = useGeolocation();

  // const renderPrice = (
  //   selling_price_rupee,
  //   selling_price_usd,
  //   mrp_price_rupee,
  //   mrp_price_usd
  // ) => {
  //   const isIndianUser = userGeolocationCode === "IN";
  //   const sellingPrice =
  //     isIndianUser || !userGeolocationCode
  //       ? selling_price_rupee
  //       : selling_price_usd;
  //   const mrpPrice =
  //     isIndianUser || !userGeolocationCode ? mrp_price_rupee : mrp_price_usd;
  //   const currencySymbol = isIndianUser || !userGeolocationCode ? "₹" : "$";

  //   if (sellingPrice === mrpPrice) {
  //     return ` ${currencySymbol}${sellingPrice}`;
  //   } else if (sellingPrice < mrpPrice) {
  //     return (
  //       <>
  //         {" "}
  //         <span
  //           style={{
  //             textDecoration: "line-through",
  //             color: "rgb(91, 91, 91)",
  //             fontSize: "14px",
  //           }}
  //         >
  //           {currencySymbol}
  //           {mrpPrice}
  //         </span>{" "}
  //         {currencySymbol}
  //         {sellingPrice}
  //       </>
  //     );
  //   } else {
  //     return ` ${currencySymbol}${sellingPrice}`;
  //   }
  // };

  const renderPrice = useCallback(
    (
      selling_price_rupee,
      selling_price_usd,
      mrp_price_rupee,
      mrp_price_usd
    ) => {
      const isIndianUser = userGeolocationCode === "IN";
      const sellingPrice =
        isIndianUser || !userGeolocationCode
          ? selling_price_rupee
          : selling_price_usd;
      const mrpPrice =
        isIndianUser || !userGeolocationCode ? mrp_price_rupee : mrp_price_usd;
      const currencySymbol = isIndianUser || !userGeolocationCode ? "₹" : "$";

      if (sellingPrice === mrpPrice) {
        return ` ${currencySymbol}${sellingPrice}`;
      } else if (sellingPrice < mrpPrice) {
        return (
          <>
            <span
              style={{
                textDecoration: "line-through",
                color: "rgb(91, 91, 91)",
                fontSize: "14px",
              }}
            >
              {currencySymbol}
              {mrpPrice}
            </span>{" "}
            {currencySymbol}
            {sellingPrice}
          </>
        );
      } else {
        return ` ${currencySymbol}${sellingPrice}`;
      }
    },
    [userGeolocationCode]
  );
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleFiltersSubmit();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  return (
    <div className="mb-4">
      <MetaData
        title={course?.coursebanner?.meta_title ?? "Dr. Archika Didi"}
        description={
          course?.coursebanner?.meta_description ?? "Dr. Archika Didi"
        }
        tags={course?.coursebanner?.meta_tag ?? "Dr. Archika Didi"}
      />
      {isLoading ? (
        <SmallLoader />
      ) : (
        <>
          <section className="overflow-hidden">
            <div className="desktop-only">
              <div className="position-realtive d-flex">
                <img
                  loading="lazy"
                  src={`${course?.coursebanner?.background_image}`}
                  alt="Dr. Archika Didi"
                  className="w-100"
                />
                <div className="position-absolute w-100 h-100 top-0">
                  <div className="container h-100">
                    <div className="row h-100 align-items-center">
                      {course?.coursebanner?.alignItem === "left" && (
                        <>
                          <div className="col-lg-6 col-md-6 col-sm-6 col-6 d-flex flex-column align-items-start justify-content-center">
                            <div>
                              <h1 className="fw-bold ">
                                <span className="wisdomhead haedingfont">
                                  {course?.coursebanner?.heading}
                                </span>
                              </h1>
                              <p className=" w-75 wisdomPara homebdes coursefontandalign">
                                {course?.coursebanner?.subheading}
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-6 col-12"></div>
                        </>
                      )}
                      {course?.coursebanner?.alignItem === "right" && (
                        <>
                          <div className="col-lg-6 col-md-6 col-sm-6 col-12"></div>
                          <div className="col-lg-6  col-md-6 col-sm-6 col-6 d-flex flex-column align-items-start justify-content-center">
                            <div>
                              <h1 className="fw-bold ">
                                <span className="wisdomhead haedingfont">
                                  {course?.coursebanner?.heading}
                                </span>
                              </h1>
                              <p className=" w-75 wisdomPara homebdes coursefontandalign">
                                {course?.coursebanner?.subheading}
                              </p>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mobile-only">
              <div className="container-fluid mobile-only position-relative d-flex">
                <img
                  loading="lazy"
                  src={`${course?.coursebanner?.res_background_image}`}
                  alt="Dr. Archika Didi"
                />
                <div className="container position-absolute h-100">
                  <div className="row h-100">
                    <div className="col-6 d-flex justify-content-start align-items-center">
                      {course?.coursebanner?.alignItem === "left" && (
                        <>
                          <p
                            className="homebannertitle coursebannertitle"
                            dangerouslySetInnerHTML={{
                              __html: course?.coursebanner?.res_heading,
                            }}
                          ></p>
                        </>
                      )}
                    </div>
                    <div className="col-6 d-flex justify-content-start align-items-center">
                      {course?.coursebanner?.alignItem === "right" && (
                        <>
                          <p
                            className="homebannertitle coursebannertitle"
                            dangerouslySetInnerHTML={{
                              __html: course?.coursebanner?.res_heading,
                            }}
                          ></p>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="container">
                <p
                  className="py-4 coursefontandalign"
                  dangerouslySetInnerHTML={{
                    __html: course?.coursebanner?.res_subheading,
                  }}
                ></p>
              </div>
            </div>
          </section>

          <div className="container filterpad  shadow sidemedia py-2">
            <div className="row pb-4 p-3 justify-content-center">
              <div
                className="col-lg-3 col-sm-12 col-md-6 d-flex justify-content-center align-items-center gap-2"
                id="mobileScreen"
              >
                <label
                  htmlFor="filter"
                  className="fw-bold myfiltericon coursesearchicon"
                >
                  <img loading="lazy" src={filtericon} alt="Dr. Archika Didi" />
                </label>
                <select
                  className="select searchborder rounded-pill p-2 w-100 my-2"
                  value={language}
                  name="language"
                  onChange={(e) => handleFilterChange(e)}
                  onKeyDown={handleKeyPress}
                >
                  <option value="">All Languages</option>
                  {Array.isArray(Languages) &&
                    Languages?.map((item, index) => {
                      return (
                        <option
                          key={index}
                          value={item?.language}
                          className="text-black"
                        >
                          {item?.language}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div
                className="col-lg-3 col-sm-12 col-md-6 d-flex justify-content-center align-items-center"
                id="mobileScreen"
              >
                <select
                  className="select searchborder rounded-pill p-2 w-100 px-2 mx-1 my-2"
                  value={categoryId}
                  name="categoryId"
                  onKeyDown={handleKeyPress}
                  onChange={(e) => handleFilterChange(e)}
                >
                  <option value="">Category</option>
                  {Array.isArray(courseCategories) &&
                    courseCategories?.map((item, index) => {
                      return (
                        <option key={index} value={item?._id}>
                          {item?.name}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div
                className="col-lg-3 col-sm-12 col-md-6 d-flex justify-content-center align-items-center"
                id="mobileScreen"
              >
                <select
                  value={sort}
                  name="sort"
                  className="select searchborder rounded-pill p-2 w-100 px-2 my-2"
                  onChange={(e) => handleFilterChange(e)}
                  onKeyDown={handleKeyPress}
                >
                  <option value="English">Sort by</option>
                  <option value="newest">Newest</option>
                  <option value="oldest">Oldest</option>
                </select>
              </div>

              <div
                className="col-lg-3 col-sm-12 col-md-6 d-flex justify-content-center align-items-center"
                id="mobileScreen"
              >
                <label
                  htmlFor="filter"
                  className="fw-bold mysearchicon coursesearchicon"
                >
                  <img
                    loading="lazy"
                    src={searchicon}
                    alt="Dr. Archika Didi"
                    onClick={handleFiltersSubmit}
                  />
                </label>
                <form className=" w-100 pt-4" role="search">
                  <input
                    className="form-control me-2 w-100 searchborder paddingdesign searchborr"
                    name="text"
                    placeholder="Search by keyword..."
                    aria-label="Search"
                    onChange={(e) => handleFilterChange(e)}
                    value={text}
                    onKeyDown={handleKeyPress}
                  />
                  <i className="fa-solid fa-magnifying-glass mysearchiconforphone "></i>
                </form>
              </div>
              <div className="pt-3 d-flex justify-content-center ">
                <button
                  className="btn  shadowbuttons btn-lg text-light cmn-t-shake coursesearchbtn"
                  onClick={handleFiltersSubmit}
                >
                  SEARCH
                </button>
              </div>
            </div>
          </div>

          {isLoading ? (
            <SmallLoader />
          ) : (
            <>
              {bestsellingCourses?.length > 0 ||
              (Array.isArray(categorywisecourse) &&
                categorywisecourse.find((e) => e?.courselist?.length > 0)) ||
              newCourses?.length > 0 ||
              upcomingCourses?.length > 0 ? (
                <>
                  <div className="container artvid overflow-hidden ">
                    {bestsellingCourses?.length > 0 && (
                      <h2 className="text-start   ps-3 bestsellinghead coursesheadings">
                        Best Selling Programmes
                      </h2>
                    )}
                    <div
                      className={`row g-3 py-2 artvid33 slidercourse ${
                        bestsellingCourses?.length <= 2
                          ? "sliderscontainercourse"
                          : ""
                      }`}
                    >
                      <Suspense fallback={<div>Loading...</div>}>
                        <Slider
                          ref={(slider) => {
                            sliderRef1 = slider;
                          }}
                          {...settings3}
                        >
                          {Array.isArray(bestsellingCourses) &&
                            bestsellingCourses.map((item, index) => (
                              <div
                                className="col-md-6 col-sm-12 col-lg-3 mb-3"
                                onClick={() =>
                                  handlenavigateToCourseSinglePage(item)
                                }
                                key={index}
                              >
                                <div className="card item-card card-block mx-4 my-3 overflow-hidden coursecards">
                                  {item?.sliderImages?.map(
                                    (slider_image, idx) => (
                                      <div className="" key={idx}>
                                        <img
                                          loading="lazy"
                                          src={slider_image?.course_image}
                                          alt="Dr. Archika Didi"
                                        />
                                      </div>
                                    )
                                  )}

                                  <div className="container courseapixoss">
                                    <h4 className="mt-3">
                                      {item.title.length > maxLength1
                                        ? `${item.title.substring(
                                            0,
                                            maxLength1
                                          )}...`
                                        : item.title}
                                    </h4>
                                    <p
                                      className="card-text lato my-2 coursecarddes"
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          item.shortDescription.length >
                                          maxLength
                                            ? `${item.shortDescription
                                                ?.replace(/<\/?p>/gi, "")
                                                .substring(0, maxLength)}...`
                                            : item.shortDescription?.replace(
                                                /<\/?p>/gi,
                                                ""
                                              ),
                                      }}
                                    ></p>
                                    <div className="coursecolor">
                                      <i className="fa-solid fa-video  fs-6 pt-1 text-info " />{" "}
                                      Online
                                      <i className="fa-solid fa-language  fs-6 pt-1 text-info ms-2" />{" "}
                                      {item.courseLanguage}
                                    </div>

                                    <div className="my-3 d-flex justify-content-between">
                                      <button
                                        type="button"
                                        className="btn btn-lg  d-sm-5 fs-6 courseregisterbtn"
                                      >
                                        {myorderlist &&
                                        myorderlist.some(
                                          (ele) =>
                                            ele.orderItems.product === item._id
                                        )
                                          ? "Open"
                                          : "Register"}
                                      </button>
                                      <span className="d-flex flex-column coursecolor">
                                        {myorderlist &&
                                          myorderlist.every(
                                            (ele) =>
                                              ele.orderItems.product !==
                                              item._id
                                          ) && (
                                            <span className="d-flex flex-column coursecolor">
                                              {" "}
                                              {item.selling_price_rupee > 0
                                                ? renderPrice(
                                                    item.selling_price_rupee,
                                                    item.selling_price_usd,
                                                    item?.mrp_price_rupee,
                                                    item.mrp_price_usd
                                                  )
                                                : "Free"}
                                            </span>
                                          )}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              // <CourseCards
                              //   key={index}
                              //   item={item}
                              //   onClick={handlenavigateToCourseSinglePage}
                              //   renderPrice={renderPrice}
                              // />
                            ))}
                        </Slider>
                      </Suspense>
                    </div>
                  </div>
                  <div className="container overflow-hidden">
                    <div>
                      {Array.isArray(categorywisecourse) &&
                        categorywisecourse.map((ele, index) => {
                          const infinitestatus = ele?.courselist?.length >= 3;
                          const infinitestatus2 = ele?.courselist?.length >= 2;
                          const settings3 = {
                            infinite: infinitestatus,
                            autoscroll: true,
                            autoplay: true,
                            autoplayspeed: 6000,
                            speed: 700,
                            slidesToShow: 3,
                            slidesToScroll: 1,
                            nextArrow: <NextArrow />,
                            prevArrow: <PrevArrow />,
                            responsive: [
                              {
                                breakpoint: 768,
                                settings: {
                                  slidesToShow: 1,
                                  slidesToScroll: 1,
                                  infinite: infinitestatus2,
                                },
                              },
                              {
                                breakpoint: 992,
                                settings: {
                                  slidesToShow: 3,
                                  slidesToScroll: 3,
                                },
                              },
                              {
                                breakpoint: 1200,
                                settings: {
                                  slidesToShow: 3,
                                  slidesToScroll: 3,
                                },
                              },
                            ],
                          };
                          return (
                            <div className="" key={index}>
                              {ele?.courselist?.length > 0 ? (
                                <>
                                  <h2 className="text-start pt-3  ps-3 coursesheadings">
                                    {ele.name}
                                  </h2>

                                  <div
                                    className={`row g-3 py-2  slidercourse ${
                                      ele?.courselist?.length <= 2
                                        ? "sliderscontainercourse"
                                        : ""
                                    } `}
                                  >
                                    <Suspense fallback={<div>Loading...</div>}>
                                      <Slider
                                        ref={(slider) => {
                                          sliderRef1 = slider;
                                        }}
                                        {...settings3}
                                      >
                                        {ele?.courselist.map((item, index) => (
                                          <div
                                            className="col-md-6 col-sm-12 col-lg-3 mb-3"
                                            onClick={() =>
                                              handlenavigateToCourseSinglePage(
                                                item
                                              )
                                            }
                                            key={index}
                                          >
                                            <div className="card item-card card-block mx-4 my-3 overflow-hidden coursecards">
                                              {item?.sliderImages?.map(
                                                (slider_image, idx) => (
                                                  <div className="" key={idx}>
                                                    <img
                                                      loading="lazy"
                                                      src={
                                                        slider_image?.course_image
                                                      }
                                                      alt="Dr. Archika Didi"
                                                    />
                                                  </div>
                                                )
                                              )}

                                              <div className="container courseapixoss">
                                                <h4 className="mt-3">
                                                  {item.title.length >
                                                  maxLength1
                                                    ? `${item.title.substring(
                                                        0,
                                                        maxLength1
                                                      )}...`
                                                    : item.title}
                                                </h4>
                                                <p
                                                  className="card-text lato my-2 coursecarddes"
                                                  dangerouslySetInnerHTML={{
                                                    __html:
                                                      item.shortDescription
                                                        .length > maxLength
                                                        ? `${item.shortDescription
                                                            ?.replace(
                                                              /<\/?p>/gi,
                                                              ""
                                                            )
                                                            .substring(
                                                              0,
                                                              maxLength
                                                            )}...`
                                                        : item.shortDescription?.replace(
                                                            /<\/?p>/gi,
                                                            ""
                                                          ),
                                                  }}
                                                ></p>
                                                <div className="coursecolor">
                                                  <i className="fa-solid fa-video fs-6 pt-1 text-info" />{" "}
                                                  Online{" "}
                                                  <i className="fa-solid fa-language fs-6 pt-1 text-info ms-2" />{" "}
                                                  {item.courseLanguage}
                                                </div>

                                                <div className="my-3 d-flex justify-content-between align-items-center">
                                                  {!item.coming_soon && (
                                                    <>
                                                      <button
                                                        type="button"
                                                        className="btn btn-lg d-sm-5 fs-6 courseregisterbtn "
                                                      >
                                                        {myorderlist &&
                                                        myorderlist.some(
                                                          (ele) =>
                                                            ele.orderItems
                                                              .product ===
                                                            item._id
                                                        )
                                                          ? "Open"
                                                          : "Register"}
                                                      </button>

                                                      {myorderlist &&
                                                        myorderlist.every(
                                                          (ele) =>
                                                            ele.orderItems
                                                              .product !==
                                                            item._id
                                                        ) && (
                                                          <span className="d-flex flex-column coursecolor">
                                                            {" "}
                                                            {item.selling_price_rupee >
                                                            0
                                                              ? renderPrice(
                                                                  item.selling_price_rupee,
                                                                  item.selling_price_usd,
                                                                  item?.mrp_price_rupee,
                                                                  item.mrp_price_usd
                                                                )
                                                              : "Free"}
                                                          </span>
                                                        )}
                                                    </>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          // <CourseCards
                                          //   key={index}
                                          //   item={item}
                                          //   onClick={handlenavigateToCourseSinglePage}
                                          //   renderPrice={renderPrice}
                                          // />
                                        ))}
                                      </Slider>
                                    </Suspense>
                                  </div>
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          );
                        })}
                    </div>
                  </div>

                  {newCourses?.length > 0 && (
                    <div className="container artvid  overflow-hidden  ">
                      <h2 className="text-start pt-3  ps-3 coursesheadings">
                        New Launches
                      </h2>

                      <div
                        className={`row g-3 py-2 artvid33 slidercourse ${
                          newCourses?.length <= 2
                            ? "sliderscontainercourse"
                            : ""
                        }`}
                      >
                        <Suspense fallback={<div>Loading...</div>}>
                          <Slider
                            ref={(slider) => {
                              sliderRef1 = slider;
                            }}
                            {...settings1}
                          >
                            {Array.isArray(newCourses) &&
                              newCourses.map((item, index) => (
                                <div
                                  className="col-md-6 col-sm-12 col-lg-3 mb-3"
                                  onClick={() =>
                                    handlenavigateToCourseSinglePage(item)
                                  }
                                  key={index}
                                >
                                  <div className="card item-card card-block mx-4 my-3 overflow-hidden coursecards">
                                    {item?.sliderImages?.map(
                                      (slider_image, idx) => (
                                        <div className="" key={idx}>
                                          <img
                                            loading="lazy"
                                            src={slider_image?.course_image}
                                            alt="Dr. Archika Didi"
                                          />
                                        </div>
                                      )
                                    )}

                                    <div className="container courseapixoss">
                                      <h4 className="mt-3">
                                        {item.title.length > maxLength1
                                          ? `${item.title.substring(
                                              0,
                                              maxLength1
                                            )}...`
                                          : item.title}
                                      </h4>
                                      <p
                                        className="card-text lato my-2 coursecarddes"
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            item.shortDescription.length >
                                            maxLength
                                              ? `${item.shortDescription
                                                  ?.replace(/<\/?p>/gi, "")
                                                  .substring(0, maxLength)}...`
                                              : item.shortDescription?.replace(
                                                  /<\/?p>/gi,
                                                  ""
                                                ),
                                        }}
                                      ></p>
                                      <div className="coursecolor">
                                        <i className="fa-solid fa-video  fs-6 pt-1 text-info" />{" "}
                                        Online{" "}
                                        <i className="fa-solid fa-language  fs-6 pt-1 text-info ms-2" />{" "}
                                        {item.courseLanguage}
                                      </div>

                                      <div className="my-3 d-flex justify-content-between">
                                        <button
                                          type="button "
                                          className="btn btn-lg  d-sm-5 fs-6 courseregisterbtn"
                                        >
                                          {myorderlist &&
                                          myorderlist.some(
                                            (ele) =>
                                              ele.orderItems.product ===
                                              item._id
                                          )
                                            ? "Open"
                                            : "Register"}
                                        </button>
                                        <span className="d-flex flex-column coursecolor">
                                          {myorderlist &&
                                            myorderlist.every(
                                              (ele) =>
                                                ele.orderItems.product !==
                                                item._id
                                            ) && (
                                              <span className="d-flex flex-column coursecolor">
                                                {" "}
                                                {item.selling_price_rupee > 0
                                                  ? renderPrice(
                                                      item.selling_price_rupee,
                                                      item.selling_price_usd,
                                                      item?.mrp_price_rupee,
                                                      item.mrp_price_usd
                                                    )
                                                  : "Free"}
                                              </span>
                                            )}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                // <CourseCards
                                //   key={index}
                                //   item={item}
                                //   onClick={handlenavigateToCourseSinglePage}
                                //   renderPrice={renderPrice}
                                // />
                              ))}
                          </Slider>
                        </Suspense>
                      </div>
                    </div>
                  )}
                  {upcomingCourses?.length > 0 && (
                    <div className="container artvid overflow-hidden">
                      <h3 className="text-start pt-3  ps-3 coursesheadings">
                        Coming Soon
                      </h3>

                      <div
                        className={`row g-3 py-2 artvid33 slidercourse ${
                          upcomingCourses?.length <= 2
                            ? "sliderscontainercourse"
                            : ""
                        }`}
                      >
                        <Suspense fallback={<div>Loading...</div>}>
                          <Slider
                            ref={(slider) => {
                              sliderRef1 = slider;
                            }}
                            {...settings2}
                          >
                            {Array.isArray(upcomingCourses) &&
                              upcomingCourses.map((item, index) => (
                                <div
                                  className="col-md-6 col-sm-12 col-lg-3 mb-3"
                                  onClick={() =>
                                    handlenavigateToCourseSinglePage(item)
                                  }
                                  key={index}
                                >
                                  <div className="card item-card card-block mx-4 my-3 overflow-hidden coursecards">
                                    {item?.sliderImages?.map(
                                      (slider_image, idx) => (
                                        <div className="" key={idx}>
                                          <img
                                            loading="lazy"
                                            src={slider_image?.course_image}
                                            alt="Dr. Archika Didi"
                                          />
                                        </div>
                                      )
                                    )}

                                    <div className="container courseapixoss">
                                      <h4 className="mt-3">
                                        {item.title.length > maxLength1
                                          ? `${item.title.substring(
                                              0,
                                              maxLength1
                                            )}...`
                                          : item.title}
                                      </h4>
                                      <p
                                        className="card-text lato my-2 coursecarddes"
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            item.shortDescription.length >
                                            maxLength
                                              ? `${item.shortDescription
                                                  ?.replace(/<\/?p>/gi, "")
                                                  .substring(0, maxLength)}...`
                                              : item.shortDescription?.replace(
                                                  /<\/?p>/gi,
                                                  ""
                                                ),
                                        }}
                                      ></p>
                                      <div className="coursecolor">
                                        <i className="fa-solid fa-video  fs-6 pt-1 text-info " />{" "}
                                        Online{" "}
                                        <i className="fa-solid fa-language  fs-6 pt-1 ms-2 text-info" />{" "}
                                        {item.courseLanguage}
                                      </div>

                                      {/* <div className="my-3 d-flex justify-content-between">
                                <button
                                  type="button"
                                  className="btn btn-lg  d-sm-5 fs-6 courseregisterbtn"
                                >
                                  Register
                                </button>
                                {/* <span className="d-flex flex-column coursecolor">
                                  {" "}
                                  {item.selling_price_rupee > 0
                                    ? renderPrice(
                                        item.selling_price_rupee,
                                        item.selling_price_usd,
                                        item?.mrp_price_rupee,
                                        item.mrp_price_usd
                                      )
                                    : "Free"}
                                </span> */}
                                      {/* </div> */}
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </Slider>
                        </Suspense>
                      </div>
                    </div>
                  )}
                  <div
                    className={`modal fade ${showModal ? "show" : ""}`}
                    id="exampleModal"
                    tabIndex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden={!showModal}
                    style={{ display: showModal ? "block" : "none" }}
                  >
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5
                            className="modal-title modaltitle"
                            id="exampleModalLabel"
                          >
                            Coming Soon
                          </h5>
                          <button
                            type="button"
                            className="fs-2 comingsoonbtn"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={handleClose}
                          >
                            <IoCloseSharp />
                          </button>
                        </div>
                        <div className="modal-body lato  ">{message}</div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <section>
                  <div className="text-center d-flex justify-content-center coursenodata">
                    {/* <img loading="lazy" src={nodata} alt="Dr. Archika Didi" /> */}
                  </div>
                </section>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}
