import React, { useEffect, useState, useRef } from "react";
import ".././component/layout/style.css";

import { useNavigate, useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  filtersUpdate,
  getAllWellness,
  getWellnessCategory,
  getwellnessbanner,
} from "../Allslices/wellnessSlice";
import { getLanguages } from "../Allslices/wisdomSlice";
import SmallLoader from "./SmallLoader";
import MetaData from "../component/layout/MetaData";
import crownimg from "../../src/images/crown.png";
import { getmyOrder } from "../Allslices/orderSlice";
import filtericon from "../images/filtericons.png";
import searchicon from "../images/searchicons.png";
import usePreserveScroll from "./histrorysave";
import nodata from "../images/No-Data.png";
const WellnessBundle = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const listStartRef = useRef(null);
  const [yCoordinate, setYCoordinate] = useState(null);
  usePreserveScroll();

  const url = window.location.href;
  const id = url.split("/")[4];

  const query = location.search.split("?")[1];

  const dispatch = useDispatch();

  const [loader, setLoader] = useState(true);

  const [hasMore, setHasMore] = useState(true);
  const [waitResponse, setWaitResponse] = useState(false);

  const [fetchedWellness, setFetchedwellness] = useState([]);
  const [totalwellnessCount, settotalWellnessCount] = useState(0);

  let initialfilters = {
    categoryId: null,
    language: null,
    // contenttype: null,
    newest: "",
    text: query ?? "",
  };
  const [filters, setfilters] = useState(initialfilters);

  const {
    wellness,
    wellnesslist,
    currentPage,
    limit,
    isLoading,
    totalwellness,
    wellnessCategoryList,
    Filters: { categoryId, language, sort, contentType, text },
  } = useSelector((state) => state.allwellness);
  const { Languages } = useSelector((state) => state.wisdom);

  const handleNavigateToWellnaessAudio = (slug) => {
    navigate(`/wellness/${slug}`, {
      state: { default: wellness?.default_image },
    });
  };

  useEffect(() => {
    const element = listStartRef.current;
    if (element) {
      const position = element.getBoundingClientRect();
      const y = position.top;
      setYCoordinate(y);
    }
  }, []);

  useEffect(() => {
    dispatch(getwellnessbanner());
    dispatch(getWellnessCategory());
    dispatch(getLanguages());

    // dispatch(
    //   getAllWellness({
    //     page: currentPage,
    //     limit: limit,
    //   })
    // );
    setFetchedwellness(wellnesslist);
  }, []);

  const handleFilterChange = (e) => {
    const { value, name } = e.target;

    const payload = {
      [name]: value,
    };

    dispatch(filtersUpdate(payload));
  };

  const handleFiltersSubmit = (e, action) => {
    if (action == "loadmore") {
      dispatch(
        getAllWellness({
          page: currentPage,
          limit: limit + 8,
        })
      );
    } else {
      dispatch(
        getAllWellness({
          page: currentPage,
          limit: limit,
        })
      );
      window.scrollTo({
        top: 650,
        behavior: "smooth",
      });
    }
  };

  const { orderAll } = useSelector((state) => state?.order);

  const [orderIds, setOrderIds] = useState([]);

  useEffect(() => {
    dispatch(getmyOrder());
  }, [dispatch]);

  const maxLength = 115;
  const maxLength1 = 40;

  useEffect(() => {
    let tempOrders = [];
    for (let j = 0; j < wellnesslist.length; j++) {
      for (let i = 0; i < orderAll.length; i++) {
        if (wellnesslist[j]._id === orderAll[i]?.orderItems?.product) {
          tempOrders.push(orderAll[i]?.orderItems?.product);
        }
      }
    }
    setOrderIds(tempOrders);
  }, [orderAll, wellnesslist, dispatch]);

  useEffect(() => {
    dispatch(filtersUpdate(query ? { text: query } : { text: text }));
    handleFiltersSubmit();
  }, [query]);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleFiltersSubmit();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);
  return (
    <>
      <MetaData
        title={wellness?.meta_title ?? "Dr. Archika Didi"}
        description={wellness?.meta_description ?? "Dr. Archika Didi"}
        tags={wellness?.meta_tag ?? "Dr. Archika Didi"}
      />
      {wellness ? (
        <>
          <section className="overflow-hidden">
            {/* Desktop View */}
            <div className="desktop-only position-relative">
              <img
                loading="lazy"
                src={`${wellness?.background_image}`}
                alt="Dr. Archika Didi"
                className="w-100"
              />
              <div className="position-absolute w-100 h-100 top-0">
                <div className="container h-100">
                  <div className="row h-100 align-items-center">
                    {wellness?.alignItem === "left" && (
                      <>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-12 d-flex flex-column align-items-start justify-content-center">
                          <div>
                            <h1 className="fw-bold">
                              <span className="wisdomhead haedingfont">
                                {wellness?.heading}
                              </span>
                            </h1>
                            <p
                              className="w-75 wisdomPara homebdes mt-4"
                              style={{
                                fontFamily: "Lato",
                                textAlign: "justify",
                              }}
                            >
                              {wellness?.subheading}
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-12"></div>
                      </>
                    )}
                    {wellness?.alignItem === "right" && (
                      <>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-12"></div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-12 d-flex flex-column align-items-start justify-content-center">
                          <div>
                            <h1 className="fw-bold">
                              <span className="wisdomhead haedingfont">
                                {wellness?.heading}
                              </span>
                            </h1>
                            <p
                              className="w-75 wisdomPara homebdes mt-4"
                              style={{
                                fontFamily: "Lato",
                                textAlign: "justify",
                              }}
                            >
                              {wellness?.subheading}
                            </p>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* Mobile View */}
            <div className="mobile-only">
              <div className="container-fluid position-relative d-flex">
                <img
                  loading="lazy"
                  src={wellness?.res_background_image}
                  alt="Dr. Archika Didi"
                  className="w-100"
                />
                <div className="position-absolute w-100 h-100 top-0">
                  <div className="container h-100">
                    <div className="row h-100 align-items-center">
                      <div className="col-6 d-flex justify-content-start align-items-center">
                        {wellness?.alignItem === "left" && (
                          <p
                            className="homebannertitle"
                            style={{ fontFamily: "myFont", fontSize: "24px" }}
                            dangerouslySetInnerHTML={{
                              __html: wellness?.headingres,
                            }}
                          ></p>
                        )}
                      </div>
                      <div className="col-6 d-flex justify-content-start align-items-center">
                        {wellness?.alignItem === "right" && (
                          <p
                            className="homebannertitle"
                            style={{ fontFamily: "myFont", fontSize: "24px" }}
                            dangerouslySetInnerHTML={{
                              __html: wellness?.headingres,
                            }}
                          ></p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="container">
                <p
                  className="py-4"
                  style={{
                    fontFamily: "Lato",
                    textAlign: "justify",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: wellness?.subheadingres,
                  }}
                ></p>
              </div>
            </div>
          </section>

          <section>
            <div className="container filterpad py-2 shadow">
              <div className="row p-3 ">
                <div
                  className="col-lg-3 col-sm-12 col-md-6 d-flex justify-content-center align-items-center gap-2"
                  id="mobileScreen"
                >
                  <label
                    htmlFor="filter"
                    className="fw-bold myfiltericon"
                    style={{ color: "#A0A0A0" }}
                  >
                    <img
                      loading="lazy"
                      style={{ width: "25px" }}
                      src={filtericon}
                      alt="Dr. Archika Didi"
                    />
                  </label>
                  <select
                    className="select searchborder rounded-pill p-2 w-100"
                    value={language}
                    name="language"
                    id="selectFilter"
                    onChange={(e) => handleFilterChange(e)}
                    onKeyDown={handleKeyPress}
                  >
                    <option value="">All Languages</option>
                    {Array.isArray(Languages) &&
                      Languages?.map((item, index) => {
                        return (
                          <option key={index} value={item?.language}>
                            {item?.language}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <div
                  className="col-lg-3 col-sm-12 col-md-6 d-flex justify-content-center align-items-center"
                  id="mobileScreen"
                >
                  <select
                    value={categoryId}
                    name="categoryId"
                    className="select searchborder rounded-pill p-2 w-100"
                    id="selectFilter"
                    onChange={(e) => handleFilterChange(e)}
                    onKeyDown={handleKeyPress}
                  >
                    <option value="">Category</option>
                    {Array.isArray(wellnessCategoryList) &&
                      wellnessCategoryList?.map((item, index) => {
                        return (
                          <option key={index} value={item?._id}>
                            {item?.title}
                          </option>
                        );
                      })}
                  </select>
                </div>

                <div
                  className="col-lg-3 col-sm-12 col-md-6 d-flex justify-content-center align-items-center"
                  id="mobileScreen"
                >
                  <select
                    value={sort}
                    name="sort"
                    className="select searchborder rounded-pill p-2 w-100"
                    id="selectFilter"
                    onChange={(e) => handleFilterChange(e)}
                    onKeyDown={handleKeyPress}
                  >
                    <option value="">Sort by</option>
                    <option value="newest">Newest</option>
                    <option value="oldest">Oldest</option>
                  </select>
                </div>
                <div className="col-lg-3 col-sm-12 col-md-6 d-flex justify-content-end align-items-center">
                  <label
                    htmlFor="filter"
                    className="fw-bold mysearchicon"
                    style={{ color: "#A0A0A0" }}
                  >
                    <img
                      src={searchicon}
                      alt="Dr. Archika Didi"
                      style={{ width: "25px" }}
                      onClick={handleFiltersSubmit}
                    />
                  </label>
                  <form className="w-100 pt-4" role="search">
                    <input
                      className="form-control  searchborder rounded-pill w-100 searchborder"
                      type="search"
                      name="text"
                      placeholder="Search by keyword..."
                      aria-label="Search"
                      onChange={(e) => handleFilterChange(e)}
                      onKeyDown={handleKeyPress}
                      value={text}
                      style={{ borderRadius: "19px", color: "#A0A0A0" }}
                    />
                    <i className="fa-solid fa-magnifying-glass mysearchiconforphone"></i>
                  </form>
                </div>
                <div className="pt-3 d-flex justify-content-center">
                  <button
                    className="btn shadowbuttons btn-lg text-light  cmn-t-shake"
                    style={{
                      padding: "0.6rem 2.5rem",
                    }}
                    onClick={handleFiltersSubmit}
                  >
                    SEARCH
                  </button>
                </div>
              </div>
            </div>
          </section>

          <section ref={listStartRef} id="listStart">
            {
              isLoading ? (
                <SmallLoader />
              ) : (
                <div className="container artvid mt-2">
                  {wellnesslist?.length > 0 ? (
                    <div className="row g-3">
                      {Array.isArray(wellnesslist) &&
                        wellnesslist?.map((item, index) => (
                          <div
                            className="col-md-6 col-sm-12 col-lg-3 "
                            key={index}
                          >
                            <Link to={`/wellness/${item?.slug}`}>
                              <div
                                className="card  item-card card_height1"
                                // onClick={() => handleNavigateToWellnaessAudio(item?.slug)}
                                style={{
                                  borderRadius: "10px",
                                  overflow: "hidden",
                                }}
                              >
                                <img
                                  src={item?.thumbnail_image}
                                  alt="Dr. Archika Didi"
                                  style={{ aspectRatio: "3/2" }}
                                />

                                <div className="d-flex p-3 justify-content-between">
                                  <div className="d-flex align-items-center gap-2">
                                    <span className="text-secondary">
                                      {item?.language}
                                    </span>
                                  </div>
                                  {!orderIds?.includes(item?._id) &&
                                  item?.isPaid ? (
                                    <img
                                      src={crownimg}
                                      style={{
                                        top: "1.2rem",
                                        right: "1.7rem",
                                        width: "1.5rem",
                                      }}
                                      className="img-fluid  position-absolute"
                                      alt="Dr. Archika Didi"
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div className="container">
                                  <h5
                                    className="mt-1 "
                                    style={{
                                      fontFamily: "myFont",
                                      fontWeight: "bold",
                                      height: "45px",
                                      overflow: "hidden",
                                    }}
                                  >
                                    {item.title.length > maxLength1
                                      ? `${item.title.substring(
                                          0,
                                          maxLength1
                                        )}...`
                                      : item.title}
                                  </h5>
                                  <p
                                    className="card-text lato"
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        item.description.length > maxLength
                                          ? `${item.description.substring(
                                              0,
                                              maxLength
                                            )}...`
                                          : item.description,
                                    }}
                                    style={{
                                      marginLeft: "0.0rem",
                                      fontFamily: "Lato",
                                      color: "#5B5B5B",
                                    }}
                                  ></p>
                                </div>
                              </div>
                            </Link>
                          </div>
                        ))}
                      <section className="py-3">
                        <ul style={{ listStyle: "none" }}>
                          {isLoading && (
                            <li className=" mb-5 text-center">
                              <div
                                className="spinner-border text-primary"
                                role="status"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>
                            </li>
                          )}
                        </ul>
                      </section>

                      <div className="col-12">
                        <div className="pt-3 d-flex justify-content-center py-5">
                          {wellnesslist?.length == totalwellness ? (
                            ""
                          ) : (
                            <a>
                              <button
                                className="btn shadowbuttons btn-lg text-light  cmn-t-shake"
                                style={{ borderRadius: "20px" }}
                                // onClick={handleScroll}
                                onClick={(e) =>
                                  handleFiltersSubmit(e, "loadmore")
                                }
                              >
                                {isLoading ? (
                                  <div
                                    className="spinner-border text-dark"
                                    role="status"
                                  >
                                    <span className="visually-hidden">
                                      Loading...
                                    </span>
                                  </div>
                                ) : (
                                  <span>LOAD MORE</span>
                                )}
                              </button>
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <section>
                      <div className="text-center d-flex justify-content-center">
                        {/* <img
                          loading="lazy"
                          style={{ width: "400px" }}
                          src={nodata}
                          alt="Dr. Archika Didi"
                        /> */}
                      </div>
                    </section>
                  )}
                </div>
              )

              // <section>
              //   <div className="text-center d-flex justify-content-center">
              //     <img
              //       loading="lazy"
              //       style={{ width: "400px" }}
              //       src={nodata}
              //       alt="Dr. Archika Didi"
              //     />
              //   </div>
              // </section>
            }
          </section>
        </>
      ) : (
        <SmallLoader />
      )}
    </>
  );
};

export default WellnessBundle;
